/* eslint-disable camelcase */

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import cardValidator from 'card-validator';
// import adyenEncrypt from 'adyen-encryption';

import {
  CHECK_IN_FAIL_CREDIT_CARD,
  FAIL_START_CHECK_IN,
  START_CHECK_IN,
  CHECK_IN_SUCCESS_CREDIT_CARD,
  CHECK_IN_PAYMENT_FAIL,
  CHECK_IN_PAYMENT_SUCCESS,
  CHECK_IN_PAYMENT_START,
  CHECK_IN_VERIFY_DOCUMENT,
  CHECK_IN_ROOM_PREFERENCES,
  CHECK_IN_EDIT_GUEST_SUCCESS,
  CHECK_IN_EDIT_GUEST_FAIL,
  CHECK_IN_UPLOADID_SUCCESS,
} from '../constants';
import history from '../history';
import { isStringAlpha } from '../utils';
import { BookingShape } from './booking';
import IdVerificationAPIType from '../data/types/IdVerificationAPIType';
import {
  createTokenPaymentOmise,
  createTokenPaymentMaya,
} from '../lib/payment';

const initial = {
  booking: null,
  creditCard: {
    cardHolder: '',
    cardNumber: '',
    expiry: '',
    cvc: '',
    suffix: '',
  },
  idVerificationAPI: null,
  roomPreferences: null,
  isSubmitting: false,
  idReferenceFileName: null,
  errors: [],
  errorMessage: '',
};

export const CheckInShape = PropTypes.shape({
  booking: BookingShape,
  creditCard: PropTypes.shape({
    cardHolder: PropTypes.string,
    cardNumber: PropTypes.string,
    expiry: PropTypes.string,
    cvc: PropTypes.string,
    suffix: PropTypes.string,
  }),
  idVerificationAPI: IdVerificationAPIType.shape(),
  isSubmitting: PropTypes.bool,
  errors: PropTypes.array,
  errorMessage: PropTypes.string,
});

export function startCheckIn(bookingId) {
  return async (dispatch, getState, { client }) => {
    const GetOneBooking = gql`query getOneBooking{
      getOneBooking(bookingId: "${bookingId}"){
        booking_id
        booking_code
        check_in
        check_out
        status
        amount
        is_editable
        paid_at
        card_type
        suffix_card_number
        tax
        total
        payment_merchant_name
        payment_method
        payment_status
        check_in_data {
          check_in_status
          check_in_available_date
          pin {
            code
            image {
              url
              width
              height
            }
          }
          retry_step
        }
        hotel {
          id
          name
          currency
          country_code
          checkin_time
          breakfast {
            name
          }
        }
        breakfast {
          name
          price_per_guest
        }
        smoking {
          hotel_id
          price_per_night
        }
        pet_friendly {
          hotel_id
          price_per_night
        }
        additional_products {
          id
          amount
          quantity
          price
          detail {
            title
          }
        }
        rooms {
          name
          room_id
          guests {
            email
            phoneCountry {
              phone
              country
            }
            name_title
            first_name
            last_name
            type
          }
        }
      }
    }`;
    const { data, errors } = await client.networkInterface.query({
      query: GetOneBooking,
    });

    if (errors) {
      dispatch({
        type: FAIL_START_CHECK_IN,
        payload: errors,
      });
    } else {
      dispatch({
        type: START_CHECK_IN,
        payload: {
          ...initial,
          booking: data.getOneBooking,
        },
      });
    }
  };
}

const supportedCards = [
  'visa',
  'master-card',
  'american-express',
  'jcb',
  'unionpay',
];

function isCardNumberValid(cardNumber) {
  let ok = false;
  const valid = cardValidator.number(cardNumber);
  if (valid !== null && valid.card !== null && valid.isValid) {
    const { type } = valid.card;
    ok = supportedCards.indexOf(type) > -1;
  }
  return ok;
}

function isExpiryValid(expiry) {
  const valid = cardValidator.expirationDate(expiry);
  return valid !== null && valid.isValid;
}

function isCVCValid({ cardNumber, cvc }) {
  let ok = false;
  const number = cardValidator.number(cardNumber);
  if (number !== null && number.card !== null && number.isPotentiallyValid) {
    const cvv = cardValidator.cvv(cvc, number.card.code.size);
    ok = cvv !== null && cvv.isValid;
  }
  return ok;
}

function creditCardValidate(creditCard) {
  const errors = [];
  if (creditCard.cardHolder === '' || !isStringAlpha(creditCard.cardHolder)) {
    errors.push('cardHolder');
  }
  if (!isCardNumberValid(creditCard.cardNumber)) {
    errors.push('cardNumber');
  }
  if (!isExpiryValid(creditCard.expiry)) {
    errors.push('expiry');
  }
  if (!isCVCValid(creditCard)) {
    errors.push('cvc');
  }

  return errors;
}

export function addCreditCard(creditCard) {
  return async dispatch => {
    const errors = creditCardValidate(creditCard);
    if (errors.length > 0) {
      dispatch({
        type: CHECK_IN_FAIL_CREDIT_CARD,
        payload: errors,
      });
    } else {
      dispatch({
        type: CHECK_IN_SUCCESS_CREDIT_CARD,
        payload: creditCard,
      });
    }
  };
}

export function payBooking() {
  return async (dispatch, getState, { client }) => {
    const { checkIn } = getState();
    const { booking, creditCard } = checkIn;
    const {
      cardFirstNameHolder,
      cardLastNameHolder,
      cardHolder,
      cardNumber,
      expiry,
      cvc,
    } = creditCard;
    const errorsCreditCard = [];
    let cardData = null;
    const cardExpiry = cardValidator.expirationDate(expiry);
    const year =
      cardExpiry.year.length === 2 ? `20${cardExpiry.year}` : cardExpiry.year;
    let token;
    if (checkIn.booking.hotel.country_code === 'PH') {
      cardData = {
        number: cardNumber,
        cvc,
        holderName: cardHolder,
        first_name: cardFirstNameHolder,
        last_name: cardLastNameHolder,
        expiry,
      };
      token = await createTokenPaymentMaya(window.App.mayaApiKey, cardData);
    } else if (checkIn.booking.hotel.country_code === 'TH') {
      cardData = {
        number: cardNumber,
        cvc,
        holderName: cardHolder,
        expiryMonth: cardExpiry.month,
        expiryYear: year,
      };
      token = await createTokenPaymentOmise(window.App.omiseApiKey, cardData);
    }
    if (creditCard) {
      errorsCreditCard.push(...creditCardValidate(creditCard));
      if (errorsCreditCard.length > 0) {
        dispatch({
          type: CHECK_IN_PAYMENT_FAIL,
          payload: {
            errorMessage: '',
            errors: errorsCreditCard,
          },
        });
        return;
      }
    }
    if (checkIn.isSubmitting || checkIn.errors.length > 0) {
      return;
    }
    dispatch({
      type: CHECK_IN_PAYMENT_START,
    });
    // const cardExpiry = cardValidator.expirationDate(expiry);

    // const year =
    //   cardExpiry.year.length === 2 ? `20${cardExpiry.year}` : cardExpiry.year;
    // const cardData = {
    //   number: cardNumber,
    //   cvc,
    //   holderName: cardHolder,
    //   expiryMonth: cardExpiry.month,
    //   expiryYear: year,
    // };
    // const cardEncrypt = cardData;
    // const adyen = await adyenEncrypt.encrypt(window.App.adyenKey, cardData);
    // const cardEncrypt = token;
    const suffix = cardNumber.substring(cardNumber.length - 4);
    const matched = cardNumber.match(/(^\d{6})(\d{5,})(\d{4})/);
    const masked = matched[1] + matched[2].replace(/\w/g, 'X') + matched[3];
    const cardType = cardValidator.number(cardNumber).card.type;
    const cardTypeMap = {
      visa: 'visa',
      'master-card': 'master',
      jcb: 'jcb',
      unionpay: 'cup',
      'american-express': 'amex',
    };
    const payment = {
      bookingId: booking.booking_id,
      'payment-encrypted-data': token,
      suffix_credit_card: suffix,
      masked_credit_card: masked,
      credit_card_type: cardTypeMap[cardType],
      billing_name: cardHolder,
      payment_func: 'checkinonline',
      hotel_country_code: checkIn.booking.hotel.country_code,
    };

    const Payment = gql`mutation Payment{
      payBooking(payment: "${encodeURIComponent(JSON.stringify(payment))}"){
        payment_merchant_name
        paid_at
        booking_id
        card_type
        suffix_card_number
        total
        omise {
          validate3DsOmise {
            dataOmise {
              authorizeUri
            }
          }
        }
        maya {
          validate3DsMaya {
            dataMaya {
              authorizeUri
            }
          }
        }
      }
    }`;
    const data = await client.networkInterface.query({
      query: Payment,
    });
    if (!data.errors) {
      const redirect_3ds = data.data.payBooking.omise
        ? data.data.payBooking.omise.validate3DsOmise.dataOmise.authorizeUri
        : data.data.payBooking.maya.validate3DsMaya.dataMaya.authorizeUri;
      dispatch({
        type: CHECK_IN_PAYMENT_SUCCESS,
        payload: {
          payment_merchant_name: data.data.payBooking.payment_merchant_name,
          paid_at: data.data.payBooking.paid_at,
          card_type: data.data.payBooking.card_type,
          suffix_card_number: data.data.payBooking.suffix_card_number,
          total: data.data.payBooking.total,
          creditCard: {
            cardHolder,
            cardNumber,
            expiry,
            cvc,
            suffix,
          },
          redirect_3ds,
        },
      });
      if (redirect_3ds) {
        window.open(redirect_3ds, '_self');
      }
      // history.push({
      //   pathname: `/check-in/${booking.booking_id}/payment_success`,
      // });
      return;
    }
    const respError = JSON.parse(data.errors[0].message);
    const errorMessage = `${respError.message}`;
    dispatch({
      type: CHECK_IN_PAYMENT_FAIL,
      payload: {
        errorMessage,
        errors: [],
      },
    });
  };
}

export function verifyDocument(countryIssuer, documentType) {
  return async (dispatch, getState, { client }) => {
    if (countryIssuer && documentType) {
      const {
        checkIn: {
          booking: { booking_code, booking_id },
        },
        user: {
          user: { loyalty_number },
        },
      } = getState();
      const IdVerificationAPI = gql`query IdVerificationAPI{
      getIdVerificationAPI(loyaltyNumber: "${loyalty_number}" bookingId: "${booking_id}" bookingNumber: "${booking_code}" countryIssuer: "${countryIssuer}" documentType: "${documentType}"){
        timestamp
        transactionReference
        redirectUrl
        }
      }`;
      const { data, error } = await client.networkInterface.query({
        query: IdVerificationAPI,
      });
      if (!error) {
        dispatch({
          type: CHECK_IN_VERIFY_DOCUMENT,
          payload: data.getIdVerificationAPI,
        });
        history.push({
          pathname: `/check-in/${booking_id}/id_verification`, // eslint-disable-line camelcase
        });
      }
    }
  };
}

export function saveRoomPreferences(floor, elevator, arrivalTime) {
  return async (dispatch, getState) => {
    if (floor && elevator) {
      const {
        checkIn: {
          booking: { booking_id },
        },
      } = getState();
      dispatch({
        type: CHECK_IN_ROOM_PREFERENCES,
        payload: {
          floor,
          elevator,
          arrivalTime,
        },
      });
      history.push({
        pathname: `/check-in/${booking_id}/room_success`, // eslint-disable-line camelcase
      });
    }
  };
}

export function confirmCheckIn() {
  return async (dispatch, getState, { client }) => {
    const {
      checkIn: {
        booking: { booking_id },
        roomPreferences,
        idReferenceFileName,
        idReferenceNumber,
        imageUrl,
      },
    } = getState();
    const cont = () => {
      history.push({
        pathname: `/check-in/${booking_id}/success`,
      });
    };
    if (roomPreferences) {
      const { floor, elevator, arrivalTime } = roomPreferences;
      const SaveRoomPreferences = gql`mutation roomPreferences{
      saveRoomPreferences(bookingId: "${booking_id}" floor: "${floor}" elevator: "${elevator}" arrivalTime: "${arrivalTime}" idReferenceFileName: "${idReferenceFileName}" idReferenceNumber: "${idReferenceNumber}" imageUrl: "${imageUrl}"){
        booking_id
        }
      }`;
      const data = await client.networkInterface.query({
        query: SaveRoomPreferences,
      });
      if (!data.error) {
        cont();
      }
    } else {
      cont();
    }
  };
}

export function updateNameMissMatch({ first_name, last_name }) {
  return async (dispatch, getState, { client }) => {
    if (first_name && last_name) {
      const {
        checkIn: {
          booking: { booking_id },
        },
      } = getState();
      const EditGuest = gql`mutation editGuest{
        editGuest(
          bookingId:"${booking_id}"
          firstName:"${first_name}"
          lastName:"${last_name}"){
          booking_id
        }
      }`;
      const data = await client.networkInterface.query({
        query: EditGuest,
      });
      if (!data.errors) {
        dispatch({
          type: CHECK_IN_EDIT_GUEST_SUCCESS,
          payload: { first_name, last_name },
        });
        history.push({
          pathname: `/check-in/${booking_id}/room_success`, // eslint-disable-line camelcase
        });
      } else {
        const respError = JSON.parse(data.errors[0].message);
        let errorMessage = `${respError.message}<br/>`;
        const errorFields = [];
        Object.keys(respError.error).forEach(key => {
          errorMessage += `•${key}: ${respError.error[key]}<br/>`;
          errorFields.push(key);
        });
        dispatch({
          type: CHECK_IN_EDIT_GUEST_FAIL,
          payload: {
            errorMessage,
            errors: errorFields,
          },
        });
      }
    }
  };
}

export function editGuest({ first_name, last_name, phoneCountry, email }) {
  return async (dispatch, getState, { client }) => {
    const {
      checkIn: {
        booking: { booking_id },
      },
    } = getState();
    const EditGuest = gql`mutation editGuest{
        editGuest(
          bookingId:"${booking_id}"
          firstName:"${first_name}"
          lastName:"${last_name}"
          phoneCountry:"${encodeURI(JSON.stringify(phoneCountry))}"
          email:"${email}"){
          booking_id
        }
      }`;
    const data = await client.networkInterface.query({
      query: EditGuest,
    });
    if (!data.errors) {
      dispatch({
        type: CHECK_IN_EDIT_GUEST_SUCCESS,
        payload: { first_name, last_name, phoneCountry, email },
      });
      history.push({
        pathname: `/check-in/${booking_id}/guest_detail`, // eslint-disable-line camelcase
      });
    } else {
      const respError = JSON.parse(data.errors[0].message);
      let errorMessage = `${respError.message}<br/>`;
      const errorFields = [];
      Object.keys(respError.error).forEach(key => {
        errorMessage += `•${key}: ${respError.error[key]}<br/>`;
        errorFields.push(key);
      });
      dispatch({
        type: CHECK_IN_EDIT_GUEST_FAIL,
        payload: {
          errorMessage,
          errors: errorFields,
        },
      });
    }
  };
}

export function uploadIDCheckin(id_reference_number, file_type, image_url) {
  return async (dispatch, getState, { client }) => {
    if (id_reference_number) {
      const {
        checkIn: {
          booking: { booking_id },
        },
      } = getState();
      dispatch({
        type: CHECK_IN_UPLOADID_SUCCESS,
        payload: {
          idReferenceNumber: id_reference_number,
          fileName: `${id_reference_number}.${file_type}`,
          imageUrl: image_url,
        },
      });
      history.push({
        pathname: `/check-in/${booking_id}/room_preference`,
      });
    }
  };
}

export function toBGC() {
  return async () => {
    history.push({pathname: "/hotel/bgc-the-fort"});
  }
}
