/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';

import s from './NumberPicker.scss';
import Option from './Option';

import iconArrowDownAdd1x from './icon-arrow-down@1x.png';
import iconArrowDownAdd2x from './icon-arrow-down@2x.png';

class NumberPicker extends Component {
  bindWindowScroll = null;

  constructor(props) {
    super(props);
    const { minNumber, maxNumber, number } = this.props;
    this.state = {
      minNumber,
      maxNumber,
      number,
      showingOption: false,
      isMouseOver: false,
    };
  }

  componentDidMount() {
    this.bindWindowScroll = this.onWindowScroll.bind(this);
    window.addEventListener('scroll', this.bindWindowScroll);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { minNumber, maxNumber } = nextProps;
    let { number } = nextProps;

    this.setState({
      minNumber,
      maxNumber,
    });
    if (this.props.minNumber < minNumber && number < minNumber) {
      number = minNumber;
      this.props.onSelectedNumber(number);
    } else if (this.props.maxNumber > maxNumber && number > maxNumber) {
      number = maxNumber;
      this.props.onSelectedNumber(number);
    }
    this.setState({
      number,
    });
    if (nextProps.sundrySaleScrolling) {
      this.setState({ showingOption: false });
    }
  }

  onWindowScroll = () => {
    if (this.button) {
      this.setState({ showingOption: false });
    }
  };

  handleChange = value => {
    this.props.onSelectedNumber(parseInt(value, 10));
    this.setState({
      number: parseInt(value, 10),
      showingOption: false,
    });
  };

  handleClickNumberPicker = event => {
    event.preventDefault();
    this.setState({ showingOption: !this.state.showingOption });
    // this.setState(prevState => ({ showingOption: !prevState.showingOption }));
    const self = this;
    const onClickWindow = () => {
      if (!self.state.isMouseOver) {
        self.setState({ showingOption: false });
        window.removeEventListener('click', onClickWindow);
      }
    };
    window.addEventListener('click', onClickWindow);
  };

  render() {
    const { className, id } = this.props;
    const { minNumber, maxNumber, number, showingOption } = this.state;
    const options = [];
    for (let i = minNumber; i <= maxNumber; i += 1) {
      options.push(
        <Option key={i} name={i} value={i} onClick={this.handleChange} />,
      );
    }
    return (
      <div id={id} className={cx(s.root, className)}>
        <button
          ref={button => {
            this.button = button;
          }}
          onClick={this.handleClickNumberPicker}
          onMouseOver={() => {
            this.setState({ isMouseOver: true });
          }}
          onMouseLeave={() => {
            this.setState({ isMouseOver: false });
          }}
        >
          {number}
        </button>
        {showingOption && (
          <ul
            ref={menu => {
              this.menu = menu;
            }}
            onMouseOver={() => {
              this.setState({ isMouseOver: true });
            }}
            onMouseLeave={() => {
              this.setState({ isMouseOver: false });
            }}
          >
            {options}
          </ul>
        )}
        <i className={s.arrowDown}>
          <img
            src={iconArrowDownAdd1x}
            srcSet={`${iconArrowDownAdd2x} 2x`}
            alt="arrow-down"
            width={this.props.width}
            height={this.props.height}
          />
        </i>
      </div>
    );
  }
}

NumberPicker.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  minNumber: PropTypes.number.isRequired,
  maxNumber: PropTypes.number.isRequired,
  onSelectedNumber: PropTypes.func,
  number: PropTypes.number,
  sundrySaleScrolling: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

NumberPicker.defaultProps = {
  id: '',
  className: '',
  onSelectedNumber: () => {},
  number: 0,
  sundrySaleScrolling: false,
  width: '',
  height: '',
};

const mapState = state => ({
  sundrySaleScrolling: state.runtime.sundrySaleScrolling,
});

export default connect(mapState)(withStyles(s)(NumberPicker));
