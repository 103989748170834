import React from 'react';

class LoadingIndicatorFourCircles extends React.Component {
  render() {
    return (
      <svg
        className="lds-message"
        width="59px"
        height="59px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 100 100"
        preserveAspectRatio="xMidYMid"
        style={{ background: 'none' }}
      >
        <g transform="translate(20 50)" className="">
          <circle
            cx="0"
            cy="0"
            r="8"
            fill="#fc0f3f"
            transform="scale(0.583975 0.583975)"
            className=""
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.375s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
              className=""
            />
          </circle>
        </g>
        <g transform="translate(40 50)" className="">
          <circle
            cx="0"
            cy="0"
            r="8"
            fill="#fc0f3f"
            transform="scale(0.234172 0.234172)"
            className=""
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.25s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
              className=""
            />
          </circle>
        </g>
        <g transform="translate(60 50)" className="">
          <circle
            cx="0"
            cy="0"
            r="8"
            fill="#fc0f3f"
            transform="scale(0.00912668 0.00912668)"
            className=""
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="-0.125s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
              className=""
            />
          </circle>
        </g>
        <g transform="translate(80 50)" className="">
          <circle
            cx="0"
            cy="0"
            r="8"
            fill="#fc0f3f"
            transform="scale(0.107293 0.107293)"
            className=""
          >
            <animateTransform
              attributeName="transform"
              type="scale"
              begin="0s"
              calcMode="spline"
              keySplines="0.3 0 0.7 1;0.3 0 0.7 1"
              values="0;1;0"
              keyTimes="0;0.5;1"
              dur="1s"
              repeatCount="indefinite"
              className=""
            />
          </circle>
        </g>
      </svg>
    );
  }
}

export default LoadingIndicatorFourCircles;
