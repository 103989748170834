/* eslint-disable react/no-array-index-key */
/* eslint-disable react/static-property-placement */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { intlShape } from 'react-intl';

import s from './Dropdown.css';
import Option from './Option';

import iconArrowDownAdd1x from './icon-arrow-down@1x.png';
import iconArrowDownAdd2x from './icon-arrow-down@2x.png';

class Dropdown extends Component {
  static propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    icon: PropTypes.shape({
      normal: PropTypes.string,
      add2x: PropTypes.string,
    }),
    svgIcon: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        name: PropTypes.string.isRequired,
      }),
    ).isRequired,
    onClick: PropTypes.func,
    onSelectedValue: PropTypes.func,
    preselectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    firstOptionIsPlaceholder: PropTypes.bool,
    shadow: PropTypes.bool,
    isSuccess: PropTypes.bool,
    errorMessage: PropTypes.string,
    isError: PropTypes.bool,
    showFloatingLabel: PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    id: '',
    className: '',
    onClick: () => {},
    onSelectedValue: () => {},
    value: '',
    preselectedValue: '',
    icon: {
      normal: null,
      add2x: null,
    },
    svgIcon: null,
    firstOptionIsPlaceholder: true,
    shadow: false,
    isSuccess: false,
    errorMessage: '',
    isError: false,
    showFloatingLabel: true,
    width: '',
    height: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      value: props.value || props.preselectedValue,
      selectedValue: props.value || props.preselectedValue,
      isSelectingFirstOption: props.options[0].value === props.preselectedValue,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.value) {
      this.setState({ value: nextProps.value || 0 });
    }
    if (nextProps.preselectedValue) {
      this.setState({
        isSelectingFirstOption:
          this.props.options[0].value === nextProps.preselectedValue,
      });
    }
  }

  onClick = () => {
    this.props.onClick();
  };

  handleChange = event => {
    this.props.onSelectedValue(event.target.value);
    this.setState({
      selectedValue: event.target.value,
      isSelectingFirstOption:
        this.props.options[0].value === event.target.value,
    });
  };

  render() {
    const {
      id,
      icon: { normal, add2x },
      svgIcon,
      firstOptionIsPlaceholder,
      className,
      shadow,
      isSuccess,
      errorMessage,
      isError,
      showFloatingLabel,
    } = this.props;
    const {
      isSelectingFirstOption,
      selectedValue,
      value,
      selectOptions,
    } = this.state;
    const options = [];
    let inputIcon =
      normal && add2x ? (
        <img
          src={normal}
          srcSet={`${add2x} 2x`}
          alt=""
          width={this.props.width}
          height={this.props.height}
        />
      ) : null;
    if (!inputIcon) {
      inputIcon = svgIcon ? (
        <object data={svgIcon} aria-label="icon">
          Fallback Text
        </object>
      ) : null;
    }
    this.props.options.map((option, index) =>
    options.push(
      <Option
        key={index}
        {...option}
        hidden={index === 0 && firstOptionIsPlaceholder}
      />,
    ),
  );
    const showLabel = firstOptionIsPlaceholder && !isSelectingFirstOption;
    const label =
      showFloatingLabel && firstOptionIsPlaceholder ? (
        <label className={cx(showLabel && s.show)}>
          {this.props.options[0].name}
        </label>
      ) : null;
    return (
      <div
        id={id}
        className={cx(
          s.root,
          shadow && s.shadow,
          className,
          s[this.context.intl.locale],
          inputIcon && s.hasIcon,
          showFloatingLabel && s.hasLabel,
          isError && s.error,
        )}
      >
        {(isError || isSuccess) && (
          <div
            className={cx(s.border, isError && s.error, isSuccess && s.success)}
          />
        )}
        {inputIcon && <span className={s.icon}>{inputIcon}</span>}
        <select
          onClick={this.onClick}
          onChange={this.handleChange}
          value={selectedValue || value}
        >
          {options}
        </select>
        <i className={s.arrowDown}>
          <img
            src={iconArrowDownAdd1x}
            srcSet={`${iconArrowDownAdd2x} 2x`}
            alt="arrow-down"
            width={this.props.width}
            height={this.props.height}
          />
        </i>
        {label}
        {isError && errorMessage && (
          <small className={s.errorMessage}>{errorMessage}</small>
        )}
      </div>
    );
  }
}

export default withStyles(s)(Dropdown);
