/**
 * Created by methee on 8/29/17.
 */
import {
  GraphQLObjectType as ObjectType,
  GraphQLFloat as FloatType,
  GraphQLNonNull as NonNull,
  GraphQLString as StringType,
} from 'graphql';
import PropTypes from 'prop-types';

const ApplyPromotionType = new ObjectType({
  name: 'ApplyPromotion',
  fields: {
    title: { type: new NonNull(StringType) },
    type: { type: new NonNull(StringType) },
    credit_card: {
      type: new ObjectType({
        name: 'PromotionCreditCard',
        fields: {
          tax: { type: new NonNull(FloatType) },
          price: { type: new NonNull(FloatType) },
          discounted: { type: new NonNull(FloatType) },
          member_tax: { type: new NonNull(FloatType) },
          member_price: { type: new NonNull(FloatType) },
          member_discounted: { type: new NonNull(FloatType) },
        },
      }),
    },
    pay_later: {
      type: new ObjectType({
        name: 'PromotionPayLater',
        fields: {
          tax: { type: new NonNull(FloatType) },
          price: { type: new NonNull(FloatType) },
          discounted: { type: new NonNull(FloatType) },
          member_tax: { type: new NonNull(FloatType) },
          member_price: { type: new NonNull(FloatType) },
          member_discounted: { type: new NonNull(FloatType) },
        },
      }),
    },
  },
});

ApplyPromotionType.shape = () =>
  PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.string,
    credit_card: PropTypes.shape({
      tax: PropTypes.number,
      price: PropTypes.number,
      discounted: PropTypes.number,
      member_tax: PropTypes.number,
      member_price: PropTypes.number,
      member_discounted: PropTypes.number,
    }),
    pay_later: PropTypes.shape({
      tax: PropTypes.number,
      price: PropTypes.number,
      discounted: PropTypes.number,
      member_tax: PropTypes.number,
      member_price: PropTypes.number,
      member_discounted: PropTypes.number,
    }),
  });

export default ApplyPromotionType;
