/**
 * Created by methee on 8/4/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { intlShape, defineMessages, FormattedMessage } from 'react-intl';

import iconEdit from './icon-edit.webp';
import iconEditAdd2x from './icon-edit@2x.webp';

import s from './EditButton.css';

const messages = defineMessages({
  btnEdit: {
    id: 'ProjectHello.btnEdit',
    defaultMessage: '##Edit##',
    description: 'Label for edit button',
  },
});

class EditButton extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    className: PropTypes.string,
  };

  static defaultProps = {
    onClick: () => {},
    className: '',
  };

  static contextTypes = {
    intl: intlShape,
  };

  onClick = () => {
    this.props.onClick();
  };

  render() {
    return (
      <button
        className={cx(s.root, this.props.className, this.context.intl.locale)}
        onClick={this.onClick}
      >
        <img src={iconEdit} srcSet={`${iconEditAdd2x} 2x`} alt="edit" />
        <FormattedMessage {...messages.btnEdit} />
      </button>
    );
  }
}

export default withStyles(s)(EditButton);
