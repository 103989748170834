/**
 * Created by methee on 9/12/17.
 */

import {
  GraphQLObjectType as ObjectType,
  GraphQLString as StringType,
  GraphQLInt as IntType,
  GraphQLNonNull as NonNull,
} from 'graphql';

import PropTypes from 'prop-types';

const SundryItemType = new ObjectType({
  name: 'SundryItem',
  fields: {
    id: { type: new NonNull(IntType) },
    title: { type: new NonNull(StringType) },
    description: { type: new NonNull(StringType) },
    price: { type: new NonNull(IntType) },
    max_item_per_booking: { type: new NonNull(IntType) },
    image_thumb: { type: new NonNull(StringType) },
    image_full: { type: new NonNull(StringType) },
    start: { type: new NonNull(StringType) },
    end: { type: new NonNull(StringType) },
  },
});

SundryItemType.shape = () =>
  PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    price: PropTypes.number,
    max_item_per_booking: PropTypes.number,
    image_thumb: PropTypes.string,
    image_full: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
  });

export default SundryItemType;
