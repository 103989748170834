import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './RadioButton.scss';

class RadioButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isChecked: props.isChecked };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({ isChecked: nextProps.isChecked });
  }

  handleChange = event => {
    if (this.props.onChange) {
      this.props.onChange({
        name: this.props.name,
        value: event.target.selected,
      });
    }
  };

  render() {
    const { labelLeft } = this.props;
    const { isChecked } = this.state;
    let label = null;
    if (this.props.label) {
      label = (
        <label
          htmlFor={this.props.label}
          className={cx(labelLeft ? s.labelLeft : s.labelRight)}
        >
          {this.props.label}
        </label>
      );
    }
    return (
      <div className={cx(s.root, this.props.className)}>
        {labelLeft && label}
        <label>
          <span className={s.radioGroup}>
            <input
              id={this.props.id}
              aria-label="radiobutton"
              type="radio"
              onChange={this.handleChange}
              checked={isChecked}
            />

            <div className={s.checked} />
          </span>
        </label>

        {!labelLeft && label}
      </div>
    );
  }
}

RadioButton.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool,
  labelLeft: PropTypes.bool,
};

RadioButton.defaultProps = {
  className: '',
  id: '',
  name: Math.random() * 100,
  label: '',
  onChange: () => {},
  isChecked: false,
  labelLeft: true,
};

export default withStyles(s)(RadioButton);
