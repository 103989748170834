/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';

import s from './BookingProgressHeader.css';

export const BH_ROOM_SELECT = 'select';
export const BH_GUEST_PROFILE = 'personal';
export const BH_PAYMENT = 'payment';

const messages = defineMessages({
  select: {
    id: 'booking.select_room_type',
    defaultMessage: '##{select} Room Type##',
    description:
      'Text title for room select page. Do not remove or translate {select} path. This serve as placeholder for red highlight on title',
  },
  selectHighlight: {
    id: 'booking.select_room_type.select',
    defaultMessage: '##Select##',
    description: "Highlight 'Select' path of room selection page title",
  },
  personal: {
    id: 'booking.personal_details',
    defaultMessage: '##{personal} Details##',
    description:
      'Title of guest detail page. Do not remove or translate {personal} path. It will be translated separately on another key.',
  },
  personalHighlight: {
    id: 'booking.personal_details.personal',
    defaultMessage: '##Personal##',
    description: "'Personal' path of title {personal} Detail",
  },
  payment: {
    id: 'booking.payment_details',
    defaultMessage: '##{payment} Details##',
    description:
      'Header for payment page. Do not remove or translate {payment} path. This is a placeholder and will get translated on separated key',
  },
  paymentHighlight: {
    id: 'booking.payment_details.payment',
    defaultMessage: '##Payment##',
    description: "'Payment' path of {payment} Details",
  },
});

class BookingProgress extends React.Component {
  static propTypes = {
    currentProgress: PropTypes.oneOf([
      BH_ROOM_SELECT,
      BH_GUEST_PROFILE,
      BH_PAYMENT,
    ]).isRequired,
  };

  static contextTypes = {
    intl: intlShape,
  };

  render() {
    const { currentProgress } = this.props;
    const message = messages[currentProgress];
    const highlight = messages[`${currentProgress}Highlight`];
    return (
      <h1 className={cx(s.root, s[this.context.intl.locale])}>
        <FormattedMessage
          {...message}
          values={{
            [currentProgress]: (
              <span className={s.highlight}>
                <FormattedMessage {...highlight} />
              </span>
            ),
          }}
        />
      </h1>
    );
  }
}

export default withStyles(s)(BookingProgress);
