// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.ImageGallery-root {\n  position: relative;\n  background-size: cover;\n  background-position: 50% 50%;\n  width: 100%;\n  height: 180px;\n}\n\n.ImageGallery-image {\n  position: absolute;\n  background-size: cover;\n  background-position: 50% 50%;\n  -webkit-appearance: none;\n  border: none;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n}\n\n.ImageGallery-galleryControl {\n  padding: 5px;\n  color: #d5d6d8;\n  background-color: #fff;\n  cursor: pointer;\n  font-weight: bold;\n  font-size: 1em;\n  line-height: 0;\n  -webkit-appearance: none;\n  border-color: transparent;\n  outline: none;\n}\n\n.ImageGallery-galleryControl:hover {\n  color: #da291c;\n}\n\n.ImageGallery-left {\n  position: absolute;\n  top: 42%;\n  left: -5px;\n  -webkit-box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);\n          box-shadow: -5px 5px 5px rgba(0, 0, 0, 0.1);\n}\n\n.ImageGallery-right {\n  position: absolute;\n  top: 42%;\n  right: -5px;\n  -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);\n          box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.1);\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ImageGallery-root",
	"image": "ImageGallery-image",
	"galleryControl": "ImageGallery-galleryControl",
	"left": "ImageGallery-left",
	"right": "ImageGallery-right"
};
module.exports = exports;
