/* eslint-disable css-modules/no-unused-class */

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import CarouselImages from '../../components/CarouselImages';
import s from './Search.css';

class SearchCarouselImages extends React.Component {
  static propTypes = {
    cityName: PropTypes.string,
    areaImages: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    cityName: '',
    areaImages: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      selectImage: 0,
    };
  }

  onChange = selectImage => {
    this.setState({ selectImage });
  };

  render() {
    const { cityName, areaImages } = this.props;
    const { selectImage } = this.state;
    return (
      <CarouselImages
        images={areaImages}
        title={cityName}
        classNames={s.hero}
        selectImage={selectImage}
        dim
        autoRotateImages
        onChange={this.onChange}
        customController={
          <div className={s.carouselControllerWrapper}>
            <div
              className={cx(
                s.carouselController,
                areaImages.length <= 1 && s.hidden,
              )}
            >
              <ul>
                {areaImages.map(image => (
                  <li // eslint-disable-line jsx-a11y/no-noninteractive-element-interactions
                    onClick={() => {
                      this.setState({
                        selectImage: areaImages.indexOf(image),
                        isClickedGallery: true,
                      });
                    }}
                    className={
                      selectImage === areaImages.indexOf(image)
                        ? s.selected
                        : ''
                    }
                    key={areaImages.indexOf(image)}
                  />
                ))}
              </ul>
            </div>
          </div>
        }
      />
    );
  }
}

export default withStyles(s)(SearchCarouselImages);
