// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.TruncatableText-root {\n  position: relative;\n  font-size: 18px !important;\n}\n\n.TruncatableText-root .TruncatableText-tester {\n  visibility: hidden;\n  position: absolute;\n  top: 0;\n}\n\n.TruncatableText-root .TruncatableText-text {\n  overflow: hidden;\n  -o-text-overflow: ellipsis;\n     text-overflow: ellipsis;\n  text-align: justify;\n}\n\n.TruncatableText-root button {\n  padding: 0;\n  margin: 0;\n  font-size: 16px;\n  -webkit-appearance: none;\n  background: none;\n  border: none;\n  color: #da291c;\n  text-transform: uppercase;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "TruncatableText-root",
	"tester": "TruncatableText-tester",
	"text": "TruncatableText-text"
};
module.exports = exports;
