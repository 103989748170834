/* eslint-disable no-unused-vars */
/**
 * Created by methee on 9/4/17.
 */

/* eslint-disable camelcase */

import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import moment from 'moment';
import googleLibPhone from 'google-libphonenumber';
// import Inspectlet from '../inspectlet';

import {
  USER_FB_LOGIN,
  USER_LOGIN,
  SUCCESS_USER_LOGIN,
  USER_LOGOUT,
  FAIL_USER_LOGIN,
  USER_INIT,
  USER_SIGN_UP,
  SUCCESS_USER_SIGN_UP,
  FAIL_USER_SIGN_UP,
  USER_UPDATE,
  SUCCESS_USER_UPDATE,
  FAIL_USER_UPDATE,
  OPEN_SIGN_IN_MODAL,
  CLOSE_SIGN_IN_MODAL,
  OPEN_SIGN_UP_MODAL,
  CLOSE_SIGN_UP_MODAL,
  FORGOT_PASSWORD,
  FAIL_FORGOT_PASSWORD,
  SUCCESS_FORGOT_PASSWORD,
  RESET_PASSWORD,
  FAIL_RESET_PASSWORD,
  SUCCESS_RESET_PASSWORD,
  INIT_CHANGE_PASSWORD,
  CHANGE_PASSWORD,
  SUCCESS_CHANGE_PASSWORD,
  FAIL_CHANGE_PASSWORD,
  NEW_PASSWORD,
  FAIL_NEW_PASSWORD,
  SUCCESS_NEW_PASSWORD,
  START_SUBSCRIBE_NEWS,
  SUCCESS_SUBSCRIBE_NEWS,
  FAIL_SUBSCRIBE_NEWS,
  OPEN_RESEND_BOOKING_MODAL,
  CLOSE_RESEND_BOOKING_MODAL,
  OPEN_ERROR_MODAL,
  CLOSE_ERROR_MODAL,
  OPEN_DELETE_ACCOUNT_MODAL,
  CLOSE_DELETE_ACCOUNT_MODAL,
} from '../constants';
import UserType from '../data/types/UserType';
import { isStringAlpha, isNonLatinCharacters, isEmail } from '../utils';
import { addGuestProfile, clearBooking } from './booking';
// import mp from '../analytic';

export const UserShape = PropTypes.shape({
  isLoggedIn: PropTypes.bool,
  userLogin: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
  }),
  resendBooking: PropTypes.shape({
    openResendBookingModal: PropTypes.bool,
    bookingNumber: PropTypes.string,
    toEmail: PropTypes.string,
  }),
  user: UserType.shape(),
  errors: PropTypes.arrayOf(PropTypes.string),
  errorMessage: PropTypes.string,
  gettingBooking: PropTypes.bool,
  myBookings: PropTypes.shape(),
});

const setToken = token => {
  const { hostname } = window.location;
  const paths = hostname.split('.');
  const domain = paths.slice(paths.length - 2, paths.length).join('.');
  const cookies = new Cookies();
  cookies.set('rphl.accessToken', token, {
    expires: moment()
      .add(5, 'years')
      .toDate(),
    path: '/',
    domain,
  });
};

const removeToken = () => {
  const { hostname } = window.location;
  const paths = hostname.split('.');
  const domain = paths.slice(paths.length - 2, paths.length).join('.');
  const cookies = new Cookies();
  cookies.remove('rphl.accessToken', {
    path: '/',
    domain,
  });
  cookies.remove('rphl.profile', {
    path: '/',
    domain,
  });
};

export function initializeUser() {
  return async (dispatch, getState, { client }) => {
    let user = null;
    const UserInit = gql`
      mutation Init {
        userInit {
          member_id
          name_title_id
          first_name
          last_name
          email
          birthday
          country_of_residence_code
          prefer_lang
          phoneCountry {
            country
            phone
          }
          loyalty_number
        }
      }
    `;
    const { data } = await client.networkInterface.query({
      query: UserInit,
    });
    if (data) {
      user = data.userInit;
      // if (user) {
      //   Inspectlet.identify(user.email, user.loyalty_number);
      // }
    }
    dispatch({
      type: USER_INIT,
      payload: {
        isLoggedIn: user !== null,
        userLogin: null,
        user,
        errors: [],
        errorMessage: '',
      },
    });
  };
}

export function login(email, password, errorMessage) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: USER_LOGIN,
      payload: {
        email,
        password,
      },
    });
    const UserLogin = gql`mutation Login{
      userLogin(email: "${email}" password: "${password}"){
        member{
          member_id
          name_title_id
          first_name
          last_name
          email
          birthday
          country_of_residence_code
          prefer_lang
          phoneCountry{
            phone
            country
          }
          loyalty_number
        }
        access_token
      }
    }`;
    const errorsInput = [];
    if (email === '' || !isEmail(email)) {
      errorsInput.push('email');
    }
    if (password === '') {
      errorsInput.push('password');
    }
    if (errorsInput.length > 0) {
      dispatch({
        type: FAIL_USER_LOGIN,
        payload: {
          errorMessage,
          errors: ['email', 'password'],
        },
      });
    } else {
      const { data, errors } = await client.networkInterface.query({
        query: UserLogin,
      });
      if (!errors) {
        setToken(data.userLogin.access_token);
        const {
          userLogin: { member },
        } = data;
        dispatch({
          type: SUCCESS_USER_LOGIN,
          payload: member,
        });
        if (getState().booking && !getState().booking.bookingId) {
          dispatch(
            addGuestProfile({
              nameTitle: member.name_title_id,
              firstName: member.first_name,
              lastName: member.last_name,
              birthday: moment(member.birthday),
              email: member.email,
              phoneCountry: member.phoneCountry,
              country: member.country_of_residence_code,
            }),
          );
        }
        // mp.signIn({
        //   loyaltyNumber: member.loyalty_number,
        //   nameTitle: member.name_title_id,
        //   firstName: member.first_name,
        //   lastName: member.last_name,
        //   birthday: moment(member.birthday),
        //   email: member.email,
        //   preferLang: member.prefer_lang,
        //   phoneCountry: member.phoneCountry,
        //   country: member.country_of_residence_code,
        // });
        // Inspectlet.identify(member.email, member.loyalty_number);
      } else {
        dispatch({
          type: FAIL_USER_LOGIN,
          payload: {
            errorMessage: JSON.parse(errors[0].message).message,
            errors: ['email', 'password'],
          },
        });
      }
    }
  };
}

export function fbLogin() {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: USER_FB_LOGIN,
    });

    const UserLogin = gql`
      mutation Login {
        fbLogin {
          access_token
        }
      }
    `;
    const { data, errors } = await client.networkInterface.query({
      query: UserLogin,
    });
    if (!errors) {
      setToken(data.fbLogin.access_token);
      await dispatch(initializeUser());
      const member = getState().user.user;
      // mp.signIn({
      //   loyaltyNumber: member.loyalty_number,
      //   nameTitle: member.name_title_id,
      //   firstName: member.first_name,
      //   lastName: member.last_name,
      //   birthday: moment(member.birthday),
      //   email: member.email,
      //   preferLang: member.prefer_lang,
      //   phoneCountry: member.phoneCountry,
      //   country: member.country_of_residence_code,
      // });
      // Inspectlet.identify(member.email, member.loyalty_number);
    }
  };
}

export function loginWithToken(token) {
  return async (dispatch, getState) => {
    setToken(token);
    await dispatch(initializeUser());
    const {
      user: {
        loyalty_number,
        name_title_id,
        first_name,
        last_name,
        birthday,
        country_of_residence_code,
        prefer_lang,
        phoneCountry,
        email,
      },
    } = getState().user;
    // mp.signUp({
    //   loyaltyNumber: loyalty_number,
    //   nameTitle: name_title_id,
    //   firstName: first_name,
    //   lastName: last_name,
    //   birthday: moment(birthday),
    //   country: country_of_residence_code,
    //   preferLang: prefer_lang,
    //   phoneCountry,
    //   email,
    // });
    // Inspectlet.identify(email, loyalty_number);
  };
}

export function signOut() {
  return async (dispatch, getState, { history }) => {
    removeToken();
    clearBooking();
    history.push('/');
    dispatch({
      type: USER_LOGOUT,
    });
  };
}

export function signUp({
  nameTitle,
  firstName,
  lastName,
  birthday,
  country,
  preferLang,
  phoneCountry,
  email,
  password,
}) {
  return async (dispatch, getState, { client, history }) => {
    dispatch({
      type: USER_SIGN_UP,
      payload: {
        nameTitle,
        firstName,
        lastName,
        birthday,
        country,
        preferLang,
        phoneCountry,
        email,
        password,
      },
    });
    const errorsInput = [];
    if (
      firstName === '' ||
      !(isStringAlpha(firstName) || isNonLatinCharacters(firstName))
    ) {
      errorsInput.push('firstName');
    }
    if (
      !lastName === '' ||
      !(isStringAlpha(lastName) || isNonLatinCharacters(lastName))
    ) {
      errorsInput.push('lastName');
    }
    if (birthday === null) {
      errorsInput.push('birthday');
    }
    if (country === '') {
      errorsInput.push('country');
    }
    if (phoneCountry === null) {
      errorsInput.push('phone');
    } else {
      try {
        const phoneUtil = googleLibPhone.PhoneNumberUtil.getInstance();
        const phoneNumber = phoneUtil.parse(
          phoneCountry.phone,
          phoneCountry.country,
        );
        if (!phoneUtil.isValidNumber(phoneNumber)) {
          errorsInput.push('phone');
        }
      } catch (e) {
        errorsInput.push('phone');
      }
    }
    if (email === '' || !isEmail(email)) {
      errorsInput.push('email');
    }

    const passwordRegex = new RegExp('^.{4,}$');
    if (!passwordRegex.test(password)) {
      errorsInput.push('password');
    }
    if (errorsInput.length > 0) {
      dispatch({
        type: FAIL_USER_SIGN_UP,
        payload: {
          errorMessage: '',
          errors: errorsInput,
        },
      });
    } else {
      const UserSignUp = gql`mutation Signup{
        userSignUp(
          nameTitle:${nameTitle}
          firstName:"${firstName}"
          lastName:"${lastName}"
          birthday:"${birthday.format('YYYY-MM-DD')}"
          country:"${country}"
          preferLang:"${preferLang}"
          phoneCountry:"${encodeURI(JSON.stringify(phoneCountry))}"
          email:"${email}"
          password:"${password}"){
          member{
            member_id
            name_title_id
            first_name
            last_name
            email
            birthday
            country_of_residence_code
            prefer_lang
            phoneCountry {
              phone
              country
            }
            loyalty_number
          }
          access_token
        }
      }`;
      const { data, errors } = await client.networkInterface.query({
        query: UserSignUp,
      });
      if (!errors) {
        setToken(data.userSignUp.access_token);
        dispatch({
          type: SUCCESS_USER_SIGN_UP,
          payload: data.userSignUp.member,
        });
        history.push({ pathname: '/' });
        // mp.signUp({
        //   loyaltyNumber: data.userSignUp.member.loyalty_number,
        //   nameTitle,
        //   firstName,
        //   lastName,
        //   birthday,
        //   country,
        //   phoneCountry,
        //   preferLang,
        //   email,
        // });
        // Inspectlet.identify(email, data.userSignUp.member.loyalty_number);
      } else {
        const respError = JSON.parse(errors[0].message);
        let errorMessage = `${respError.message}<br/>`;
        const errorFields = [];
        Object.keys(respError.error).forEach(key => {
          errorMessage += `•${key}: ${respError.error[key]}<br/>`;
          errorFields.push(key);
        });
        dispatch({
          type: FAIL_USER_SIGN_UP,
          payload: {
            errorMessage,
            errors: errorFields,
          },
        });
      }
    }
  };
}

export function updateProfile({
  nameTitle,
  firstName,
  lastName,
  birthday,
  country,
  preferLang,
  phoneCountry,
  email,
  password,
}) {
  return async (dispatch, getState, { client }) => {
    dispatch({
      type: USER_UPDATE,
      payload: {
        nameTitle,
        firstName,
        lastName,
        birthday,
        country,
        preferLang,
        email,
        password,
      },
    });
    const errorsInput = [];
    if (
      firstName === '' ||
      !(isStringAlpha(firstName) || isNonLatinCharacters(firstName))
    ) {
      errorsInput.push('firstName');
    }
    if (
      !lastName === '' ||
      !(isStringAlpha(lastName) || isNonLatinCharacters(lastName))
    ) {
      errorsInput.push('lastName');
    }
    if (birthday === null) {
      errorsInput.push('birthday');
    }
    if (country === '') {
      errorsInput.push('country');
    }
    if (phoneCountry === null) {
      errorsInput.push('phone');
    } else {
      try {
        const phoneUtil = googleLibPhone.PhoneNumberUtil.getInstance();
        const phoneNumber = phoneUtil.parse(
          phoneCountry.phone,
          phoneCountry.country,
        );
        if (!phoneUtil.isValidNumber(phoneNumber)) {
          errorsInput.push('phone');
        }
      } catch (e) {
        errorsInput.push('phone');
      }
    }
    if (email === '' || !isEmail(email)) {
      errorsInput.push('email');
    }
    if (errorsInput.length > 0) {
      dispatch({
        type: FAIL_USER_UPDATE,
        payload: {
          errorMessage: '',
          errors: errorsInput,
        },
      });
    } else {
      const UpdateProfile = gql`mutation UpdateProfile{
        updateProfile(
          nameTitle:${nameTitle}
          firstName:"${firstName}"
          lastName:"${lastName}"
          birthday:"${birthday.format('YYYY-MM-DD')}"
          country:"${country}"
          preferLang:"${preferLang}"
          phoneCountry:"${encodeURI(JSON.stringify(phoneCountry))}"
          email:"${email}"
          password:"${password}"){
          member_id
          name_title_id
          first_name
          last_name
          email
          birthday
          country_of_residence_code
          prefer_lang
          phoneCountry {
            phone
            country
          }
          loyalty_number
        }
      }`;
      const { data, errors } = await client.networkInterface.query({
        query: UpdateProfile,
      });
      if (!errors) {
        await dispatch({
          type: SUCCESS_USER_UPDATE,
          payload: data.updateProfile,
        });
        await dispatch({
          type: USER_UPDATE,
          payload: {
            nameTitle,
            firstName,
            lastName,
            birthday,
            country,
            preferLang,
            phoneCountry,
            email,
            password,
          },
        });
        // mp.updateUserData({
        //   loyaltyNumber: data.updateProfile.loyalty_number,
        //   nameTitle,
        //   firstName,
        //   lastName,
        //   birthday,
        //   country,
        //   preferLang,
        //   phoneCountry,
        //   email,
        // });
        // Inspectlet.identify(email, data.updateProfile.loyalty_number);
      } else {
        const respError = JSON.parse(errors[0].message);
        let errorMessage = `${respError.message}<br/>`;
        const errorFields = [];
        Object.keys(respError.error).forEach(key => {
          errorMessage += `•${key}: ${respError.error[key]}<br/>`;
          errorFields.push(key);
        });
        dispatch({
          type: FAIL_USER_UPDATE,
          payload: {
            errorMessage,
            errors: errorFields,
          },
        });
      }
    }
  };
}

export function openResendBookingModal(bookingNumber, toEmail) {
  return async dispatch => {
    dispatch({
      type: OPEN_RESEND_BOOKING_MODAL,
      payload: {
        bookingNumber,
        toEmail,
      },
    });
  };
}

export function closeResendBookingModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_RESEND_BOOKING_MODAL,
    });
  };
}

export function deleteAccount () {
  return async (dispatch, getState, { client, history } ) => {
    const { user } = getState().user;
    const { member_id } = user;

    const query = gql`mutation DeleteAccount {
      deleteAccount (memberid: ${member_id})
    }`

    const { data, errors } = await client.networkInterface.query({
      query: query,
    });

    if(data.deleteAccount) {
      await dispatch({
        type: CLOSE_DELETE_ACCOUNT_MODAL
      })
      removeToken();
      clearBooking();
      history.push('/');
      await dispatch({
        type: USER_LOGOUT
      })
    }
  }
}

export function openDeleteAccountModal() {
  return async dispatch => {
    dispatch({
      type: OPEN_DELETE_ACCOUNT_MODAL
    })
  }
}

export function closeDeleteAccountModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_DELETE_ACCOUNT_MODAL
    })
  }
}

export function openSignInModal() {
  return async dispatch => {
    dispatch({
      type: OPEN_SIGN_IN_MODAL,
    });
  };
}

export function closeSignInModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_SIGN_IN_MODAL,
    });
  };
}

export function openSignUpModal() {
  return async dispatch => {
    dispatch({
      type: OPEN_SIGN_UP_MODAL,
    });
  };
}

export function closeSignUpModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_SIGN_UP_MODAL,
    });
  };
}

export function openErrorModal() {
  return async dispatch => {
    dispatch({
      type: OPEN_ERROR_MODAL,
    });
  };
}

export function closeErrorModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_ERROR_MODAL,
    });
  };
}

export function forgotPassword(email) {
  return async (dispatch, getState, { client }) => {
    const { user } = getState();
    if (user.forgot && user.forgot.isSubmitting) {
      return;
    }
    dispatch({
      type: FORGOT_PASSWORD,
    });

    if (!isEmail(email)) {
      dispatch({
        type: FAIL_FORGOT_PASSWORD,
      });
      return;
    }
    const FogotPassword = gql`mutation ForgotPassword{
      forgotPassword(email: "${email}")
    }`;

    const { errors } = await client.networkInterface.query({
      query: FogotPassword,
    });
    if (!errors) {
      dispatch({
        type: SUCCESS_FORGOT_PASSWORD,
      });
    } else {
      dispatch({
        type: FAIL_FORGOT_PASSWORD,
      });
    }
  };
}

export function initNewPassword() {
  return async dispatch => {
    dispatch({
      type: NEW_PASSWORD,
    });
  };
}

export function newPassword(password, confirmPassword, token) {
  return async (dispatch, getState, { client }) => {
    const { user } = getState();
    if (user.new_password && user.new_password.isSubmitting) {
      return;
    }

    dispatch({
      type: NEW_PASSWORD,
    });

    if (password !== confirmPassword) {
      dispatch({
        type: FAIL_NEW_PASSWORD,
        payload: {
          errors: ['confirm_password'],
        },
      });
      return;
    }
    const NewPassword = gql`mutation NewPassword{
      newPassword(password: "${password}" token: "${token}")
    }`;
    const { errors } = await client.networkInterface.query({
      query: NewPassword,
    });
    let errorMessage = '';
    const errorFields = [];
    if (typeof errors === 'undefined') {
      dispatch({
        type: SUCCESS_NEW_PASSWORD,
      });
    } else {
      const message = JSON.parse(errors[0].message);
      if (message.error) {
        Object.keys(message.error).forEach(key => {
          errorFields.push(key);
        });
      } else if (message.message) {
        errorMessage = message.message;
      }

      dispatch({
        type: FAIL_NEW_PASSWORD,
        payload: {
          errors: {
            errorMessage,
            errorFields,
          },
        },
      });
    }
  };
}

export function initChangePassword() {
  return async dispatch => {
    dispatch({
      type: INIT_CHANGE_PASSWORD,
    });
  };
}

export function changePassword(currentPassword, password, confirmPassword) {
  return async (dispatch, getState, { client }) => {
    const { user } = getState();
    if (user.change && user.change.isSubmitting) {
      return;
    }

    dispatch({
      type: CHANGE_PASSWORD,
    });

    if (password !== confirmPassword) {
      dispatch({
        type: FAIL_CHANGE_PASSWORD,
        payload: {
          errors: ['confirm_password'],
        },
      });
      return;
    }
    const ChangePassword = gql`mutation ChangePassword{
      changePassword(newPassword: "${password}" currentPassword: "${currentPassword}")
    }`;
    const { errors } = await client.networkInterface.query({
      query: ChangePassword,
    });
    if (typeof errors === 'undefined') {
      dispatch({
        type: SUCCESS_CHANGE_PASSWORD,
      });
    } else {
      const message = JSON.parse(errors[0].message);
      const errorFields = [];
      Object.keys(message.error).forEach(key => {
        errorFields.push(key);
      });
      dispatch({
        type: FAIL_CHANGE_PASSWORD,
        payload: {
          errors: errorFields,
        },
      });
    }
  };
}

export function resetPassword(token, password, confirmPassword) {
  return async (dispatch, getState, { client }) => {
    const { user } = getState();
    if (user.reset && user.reset.isSubmitting) {
      return;
    }

    dispatch({
      type: RESET_PASSWORD,
    });

    if (password !== confirmPassword) {
      dispatch({
        type: FAIL_RESET_PASSWORD,
      });
      return;
    }
    const ResetPassword = gql`mutation ResetPassword{
      resetPassword(password: "${password}" token: "${token}")
    }`;
    const { errors } = await client.networkInterface.query({
      query: ResetPassword,
    });
    if (typeof errors === 'undefined') {
      dispatch({
        type: SUCCESS_RESET_PASSWORD,
      });
    } else {
      dispatch({
        type: FAIL_RESET_PASSWORD,
      });
    }
  };
}

export function subscribeNewsletter(email, lang, source) {
  return async (dispatch, getState, { client }) => {
    if (email) {
      dispatch({
        type: START_SUBSCRIBE_NEWS,
      });
      const SubscribeNews = gql`mutation SubscribeNews{
      newsletterSubscribe(email: "${email.trim()}" lang: "${lang}" source: "${source}")
    }`;
      const { errors } = await client.networkInterface.query({
        query: SubscribeNews,
      });
      if (typeof errors === 'undefined') {
        dispatch({
          type: SUCCESS_SUBSCRIBE_NEWS,
        });
        if (window.dataLayer) {
          window.dataLayer.push({ event: 'newsletterSubscription' });
        }
      } else {
        dispatch({
          type: FAIL_SUBSCRIBE_NEWS,
        });
      }
    }
  };
}
