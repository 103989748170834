import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import s from './CheckInProgressStatus.css';
import CheckInProgressBadge from './CheckInProgressBadge';

export const CI_BOOKING_DETAILS = 'select';
export const CI_PAYMENT = 'payment';
export const CI_VERIFY_ID = 'verify_id';
export const CI_ROOM_PREFERENCES = 'room_preferences';

const progresses = [
  CI_BOOKING_DETAILS,
  CI_PAYMENT,
  CI_VERIFY_ID,
  CI_ROOM_PREFERENCES,
];

const messages = defineMessages({
  bookingDetails: {
    id: 'ProjectHello.booking_details',
    defaultMessage: '##Booking Details##',
    description: 'Label for booking details',
  },
  payment: {
    id: 'ProjectHello.payment',
    defaultMessage: '##Payment##',
    description: 'Label for payment',
  },
  verifyId: {
    id: 'ProjectHello.verifyId',
    defaultMessage: '##Verify ID##',
    description: 'Label for verify id',
  },
  roomPreferences: {
    id: 'ProjectHello.roomPreferences',
    defaultMessage: '##Room Preferences##',
    description: 'Label for room preferences',
  },
});

class CheckInProgressStatus extends React.Component {
  static propTypes = {
    currentProgress: PropTypes.oneOf([
      CI_BOOKING_DETAILS,
      CI_PAYMENT,
      CI_VERIFY_ID,
      CI_ROOM_PREFERENCES,
    ]).isRequired,
  };

  static contextTypes = {
    intl: intlShape,
  };

  render() {
    const { currentProgress } = this.props;
    const index = progresses.indexOf(currentProgress);
    return (
      <div className={s.root}>
        <ul>
          <li>
            <CheckInProgressBadge
              badgeNumber={1}
              label={this.context.intl.formatMessage(messages.bookingDetails)}
              selected={currentProgress === CI_BOOKING_DETAILS}
              passed={index > 0}
            />
          </li>
          <li>
            <span className={cx(s.line, index > 0 && s.passed)} />
          </li>
          <li>
            <CheckInProgressBadge
              badgeNumber={2}
              label={this.context.intl.formatMessage(messages.payment)}
              selected={currentProgress === CI_PAYMENT}
              passed={index > 1}
            />
          </li>
          <li>
            <span className={cx(s.line, index > 1 && s.passed)} />
          </li>
          <li>
            <CheckInProgressBadge
              badgeNumber={3}
              label={this.context.intl.formatMessage(messages.verifyId)}
              selected={currentProgress === CI_VERIFY_ID}
              passed={index > 2}
            />
          </li>
          <li>
            <span className={cx(s.line, index > 2 && s.passed)} />
          </li>
          <li>
            <CheckInProgressBadge
              badgeNumber={4}
              label={this.context.intl.formatMessage(messages.roomPreferences)}
              selected={currentProgress === CI_ROOM_PREFERENCES}
              passed={index > 3}
            />
          </li>
        </ul>
      </div>
    );
  }
}

export default withStyles(s)(CheckInProgressStatus);
