// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.ResendBookingModal-root {\n  position: fixed;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  z-index: 999;\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -ms-flex-line-pack: center;\n      align-content: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  overflow: scroll;\n  overflow-x: hidden;\n  background: rgba(130, 130, 130, 0.5);\n}\n\n.ResendBookingModal-root::-webkit-scrollbar {\n  display: none;\n}\n\n.ResendBookingModal-root .ResendBookingModal-row {\n  margin-bottom: 15px;\n}\n\n.ResendBookingModal-body {\n  padding: 15px 30px;\n  margin: 0 auto;\n  position: relative;\n  background-color: #f5f5f5;\n}\n\n.ResendBookingModal-body h1,\n.ResendBookingModal-body b {\n  font-weight: normal;\n  text-transform: uppercase;\n  margin-left: 5px;\n}\n\n.ResendBookingModal-body b {\n  margin-right: 15px;\n}\n\n.ResendBookingModal-body .ResendBookingModal-btnClose {\n  padding: 0;\n  margin: 0;\n  position: absolute;\n  top: 0;\n  right: 2px;\n  -webkit-appearance: none;\n  border: none;\n  background: none;\n  font-size: 2em;\n  font-weight: normal;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "ResendBookingModal-root",
	"row": "ResendBookingModal-row",
	"body": "ResendBookingModal-body",
	"btnClose": "ResendBookingModal-btnClose"
};
module.exports = exports;
