import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import { UserShape, openSignInModal, signOut } from '../../actions/user';

import s from './HotelPromotionButton.css';
import Link from '../Link/Link';


class HotelPromotionButton extends React.Component {
  static propTypes = {
    user: UserShape,
    openSignInModal: PropTypes.func,
    signOut: PropTypes.func,
  };

  static defaultProps = {
    user: null,
    openSignInModal: () => {},
    signOut: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  render() {
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <Link className={s.link} to='/hotel/bgc-the-fort'>
          <div className={s.background} />
        </Link>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.user,
});

const mapDispatch = {
  openSignInModal,
  signOut,
};

export default connect(
  mapState,
  mapDispatch,
)(withStyles(s)(HotelPromotionButton));
