/**
 * Created by methee on 9/1/17.
 */
import {
  GraphQLObjectType as ObjectType,
  GraphQLInt as IntType,
  GraphQLFloat as FloatType,
  GraphQLString as StringType,
  GraphQLNonNull as NonNull,
  GraphQLBoolean as BooleanType,
  GraphQLList as List,
} from 'graphql';

import PropTypes from 'prop-types';

import HotelType from './HotelType';

const BookingType = new ObjectType({
  name: 'Booking',
  fields: {
    booking_id: { type: new NonNull(IntType) },
    booking_code: { type: new NonNull(StringType) },
    check_in: { type: new NonNull(StringType) },
    check_out: { type: new NonNull(StringType) },
    comment: { type: new NonNull(StringType) },
    tax: { type: new NonNull(FloatType) },
    amount: { type: new NonNull(FloatType) },
    total: { type: new NonNull(FloatType) },
    payment_method: { type: new NonNull(StringType) },
    payment_status: { type: new NonNull(StringType) },
    currency: { type: new NonNull(StringType) },
    card_type: { type: new NonNull(StringType) },
    suffix_card_number: { type: new NonNull(StringType) },
    created_at: { type: new NonNull(StringType) },
    updated_at: { type: new NonNull(StringType) },
    status: { type: new NonNull(StringType) },
    is_editable: { type: new NonNull(BooleanType) },
    is_owner: { type: new NonNull(BooleanType) },
    is_birthday: { type: new NonNull(BooleanType) },
    ics: { type: new NonNull(StringType) },
    access_token: { type: StringType },
    paid_at: { type: StringType },
    payment_merchant_name: { type: StringType },
    breakfast: {
      type: new ObjectType({
        name: 'BookingBreakfast',
        fields: {
          name: { type: new NonNull(StringType) },
          price_per_guest: { type: new NonNull(FloatType) },
          price_per_guest_no_tax: { type: new NonNull(FloatType) },
        },
      }),
    },
    smoking: {
      type: new ObjectType({
        name: 'BookingSmoking',
        fields: {
          hotel_id: { type: new NonNull(FloatType) },
          price_per_night: { type: new NonNull(FloatType) },
        },
      }),
    },
    pet_friendly: {
      type: new ObjectType({
        name: 'BookingPetFriendly',
        fields: {
          hotel_id: { type: new NonNull(FloatType) },
          price_per_night: { type: new NonNull(FloatType) },
        },
      }),
    },
    omise: {
      type: new ObjectType({
        name: 'omise',
        fields: {
          validate3DsOmise: {
            type: new ObjectType({
              name: 'validate3DsOmise',
              fields: {
                dataOmise: {
                  type: new ObjectType({
                    name: 'dataOmise',
                    fields: {
                      authorizeUri: { type: new NonNull(StringType) },
                    },
                  }),
                },
              },
            }),
          },
        },
      }),
    },
    maya: {
      type: new ObjectType({
        name: 'maya',
        fields: {
          validate3DsMaya: {
            type: new ObjectType({
              name: 'validate3DsMaya',
              fields: {
                dataMaya: {
                  type: new ObjectType({
                    name: 'dataMaya',
                    fields: {
                      authorizeUri: { type: new NonNull(StringType) },
                    },
                  }),
                },
              },
            }),
          },
        },
      }),
    },
    // adyen: {
    //   type: new ObjectType({
    //     name: 'adyen',
    //     fields: {
    //       validate3Ds: {
    //         type: new ObjectType({
    //           name: 'validate3Ds',
    //           fields: {
    //             redirectToIssuerUrl: { type: new NonNull(StringType) },
    //             data: {
    //               type: new ObjectType({
    //                 name: 'data',
    //                 fields: {
    //                   url: { type: new NonNull(StringType) },
    //                   paReq: { type: new NonNull(StringType) },
    //                   md: { type: new NonNull(StringType) },
    //                   termUrl: { type: new NonNull(StringType) },
    //                   token: { type: new NonNull(StringType) },
    //                   sum: { type: new NonNull(StringType) },
    //                 },
    //               }),
    //             },
    //           },
    //         }),
    //       },
    //     },
    //   }),
    // },
    check_in_data: {
      type: new ObjectType({
        name: 'CheckInDate',
        fields: {
          check_in_status: { type: new NonNull(IntType) },
          check_in_available_date: { type: StringType },
          pin: {
            type: new ObjectType({
              name: 'Pin',
              fields: {
                code: { type: StringType },
                image: {
                  type: new ObjectType({
                    name: 'PinImage',
                    fields: {
                      url: { type: StringType },
                      width: { type: IntType },
                      height: { type: IntType },
                    },
                  }),
                },
              },
            }),
          },
          retry_step: { type: StringType },
        },
      }),
    },
    additional_products: {
      type: new List(
        new ObjectType({
          name: 'AdditionalItems',
          fields: {
            id: { type: new NonNull(IntType) },
            amount: { type: new NonNull(IntType) },
            quantity: { type: new NonNull(IntType) },
            price: { type: new NonNull(IntType) },
            detail: {
              type: new ObjectType({
                name: 'AdditionalItemsDetail',
                fields: {
                  title: { type: new NonNull(StringType) },
                },
              }),
            },
          },
        }),
      ),
    },
    hotel: { type: HotelType },
    rooms: {
      type: new List(
        new ObjectType({
          name: 'BookingRoom',
          fields: {
            room_id: { type: new NonNull(IntType) },
            rate_id: { type: new NonNull(IntType) },
            name: { type: new NonNull(StringType) },
            guests: {
              type: new List(
                new ObjectType({
                  name: 'BookingGuest',
                  fields: {
                    guest_id: { type: new NonNull(IntType) },
                    name_title_id: { type: new NonNull(IntType) },
                    name_title: { type: StringType },
                    prefer_lang: { type: StringType },
                    first_name: { type: new NonNull(StringType) },
                    last_name: { type: new NonNull(StringType) },
                    email: { type: new NonNull(StringType) },
                    phoneCountry: {
                      type: new ObjectType({
                        name: 'BookingPhoneCountry',
                        fields: {
                          phone: { type: StringType },
                          country: { type: StringType },
                        },
                      }),
                    },
                    country_of_residence_code: {
                      type: new NonNull(StringType),
                    },
                    type: { type: new NonNull(StringType) },
                  },
                }),
              ),
            },
          },
        }),
      ),
    },
  },
});

BookingType.shape = () =>
  PropTypes.shape({
    booking_id: PropTypes.number,
    booking_code: PropTypes.string,
    check_in: PropTypes.string,
    check_out: PropTypes.string,
    comment: PropTypes.string,
    tax: PropTypes.number,
    amount: PropTypes.number,
    total: PropTypes.number,
    payment_method: PropTypes.string,
    currency: PropTypes.string,
    card_type: PropTypes.string,
    suffix_card_number: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    status: PropTypes.string,
    is_editable: PropTypes.bool,
    is_owner: PropTypes.bool,
    is_birthday: PropTypes.bool,
    ics: PropTypes.string,
    check_in_data: PropTypes.shape({
      check_in_status: PropTypes.string,
      check_in_available_date: PropTypes.string,
      pin: PropTypes.shape({
        code: PropTypes.string,
        image: PropTypes.shape({
          url: PropTypes.string,
          width: PropTypes.number,
          height: PropTypes.number,
        }),
      }),
      retry_step: PropTypes.string,
    }),
    hotel: HotelType.shape(),
    rooms: PropTypes.arrayOf(
      PropTypes.shape({
        room_id: PropTypes.number,
        rate_id: PropTypes.number,
        guests: PropTypes.arrayOf(
          PropTypes.shape({
            guest_id: PropTypes.number,
            name_title_id: PropTypes.number,
            first_name: PropTypes.string,
            last_name: PropTypes.string,
            email: PropTypes.string,
            phoneCountry: PropTypes.object,
            country_code: PropTypes.string,
            type: PropTypes.string,
            prefer_lang: PropTypes.string,
          }),
        ),
      }),
    ),
  });

export default BookingType;
