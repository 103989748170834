// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.HeroWithTitle-root {\n  position: relative;\n  width: 100%;\n  height: 180px;\n}\n\n.HeroWithTitle-background {\n  background-size: cover;\n  position: absolute;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n}\n\n.HeroWithTitle-title {\n  position: absolute;\n  bottom: 30px;\n  width: 100%;\n  text-align: center;\n  font-family: 'BarlowBold', sans-serif !important;\n  font-size: 38px;\n  line-height: 56px;\n  font-weight: bold;\n  color: #fff;\n  text-transform: uppercase;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "HeroWithTitle-root",
	"background": "HeroWithTitle-background",
	"title": "HeroWithTitle-title"
};
module.exports = exports;
