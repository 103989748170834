// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.MeetingRoomForm-root {\n  position: absolute;\n}\n\n.MeetingRoomForm-noLink {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  -ms-flex-line-pack: center;\n      align-content: center;\n  width: 100%;\n  height: 100%;\n}\n\n.MeetingRoomForm-marginAuto {\n  margin: auto;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "MeetingRoomForm-root",
	"noLink": "MeetingRoomForm-noLink",
	"marginAuto": "MeetingRoomForm-marginAuto"
};
module.exports = exports;
