import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import s from './TextBox.css';

class TextBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
      errorMessage: props.errorMessage,
      isError: props.isError,
      isSuccess: props.isSuccess,
    };
  }

  componentDidMount() {
    this.props.onRef(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { value, isError, isSuccess, errorMessage } = nextProps;
    this.setState({
      value,
      isError,
      isSuccess,
      errorMessage,
    });
  }

  onBlur = event => {
    let isValid = true;
    const { validation, onBlur } = this.props;
    if (validation) {
      isValid = validation(event.target.value);
      this.setState({
        isError: !isValid,
      });
    }

    this.setState({
      value: event.target.value,
    });

    if (onBlur) {
      onBlur(event.target.value);
    }
  };

  onChange = event => {
    const { onChange } = this.props;

    this.setState({
      value: event.target.value,
    });

    if (onChange) {
      onChange(event.target.value);
    }
  };

  onClick = () => {
    const { onClick } = this.props;
    if (onClick) {
      onClick();
    }
  };

  focus() {
    if (this.textInput) {
      this.textInput.focus();
    }
  }

  render() {
    let disabled = {};
    const {
      maxLines,
      className,
      inputType,
      name,
      isDisabled,
      readOnly,
      button,
      icon,
      placeholder,
      shadow,
      showFloatingLabel,
      errorBorderColor,
    } = this.props;

    const { errorMessage, isError, isSuccess, value } = this.state;

    if (isDisabled) {
      disabled = {
        disabled: 'disabled',
      };
    }

    return (
      <div
        className={cx(
          s.root,
          shadow && s.shadow,
          value && s.hasValue,
          showFloatingLabel && s.hasLabel,
          icon && s.hasIcon,
          isError && s.error,
        )}
      >
        {(isError || isSuccess) && (
          <div
            className={cx(s.border, isError && s.error, isSuccess && s.success)}
            style={{ borderColor: errorBorderColor || '' }}
          />
        )}
        {icon ? (
          <span className={s.icon}>
            <img
              src={icon.normal}
              srcSet={`${icon.add2x} 2x`}
              alt=""
              width={this.props.width}
              height={this.props.height}
            />
          </span>
        ) : (
          ''
        )}
        {maxLines > 1 ? (
          <textarea
            ref={input => {
              this.textInput = input;
            }}
            name={name}
            value={value}
            onClick={this.onClick}
            className={cx(className)}
            key="input"
            rows={maxLines}
            onChange={this.onChange}
            onBlur={this.onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            {...disabled}
          />
        ) : (
          <input
            ref={input => {
              this.textInput = input;
            }}
            name={name}
            value={value}
            onClick={this.onClick}
            className={cx(className, icon && s.hasIcon, button && s.hasButton)}
            key="input"
            type={inputType}
            onChange={this.onChange}
            onBlur={this.onBlur}
            placeholder={placeholder}
            readOnly={readOnly}
            {...disabled}
          />
        )}
        {button ? <span className={s.button}>{button}</span> : ''}
        <label className={cx(showFloatingLabel && value !== '' && s.show)}>
          {placeholder}
        </label>
        {isError && errorMessage !== '' && (
          <small
            className={s.errorMessage}
            dangerouslySetInnerHTML={{
              __html: errorMessage,
            }}
          />
        )}
      </div>
    );
  }
}

TextBox.propTypes = {
  maxLines: PropTypes.number,
  className: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  errorMessage: PropTypes.string,
  isError: PropTypes.bool,
  errorBorderColor: PropTypes.string,
  isSuccess: PropTypes.bool,
  inputType: PropTypes.oneOf(['text', 'tel', 'password', 'email']),
  validation: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.string,
  isDisabled: PropTypes.bool,
  onClick: PropTypes.func,
  onRef: PropTypes.func,
  readOnly: PropTypes.bool,
  button: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  icon: PropTypes.shape({
    normal: PropTypes.string.isRequired,
    add2x: PropTypes.string.isRequired,
  }),
  placeholder: PropTypes.string,
  shadow: PropTypes.bool,
  showFloatingLabel: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
};

TextBox.defaultProps = {
  maxLines: 1,
  inputType: 'text',
  errorMessage: '',
  errorBorderColor: '',
  isError: false,
  isSuccess: false,
  isDisabled: false,
  readOnly: false,
  placeholder: '',
  className: '',
  onChange: () => {},
  onRef: () => {},
  onBlur: () => {},
  validation: null,
  name: '',
  value: '',
  onClick: () => {},
  icon: null,
  button: null,
  shadow: false,
  showFloatingLabel: true,
  width: '',
  height: '',
};

export default withStyles(s)(TextBox);
