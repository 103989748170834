/**
 * Created by methee on 9/13/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';

import Button from '../Button';

import {
  TYPE_ERROR,
  TYPE_OK,
  TYPE_NOTICE,
  TYPE_CONFIRM,
  confirmFlashMessage,
  removeFlashMessage,
} from '../../actions/flashMessage';
import s from './FlashMessage.css';

const messages = defineMessages({
  notice: {
    id: 'flashMessage.notice',
    defaultMessage: '##Notice##',
    description: 'Title for Success dialog box',
  },
  success: {
    id: 'flashMessage.success',
    defaultMessage: '##Success##',
    description: 'Title for Success dialog box',
  },
  error: {
    id: 'flashMessage.error',
    defaultMessage: '##Error##',
    description: 'Title for Error dialog box',
  },
  confirmation: {
    id: 'flashMessage.confirmation',
    defaultMessage: '##Confirmation##',
    description: 'Title for Confirmation dialog box',
  },
  confirmButton: {
    id: 'flashMessage.confirmButton',
    defaultMessage: '##Confirm##',
    description: 'Label for confirm button',
  },
  closeButton: {
    id: 'flashMessage.closeButton',
    defaultMessage: '##Close##',
    description: 'Close button',
  },
});

class FlashMessage extends React.Component {
  static propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf([TYPE_ERROR, TYPE_OK, TYPE_CONFIRM]).isRequired,
    confirmFlashMessage: PropTypes.func,
    removeFlashMessage: PropTypes.func,
  };

  static contextTypes = {
    intl: intlShape.isRequired,
  };

  static defaultProps = {
    confirmFlashMessage: () => {},
    removeFlashMessage: () => {},
  };

  onClickConfirm = () => {
    this.props.confirmFlashMessage();
  };

  onClickClose = () => {
    this.props.removeFlashMessage();
  };

  render() {
    const { message, type } = this.props;
    let title = <FormattedMessage {...messages.notice} />;
    if (type === TYPE_ERROR) {
      title = <FormattedMessage {...messages.error} />;
    } else if (type === TYPE_OK) {
      title = <FormattedMessage {...messages.success} />;
    } else if (type === TYPE_CONFIRM) {
      title = <FormattedMessage {...messages.confirmation} />;
    }
    return (
      <div
        className={cx(
          s.root,
          type === TYPE_OK && s.success,
          type === TYPE_ERROR && s.error,
          (type === TYPE_NOTICE || type === TYPE_CONFIRM) && s.notice,
        )}
      >
        <div className={s.body}>
          <h2>{title}</h2>
          <div className={s.content}>{message}</div>
          {type === TYPE_CONFIRM && (
            <Button
              className={s.button}
              onClick={this.onClickConfirm}
              label={this.context.intl.formatMessage(messages.confirmButton)}
            />
          )}
          <Button
            className={cx(s.button, s.btnClose)}
            onClick={this.onClickClose}
            label={this.context.intl.formatMessage(messages.closeButton)}
          />
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  confirmFlashMessage,
  removeFlashMessage,
};

export default connect(
  null,
  mapDispatch,
)(withStyles(s)(FlashMessage));
