/* eslint-disable no-console */
/* eslint-disable camelcase */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, intlShape, FormattedMessage } from 'react-intl';
import cx from 'classnames';
import moment from 'moment';

import HotelType from '../../data/types/HotelType';
import CurrencyDisplay from '../CurrencyDisplay';
import Button from '../Button';
import Link from '../Link';
import ImageGallery from '../ImageGallery';

import s from './HotelCard.css';
import wifi from './wifi.webp';
import wifiAdd2x from './wifi@2x.webp';
import parking from './parking.webp';
import parkingAdd2x from './parking@2x.webp';
import water from './bottle-of-water.webp';
import waterAdd2x from './bottle-of-water@2x.webp';
import free from './free.webp';
import freeAdd2x from './free@2x.webp';

import hairDryer from './hair-dryer.svg';
import hairBrush from './hair-brush.svg';
import shavingKit from './shaving-kit.svg';
import googleChromecast from './google-chromecast.svg';

const messages = defineMessages({
  noRoomAvailable: {
    id: 'hotel.noRoomAvailable',
    defaultMessage: '##No rooms available##',
    description: 'Label when there is no room available in the hotel.',
  },
  bookNow: {
    id: 'city_hotel_listing.book_now',
    defaultMessage: '##Book Now##',
    description: 'Book now button in Hotel CheckInCard',
  },
  reviewCount: {
    id: 'hotel.review_count',
    defaultMessage:
      '{count, plural, zero {# reviews} one {1 review} other {# reviews}}',
    description: 'Ignore this',
  },
  review: {
    id: 'hotel.review',
    defaultMessage: '##Review##',
    description: 'Singular review',
  },
  reviews: {
    id: 'hotel.reviews',
    defaultMessage: '##Reviews##',
    description: 'Plural review',
  },
  availableFrom: {
    id: 'booking.availableFrom',
    defaultMessage: '##Available From##',
    description:
      'Text to show only when new hotel is opening and not available yet',
  },
});

const tripAdvisorUrl =
  'https://www.tripadvisor.com/img/cdsi/img2/ratings/traveler/s';
const tripAdvisorImage = '-50271-5.svg';

const featureImages = {
  wifi: {
    normal: wifi,
    add2x: wifiAdd2x,
  },
  parking: {
    normal: parking,
    add2x: parkingAdd2x,
  },
  'bottle-of-water': {
    normal: water,
    add2x: waterAdd2x,
  },
  'hair-dryer': {
    normal: hairDryer,
    add2x: hairDryer,
  },
  'hair-brush': {
    normal: hairBrush,
    add2x: hairBrush,
  },
  'shaving-kit': {
    normal: shavingKit,
    add2x: shavingKit,
  },
  'google-chromecast': {
    normal: googleChromecast,
    add2x: googleChromecast,
  },
};

class HotelCard extends React.Component {
  onMouseOver = () => {
    if (this.props.onMouseOver) {
      this.props.onMouseOver(this.props.hotel);
    }
  };

  onMouseOut = () => {
    if (this.props.onMouseOut) {
      this.props.onMouseOut();
    }
  };

  onClickBookNow = () => {
    this.props.onClickBookNow(this.props.hotel);
  };

  onClickHotelName = () => {
    this.props.onClickHotelName(this.props.hotel);
  };

  render() {
    const {
      adults,
      children,
      numberOfRooms,
      code,
      hotel: {
        slug_name,
        images,
        name,
        start_price_remembered,
        opening_date,
        check_in,
        check_out,
        currency,
        rooms,
        features,
        user_contents,
        location: { display_name },
        opening_soon,
      },
      showCountry,
    } = this.props;
    let { checkIn, checkOut } = this.props;
    // const price = start_price_remembered;
    let price = start_price_remembered;
    let hasAvailableRoom = false;
    const specialRateDescription =
      (rooms.length > 0 &&
        rooms[0].rate &&
        rooms[0].rate.special_rate_description) ||
      '';
    if (rooms.length > 0) {
      price = rooms[0].rate.available_rates[0].selling_rate_member.price_no_tax;
      rooms.some(room => {
        if (room.available > 0) {
          hasAvailableRoom = true;
          return true;
        }
        return false;
      });
    }

    let noticeMessage = '';
    if (opening_soon) {
      noticeMessage = (
        <span>
          <h3 className={s.openingSoonFrom}>OPENING SOON!</h3>
        </span>
      );
    } else if (
      opening_date &&
      moment.parseZone(opening_date).isSameOrAfter(moment(checkIn))
    ) {
      noticeMessage = (
        <span>
          <h3 className={s.availableFrom}>
            <FormattedMessage {...messages.availableFrom} />
            <br />
            <b>{moment.parseZone(opening_date).format('D MMM YYYY')}</b>
          </h3>
        </span>
      );
    } else if (!hasAvailableRoom) {
      noticeMessage = (
        <h3 className={s.noRoomAvailable}>
          <FormattedMessage {...messages.noRoomAvailable} />
        </h3>
      );
    }
    let country = '';
    if (showCountry && display_name !== null) {
      const parts = display_name.split('-');
      country = parts[0].trim();
    }
    if (!checkIn && !checkOut) {
      checkIn = moment(check_in);
      checkOut = moment(check_out);
    }

    let hotelLink = `/hotel/${slug_name}?check_in=${checkIn.format(
      'YYYY-MM-DD',
    )}&check_out=${checkOut.format(
      'YYYY-MM-DD',
    )}&adults=${adults}&children=${children}&rooms=${numberOfRooms}`;
    hotelLink += `&code=${code}`;

    return (
      <div
        className={cx(
          s.root,
          showCountry && s.showCountry,
          s[this.context.intl.locale],
        )}
        onMouseOver={this.onMouseOver}
        onFocus={this.onMouseOver}
        onMouseOut={this.onMouseOut}
        onBlur={this.onMouseOut}
      >
        <Link to={hotelLink}>
          <ImageGallery
            autoRotation={false}
            images={images}
            onClick={this.onClickHotelName}
            leftButtonClassName={s.galleryButton}
            rightButtonClassName={s.galleryButton}
          />
          {specialRateDescription && (
            <div className={s.specialRate}>{specialRateDescription}</div>
          )}
        </Link>
        <div className={s.content}>
          <div className={s.row}>
            <Link
              to={hotelLink}
              onClick={this.onClickHotelName}
              className={cx(s.hotelName, s[this.context.intl.locale])}
            >
              {name}
            </Link>
            {showCountry && country !== '' && (
              <h3 className={s.country}>{country}</h3>
            )}
          </div>
          {!opening_soon && (
            <div className={cx(s.row, s.ratingGroup)}>
              {user_contents && (
                <div className={s.review}>
                  <div className={s.rating}>
                    <img
                      src={`${tripAdvisorUrl}${user_contents.info.rating.toPrecision(
                        2,
                      )}${tripAdvisorImage}`}
                      alt="TripAdvisor rating"
                    />
                  </div>
                  <div className={s.info}>
                    <FormattedMessage
                      {...messages.reviewCount}
                      values={{
                        count: user_contents.info.total_reviews,
                        review: <FormattedMessage {...messages.review} />,
                        reviews: <FormattedMessage {...messages.reviews} />,
                      }}
                    />
                  </div>
                </div>
              )}
              <div className={s.features}>
                {features.map(feature => (
                  <span key={feature.code}>
                    <img
                      src={featureImages[feature.code].normal}
                      srcSet={`${featureImages[feature.code].add2x} 2x`}
                      alt={feature.code}
                    />
                  </span>
                ))}
                <span>
                  <img src={free} srcSet={`${freeAdd2x} 2x`} alt="free" />
                </span>
              </div>
            </div>
          )}
          <div
            className={
              opening_soon
                ? cx(s.row, s.openingSoon)
                : cx(s.row, s.bookNowGroup)
            }
          >
            {!noticeMessage && hasAvailableRoom && !opening_soon ? (
              <span>
                <h3 className={s.hotelPrice}>
                  <CurrencyDisplay value={price} currency={currency} />
                </h3>
                <Button
                  className={s.bookNowButton}
                  onClick={this.onClickBookNow}
                  label={this.context.intl.formatMessage(messages.bookNow)}
                />
              </span>
            ) : (
              noticeMessage
            )}
          </div>
        </div>
      </div>
    );
  }
}

HotelCard.propTypes = {
  hotel: HotelType.shape().isRequired,
  adults: PropTypes.number,
  children: PropTypes.number,
  numberOfRooms: PropTypes.number,
  code: PropTypes.string,
  checkIn: PropTypes.instanceOf(moment),
  checkOut: PropTypes.instanceOf(moment),
  showCountry: PropTypes.bool,
  onMouseOver: PropTypes.func,
  onMouseOut: PropTypes.func,
  onClickBookNow: PropTypes.func,
  onClickHotelName: PropTypes.func,
};

HotelCard.contextTypes = {
  intl: intlShape,
};

HotelCard.defaultProps = {
  showCountry: false,
  adults: 2,
  children: 0,
  numberOfRooms: 1,
  code: '',
  checkIn: moment(),
  checkOut: moment().add(1, 'd'),
  onMouseOver: () => {},
  onMouseOut: () => {},
  onClickBookNow: () => {},
  onClickHotelName: () => {},
};

export default withStyles(s)(HotelCard);
