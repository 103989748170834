/**
 * Created by methee on 9/5/17.
 */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { compose, graphql } from 'react-apollo';
import cx from 'classnames';
import moment from 'moment';

import CountryType from '../../data/types/CountryType';
import SignUpFormComponent from '../SignUpForm';
import { signUp, UserShape, openSignInModal } from '../../actions/user';
import FacebookButton from '../../components/FacebookButton';
import Button from '../../components/Button';

import s from './SignUpForm.css';
import query from './signUp.graphql';

const messages = defineMessages({
  signUp: {
    id: 'signup.sign_up',
    defaultMessage: '##Sign Up!##',
    description: 'Red Planet Hotel part on header of Sign up page',
  },
  formHeader: {
    id: 'signup.personal_details',
    defaultMessage: '##{personal} details##',
    description: 'The header above sign in form',
  },
  personal: {
    id: 'signup.personal_details.personal',
    defaultMessage: '##Personal##',
    description: '{personal} part of {personal} details',
  },
  signUpButton: {
    id: 'signup.sign_up_button',
    defaultMessage: '##Sign Up##',
    description: 'Sign Up button',
  },
  signupCTA: {
    id: 'signup.already_a_member',
    defaultMessage: '##Already a member? {signIn}##',
    description:
      'Call to action for sign in the bottom of Sign up page. Do not remove or translate the {signIn} part',
  },
  signIn: {
    id: 'signup.already_a_member_log_in',
    defaultMessage: '##Sign In##',
    description: '{signIn} part of the Sign In call to action',
  },
});

class SignUpForm extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      loading: PropTypes.bool,
      countries: PropTypes.arrayOf(CountryType.shape()),
    }).isRequired,
    user: UserShape,
    signUp: PropTypes.func,
    openSignInModal: PropTypes.func,
  };

  static defaultProps = {
    user: null,
    signUp: () => {},
    openSignInModal: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = { submitOnce: false };
  }

  onFormUpdated = profile => {
    this.profile = profile;
  };

  onSubmit = event => {
    this.props.signUp(this.profile);
    this.setState({ submitOnce: true });
    if (event) {
      event.preventDefault();
    }
  };

  onClickSignIn = () => {
    this.props.openSignInModal();
  };

  profile = {
    nameTitle: 0,
    firstName: '',
    lastName: '',
    birthday: moment(),
    country: '',
    phoneCountry: '',
    email: '',
    password: '',
  };

  render() {
    const {
      data: { loading },
      user: { errors },
    } = this.props;
    let {
      data: { countries },
    } = this.props;
    if (loading || !countries) {
      countries = [];
    }
    const { submitOnce } = this.state;
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <div className={s.container}>
          <h3>
            <FormattedMessage
              {...messages.formHeader}
              values={{
                personal: (
                  <span className={s.highlight}>
                    <FormattedMessage {...messages.personal} />
                  </span>
                ),
              }}
            />
          </h3>
          <div className={s.buttonGroup}>
            <FacebookButton className={s.fbButton} />
          </div>
          <SignUpFormComponent
            onSubmit={this.onSubmit}
            countries={countries}
            onFormUpdated={this.onFormUpdated}
            showPassword
            errors={submitOnce ? errors : []}
            compact
          />
        </div>
        <div className={s.buttonGroup}>
          <Button
            className={s.signUpButton}
            onClick={this.onSubmit}
            label={this.context.intl.formatMessage(messages.signUp)}
          />
        </div>
        <div className={s.joinNow}>
          <FormattedMessage
            {...messages.signupCTA}
            values={{
              signIn: (
                <button onClick={this.onClickSignIn}>
                  <FormattedMessage {...messages.signIn} />
                </button>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.user,
});

const mapDispatch = {
  signUp,
  openSignInModal,
};

export default compose(
  connect(
    mapState,
    mapDispatch,
  ),
  withStyles(s),
  graphql(query),
)(SignUpForm);
