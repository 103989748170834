/**
 * Created by methee on 8/21/17.
 */
import {
  GraphQLObjectType as ObjectType,
  GraphQLString as StringType,
  GraphQLInt as IntType,
  GraphQLNonNull as NonNull,
  GraphQLList as List,
} from 'graphql';

import PropTypes from 'prop-types';

import RateType from './RateType';

const RoomType = new ObjectType({
  name: 'Room',
  fields: {
    id: { type: new NonNull(IntType) },
    name: { type: new NonNull(StringType) },
    code: { type: new NonNull(StringType) },
    description: { type: new NonNull(StringType) },
    images: { type: new List(new NonNull(StringType)) },
    available: { type: new NonNull(IntType) },
    limit_capacity: { type: new NonNull(IntType) },
    guest_combination: {
      type: new List(
        new ObjectType({
          name: 'GuestCombination',
          fields: {
            adult: { type: new NonNull(IntType) },
            child: { type: new List(IntType) },
          },
        }),
      ),
    },
    features: {
      type: new List(
        new ObjectType({
          name: 'RoomFeature',
          fields: {
            id: { type: new NonNull(IntType) },
            code: { type: new NonNull(StringType) },
            name: { type: new NonNull(StringType) },
          },
        }),
      ),
    },
    rate: {
      type: new ObjectType({
        name: 'RoomRate',
        fields: {
          id: { type: new NonNull(IntType) },
          name: { type: new NonNull(StringType) },
          code: { type: new NonNull(StringType) },
          special_rate_description: { type: StringType },
          available_rates: {
            type: new List(
              new ObjectType({
                name: 'AvailableRate',
                fields: {
                  limit_guest: { type: new NonNull(IntType) },
                  selling_rate_non_member: { type: new NonNull(RateType) },
                  selling_rate_member: { type: new NonNull(RateType) },
                  selling_rate_non_member_pay_at_hotel: {
                    type: new NonNull(RateType),
                  },
                  selling_rate_member_pay_at_hotel: {
                    type: new NonNull(RateType),
                  },
                },
              }),
            ),
          },
        },
      }),
    },
  },
});

RoomType.shape = () =>
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    code: PropTypes.string,
    description: PropTypes.string,
    images: PropTypes.arrayOf(PropTypes.string),
    available: PropTypes.number,
    limit_capacity: PropTypes.number,
    guest_combination: PropTypes.arrayOf(
      PropTypes.shape({
        adult: PropTypes.number,
        child: PropTypes.arrayOf(PropTypes.number),
      }),
    ),
    rate: PropTypes.shape({
      id: PropTypes.number,
      code: PropTypes.string,
      special_rate_description: PropTypes.string,
      available_rates: PropTypes.arrayOf(
        PropTypes.shape({
          limit_guest: PropTypes.number,
          selling_rate_non_member: RateType.shape(),
          selling_rate_member: RateType.shape(),
          selling_rate_non_member_pay_at_hotel: RateType.shape(),
          selling_rate_member_pay_at_hotel: RateType.shape(),
        }),
      ),
    }),
  });

export default RoomType;
