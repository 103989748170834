// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.SignUpForm-root {\n  position: relative;\n}\n\n.SignUpForm-root .SignUpForm-label {\n  font-family: 'Lemon Sans', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n  font-size: 24px;\n  line-height: 27px;\n  padding-left: 16px;\n  margin-bottom: 6px;\n}\n\n.SignUpForm-root.SignUpForm-compact .SignUpForm-label {\n  font-family: 'Lemon Sans', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n  font-size: 24px;\n  line-height: 27px;\n  margin-bottom: 6px;\n}\n\n.SignUpForm-root .SignUpForm-label.SignUpForm-error {\n  color: #da291c;\n}\n\n.SignUpForm-formGroup {\n  margin: 0 -15px 21px;\n}\n\n.SignUpForm-root.SignUpForm-compact .SignUpForm-formGroup {\n  margin: 0 -15px 15px;\n}\n\n.SignUpForm-formGroup::after {\n  content: ' ';\n  display: table;\n  clear: both;\n}\n\n.SignUpForm-formGroup .SignUpForm-col6 {\n  float: left;\n  width: 50%;\n  padding: 0 15px;\n}\n\n.SignUpForm-formGroup .SignUpForm-col12 {\n  float: left;\n  width: 100%;\n  padding: 0 15px;\n}\n\n.SignUpForm-root select,\n.SignUpForm-root input {\n  font-weight: 300 !important;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "SignUpForm-root",
	"label": "SignUpForm-label",
	"compact": "SignUpForm-compact",
	"error": "SignUpForm-error",
	"formGroup": "SignUpForm-formGroup",
	"col6": "SignUpForm-col6",
	"col12": "SignUpForm-col12"
};
module.exports = exports;
