/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';

import Button from '../Button/Button';
import UserLoginType from '../../data/types/UserLoginType';

import {
  closeDeleteAccountModal,
  deleteAccount,
  openDeleteAccountModal,
} from '../../actions/user';

import s from './DeleteAccountForm.css';

const messages = defineMessages({
  formHeader: {
    id: 'deleteAccount.do_you_want_to_delete_your_account',
    defaultMessage: '##Do You Want to Delete Your Account##',
    description:
      'The header above sign in form. Do not translate or remove {red} part',
  },
  confirm: {
    id: 'deleteAccount.confirm',
    defaultMessage: '##Confirm##',
    description:
      'The header above sign in form. Do not translate or remove {red} part',
  },
  cancel: {
    id: 'deleteAccount.cancel',
    defaultMessage: '##Cancel##',
    description:
      'The header above sign in form. Do not translate or remove {red} part',
  },
});

const isError = (errors, field) =>
  errors.length > 0 && errors.indexOf(field) > -1;
class DeleteAccountForm extends React.Component {
  static propTypes = {
    user: UserLoginType.shape(),
    deleteAccount: PropTypes.func,
    penDeleteAccountModal: PropTypes.func,
    closeDeleteAccountModal: PropTypes.func,
  };

  static defaultProps = {
    user: {
      errors: [],
      errorMessage: '',
    },
    deleteAccount: () => {},
    openDeleteAccountModal: () => {},
    closeDeleteAccountModal: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitOnce: false,
    };
  }

  onSubmit = event => {
    this.props.deleteAccount(
      this.email,
      this.context.intl.formatMessage(messages.error),
    );
    this.setState({ submitOnce: true });
    if (event) {
      event.preventDefault();
    }
    return true;
  };

  email = '';

  render() {
    const {
      user: { user, errorMessage, errors },
    } = this.props;
    const { submitOnce } = this.state;
    // console.log("member id: ", user.member_id)

    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <div className={s.container}>
          <h3>
            <FormattedMessage {...messages.formHeader} />
          </h3>
          <div className={s.row}>
            <div className={s.buttonGroup}>
              <Button
                className={s.cancelButton}
                onClick={this.props.closeDeleteAccountModal} // change to cancel action
                label={this.context.intl.formatMessage(messages.cancel)}
              />
              <Button
                className={s.deleteButton}
                onClick={this.props.deleteAccount}
                label={this.context.intl.formatMessage(messages.confirm)}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.user,
});

const mapDispatch = {
  deleteAccount,
  openDeleteAccountModal,
  closeDeleteAccountModal,
};

export default connect(mapState, mapDispatch)(withStyles(s)(DeleteAccountForm));
