/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';

import s from './Header.css';
import Link from '../Link';
import Navigation from '../Navigation';

const path = 'https://cdn.redplanethotels.com/web3/static/';
const logoUrlWebP = `${path}redplanet-logo.webp`;
const logoUrlPng = `${path}redplanet-logo.png`;
// const logoUrl2x = `${path}redplanet-logo@2x.png`;

const logoUrlMobile = `${path}redplanet-logo-mobile.webp`;
const logoUrlMobilePng = `${path}redplanet-logo-mobile.png`;
// const logoUrlMobile2x = `${path}redplanet-logo-mobile@2x.webp`;

class Header extends React.Component {
  static propTypes = {
    showBookingEngine: PropTypes.bool,
  };

  static defaultProps = {
    showBookingEngine: false,
  };

  render() {
    const { showBookingEngine } = this.props;
    return (
      <div className={cx(s.root, showBookingEngine && s.showingBookingEngine)}>
        <div className={s.container}>
          <Navigation />
          <Link className={s.brand} to="/">
            {/* <img
              className={s.desktop}
              src={logoUrl}
              srcSet={`${logoUrl2x} 2x`}
              alt="Red Planet Hotels"
              width="201px"
              height="79px"
            />
            <img
              className={s.mobile}
              src={logoUrlMobile}
              srcSet={`${logoUrlMobile2x} 2x`}
              alt="Red Planet Hotels"
            /> */}
            {/* implemented picture to incomporate both png and webp without to much code change */}
            <picture className={s.desktop}>
              <source srcSet={logoUrlPng} type="image/png" />
              <source srcSet={logoUrlWebP} type="image/webp" />
              <img
                srcSet={`${logoUrlWebP} 2x`}
                type="image/webp"
                alt="Red Planet Hotels"
              />
            </picture>
            <picture className={s.mobile}>
              <source srcSet={logoUrlMobilePng} type="image/png" />
              <source srcSet={logoUrlMobile} type="image/webp" />
              <img
                srcSet={`${logoUrlMobile} 2x`}
                type="image/webp"
                alt="Red Planet Hotels"
              />
            </picture>
          </Link>
        </div>
      </div>
    );
  }
}

const mapProps = state => ({
  showBookingEngine: state.runtime.showBookingEngine,
});

export default connect(mapProps)(withStyles(s)(Header));
