/* eslint-disable react/no-unused-state */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */

import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import s from './Navigation.css';
import { UserShape, signOut, openSignInModal } from '../../actions/user';

import Link from '../Link';
import MyAccountButton from '../MyAccountButton';
import LanguageSwitcher from '../LanguageSwitcher';
import HotelPromotionButton from '../HotelPromotionButton/HotelPromotionButton';

const messages = defineMessages({
  hotels: {
    id: 'header.our_hotels',
    defaultMessage: '##Our Hotels##',
    description: 'Our Hotels link in header',
  },
  loyalty: {
    id: 'header.loyalty',
    defaultMessage: '##Loyalty##',
    description: 'Blog link in header',
  },
  technology: {
    id: 'header.technology',
    defaultMessage: '##Technology##',
    description: 'Technology link in header',
  },
  promotion: {
    id: 'header.promotion',
    defaultMessage: '##Promotion##',
    description: 'Promotion link in header',
  },
  mybookings: {
    id: 'header.my_bookings',
    defaultMessage: '##My Bookings##',
    description: 'My Bookings link in header',
  },
  aboutUs: {
    id: 'header.tech_savvy',
    defaultMessage: '##Tech Savvy##',
    description: 'Tech link in header',
  },
  contact: {
    id: 'header.contact_us',
    defaultMessage: '##Contact Us##',
    description: 'Contact Us link in header',
  },
  redApp: {
    id: 'header.red_app',
    defaultMessage: '##RED APP##',
    description: 'Red App menu',
  },
  redAppLink: {
    id: 'header.red_app_link',
    defaultMessage: 'https://info.redplanethotels.com/app',
    description: 'Red App Link',
  },
  blog: {
    id: 'header.blog',
    defaultMessage: '##Blog##',
    description: 'Blog link in header',
  },
  signin: {
    id: 'header.sign_in',
    defaultMessage: '##Sign In##',
    description: 'Sign In link in header',
  },
  signout: {
    id: 'header.sign_out',
    defaultMessage: '##Sign Out##',
    description: 'Sign Out link in header',
  },
  myaccount: {
    id: 'header.my_account',
    defaultMessage: '##My Account##',
    description: 'My Account link in header',
  },
  hiUser: {
    id: 'navigation.hiUser',
    defaultMessage: '##Hi {userName}##',
    description:
      'Label displayed when user already logged in. Do not remove or translate tre {userName} path',
  },
});

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      mouseOver: false,
    };
  }

  onMouseOver = () => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: false, selected: false });
  };

  onClick = () => {
    this.setState({ selected: false });
  };

  render() {
    const { user } = this.props;
    const { selected } = this.state;
    return (
      <div className={s.root} role="navigation">
        <div className={s.navbar}>
          <div className={s.myAccButton}>
            <HotelPromotionButton />
          </div>
          <Link className={s.link} to="/hotels">
            <FormattedMessage {...messages.hotels} />
          </Link>
          <Link className={s.link} to="/loyalty">
            <FormattedMessage {...messages.loyalty} />
          </Link>
          <Link className={s.link} to="/vouchers">
            <FormattedMessage {...messages.promotion} />
          </Link>
          <Link className={s.link} to="/mybookings">
            <FormattedMessage {...messages.mybookings} />
          </Link>
          <Link className={s.link} to="/red-app">
            <FormattedMessage {...messages.redApp} />
          </Link>
          {/* <Link className={s.link} to="/blog"> */}
          {/*  <FormattedMessage {...messages.blog} /> */}
          {/* </Link> */}
          {/* <Link className={s.link} to="/contact"> */}
          {/*  <FormattedMessage {...messages.contact} /> */}
          {/* </Link> */}
          {/* <a
            className={s.link}
            href={this.context.intl.formatMessage(messages.redAppLink)}
            hrefLang={this.context.intl.locale}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage {...messages.redApp} />
          </a> */}
          <div className={s.myAccButton}>
            <MyAccountButton />
          </div>
          <div className={s.langSwitch}>
            <LanguageSwitcher />
          </div>
        </div>
        <div className={s.langSwitchMobile}>
          <LanguageSwitcher />
        </div>
        <div onMouseOver={this.onMouseOver} onMouseLeave={this.onMouseLeave}>
          <button
            type="button"
            id="mobilehamBurgerButton"
            aria-label="hamBurgerButton"
            className={s.hamBurgerButton}
            onClick={() => {
              this.setState({ selected: !selected });
            }}
          >
            <span className={s.bar} />
            <span className={s.bar} />
            <span className={s.bar} />
          </button>
          {selected ? (
            <div className={s.dropdown}>
              <ul>
                <li>
                  <Link onClick={this.onClick} className={s.link} to="/hotels">
                    <FormattedMessage {...messages.hotels} />
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={this.onClick}
                    className={s.link}
                    to="/vouchers"
                  >
                    <FormattedMessage {...messages.promotion} />
                  </Link>
                </li>
                <li>
                  <Link
                    className={s.link}
                    onClick={this.onClick}
                    to="/mybookings"
                  >
                    <FormattedMessage {...messages.mybookings} />
                  </Link>
                </li>
                <li>
                  <Link className={s.link} onClick={this.onClick} to="/blog">
                    <FormattedMessage {...messages.blog} />
                  </Link>
                </li>
                <li>
                  <Link className={s.link} to="/red-app">
                    <FormattedMessage {...messages.redApp} />
                  </Link>
                  {/* <a
                    className={s.link}
                    href={this.context.intl.formatMessage(messages.redAppLink)}
                    hrefLang={this.context.intl.locale}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage {...messages.redApp} />
                  </a> */}
                </li>
                {!user.isLoggedIn ? (
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({ selected: false });
                        this.props.openSignInModal();
                      }}
                    >
                      <FormattedMessage {...messages.signin} />
                    </button>
                  </li>
                ) : (
                  <li>
                    <Link
                      onClick={this.onClick}
                      className={s.link}
                      to="/account"
                    >
                      <FormattedMessage
                        {...messages.hiUser}
                        values={{
                          userName: user.user.first_name,
                        }}
                      />
                    </Link>
                  </li>
                )}
                {user.isLoggedIn && (
                  <li>
                    <button
                      type="button"
                      onClick={() => {
                        this.setState({ selected: false });
                        this.props.signOut();
                      }}
                    >
                      <FormattedMessage {...messages.signout} />
                    </button>
                  </li>
                )}
              </ul>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
    );
  }
}

Navigation.propTypes = {
  user: UserShape,
  signOut: PropTypes.func,
  openSignInModal: PropTypes.func,
};

Navigation.contextTypes = {
  intl: intlShape,
};

Navigation.defaultProps = {
  user: null,
  signOut: () => {},
  openSignInModal: () => {},
};

const mapState = state => ({
  user: state.user,
});

const mapDispatch = {
  signOut,
  openSignInModal,
};

export default connect(mapState, mapDispatch)(withStyles(s)(Navigation));
