// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.SundryItem-root {\n  position: relative;\n  text-align: center;\n}\n\n.SundryItem-root img {\n  width: 100%;\n}\n\n.SundryItem-name {\n  height: 44px;\n  text-transform: uppercase;\n  text-align: center;\n}\n\n.SundryItem-price {\n  color: #da291c;\n}\n\n.SundryItem-selectButton {\n  padding: 3px 10px;\n  -webkit-appearance: none;\n  background: #ffe000;\n  border: none;\n  border-radius: 5px;\n  text-transform: uppercase;\n  color: #000;\n  -webkit-box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);\n          box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);\n  font-weight: bold;\n  font-family: 'Lemon Sans', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n}\n\n.SundryItem-root.SundryItem-id .SundryItem-selectButton,\n.SundryItem-root.SundryItem-ja .SundryItem-selectButton,\n.SundryItem-root.SundryItem-ko .SundryItem-selectButton,\n.SundryItem-root.SundryItem-zh-hans .SundryItem-selectButton,\n.SundryItem-root.SundryItem-zh-hant .SundryItem-selectButton,\n.SundryItem-root.SundryItem-th .SundryItem-selectButton {\n  line-height: normal;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "SundryItem-root",
	"name": "SundryItem-name",
	"price": "SundryItem-price",
	"selectButton": "SundryItem-selectButton",
	"id": "SundryItem-id",
	"ja": "SundryItem-ja",
	"ko": "SundryItem-ko",
	"zh-hans": "SundryItem-zh-hans",
	"zh-hant": "SundryItem-zh-hant",
	"th": "SundryItem-th"
};
module.exports = exports;
