/**
 * Created by methee on 8/21/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './ImageGallery.css';

import iconLeft from './icon-arrow-left.webp';
import iconLeftAdd2x from './icon-arrow-left@2x.webp';
import iconRight from './icon-arrow-right.webp';
import iconRightAdd2x from './icon-arrow-right@2x.webp';

const imageRotationTime = 6000;

class HotelGallery extends React.Component {
  static propTypes = {
    images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    className: PropTypes.string,
    leftButtonClassName: PropTypes.string,
    rightButtonClassName: PropTypes.string,
    onClick: PropTypes.func,
    onClickGalleryControl: PropTypes.func,
    autoRotation: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    leftButtonClassName: '',
    rightButtonClassName: '',
    onClick: () => {},
    onClickGalleryControl: () => {},
    autoRotation: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedImage: props.images[this.imageIndex],
    };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      this.timeoutPromise = setTimeout(
        this.autoImageRotation,
        imageRotationTime,
      );
    }
  }

  componentWillUnmount() {
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
  }

  onClickGalleryControl(direction) {
    this.props.onClickGalleryControl(direction);
    this.controlClicked = true;
    this.imageIndex += direction;
    if (this.imageIndex < 0) {
      this.imageIndex = this.props.images.length - 1;
    }
    if (this.imageIndex >= this.props.images.length) {
      this.imageIndex = 0;
    }
    this.setState({
      selectedImage: this.props.images[this.imageIndex],
    });
  }

  onClick = () => {
    this.props.onClick();
  };

  autoImageRotation = () => {
    if (!this.state.controlClicked && this.props.autoRotation) {
      const { images } = this.props;
      this.nextIndex = this.imageIndex + 1;
      if (this.nextIndex >= images.length) {
        this.nextIndex = 0;
      }
      this.setState({ selectedImage: images[this.nextIndex] });
      this.timeoutPromise = setTimeout(
        this.autoImageRotation,
        imageRotationTime,
      );
    }
  };

  imageIndex = 0;

  controlClicked = false;

  render() {
    const { selectedImage } = this.state;
    const {
      className,
      leftButtonClassName,
      rightButtonClassName,
      images,
    } = this.props;
    return (
      <div className={cx(s.root, className)}>
        <button
          className={s.image}
          style={{ backgroundImage: `url('${selectedImage}')` }}
          onClick={this.onClick}
        />
        {images.length > 1 && (
          <div>
            <button
              className={cx(s.galleryControl, leftButtonClassName, s.left)}
              onClick={event => {
                event.preventDefault();
                this.onClickGalleryControl(-1);
              }}
            >
              <img src={iconLeft} srcSet={`${iconLeftAdd2x} 2x`} alt="left" />
            </button>
            <button
              className={cx(s.galleryControl, rightButtonClassName, s.right)}
              onClick={event => {
                event.preventDefault();
                this.onClickGalleryControl(+1);
              }}
            >
              <img
                src={iconRight}
                srcSet={`${iconRightAdd2x} 2x`}
                alt="right"
              />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default withStyles(s)(HotelGallery);
