/* eslint-disable css-modules/no-unused-class */

import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import CurrencyDisplay from '../CurrencyDisplay';
import NumberPicker from '../NumberPicker';
import SundryItemTooltip from './SundryItemTooltip';
import HotelType from '../../data/types/HotelType';
import SundryItemType from '../../data/types/SundryItemType';
import s from './SundryItem.css';

const maxMobileWidth = 992;

const messages = defineMessages({
  select: {
    id: 'booking.roomCard.select',
    defaultMessage: '##Select##',
    description: 'Select room button'
  }
});

class SundryItem extends React.Component {
  bindOnWindowResize = null;

  static propTypes = {
    hotel: HotelType.shape().isRequired,
    item: SundryItemType.shape().isRequired,
    onPickItem: PropTypes.func.isRequired,
    imageTooltipRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  };

  static contextTypes = {
    intl: intlShape
  };

  constructor(props) {
    super(props);

    this.state = {
      imageTooltipData: null,
      showImageTooltip: false,
      isMobileDevice: false
    };
  }

  onPickItem = number => {
    const { item } = this.props;
    item.numberOfItem = number;
    this.props.onPickItem(item);
  };

  onMouseEnter = (event, data) => {
    const { clientX, clientY } = event;
    const props = { ...data, clientX, clientY };

    this.setState(prevState => ({
      ...prevState,
      imageTooltipData: props,
      showImageTooltip: true
    }));
  };

  onMouseLeave = () => {
    this.setState(prevState => ({
      ...prevState,
      imageTooltipData: null,
      showImageTooltip: false
    }));
  };

  onMouseMove = (event, data) => {
    const { pageX, pageY } = event;

    const props = { ...data, clientX: pageX, clientY: pageY };

    this.setState(prevState => ({
      ...prevState,
      imageTooltipData: props,
      showImageTooltip: true
    }));
  };

  componentDidMount() {
    if (process.env.BROWSER) {
      this.onWindowResize();
      this.bindOnWindowResize = this.onWindowResize.bind(this);
      window.addEventListener('resize', this.bindOnWindowResize);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.bindOnWindowResize);
  }

  onWindowResize = () => {
    this.setState(prevState => ({
      ...prevState,
      isMobileDevice: window.innerWidth < maxMobileWidth
    }));
  };

  render() {
    const { hotel, item } = this.props;

    return (
      <div
        className={cx(s.root, s[this.context.intl.locale])}
        onMouseEnter={e => this.onMouseEnter(e, item)}
        onMouseLeave={this.onMouseLeave}
        onMouseMove={e => this.onMouseMove(e, item)}
      >
        <img src={item.item.image_thumb} alt={item.item.title} />
        {!this.state.isMobileDevice &&
          this.state.showImageTooltip &&
          createPortal(
            <SundryItemTooltip
              item={item.item}
              clientX={this.state.imageTooltipData.clientX}
              clientY={this.state.imageTooltipData.clientY}
            />,
            this.props.imageTooltipRef.current
          )}
        <div className={s.name}>{item.item.title}</div>
        <div>
          <CurrencyDisplay
            className={s.price}
            value={item.item.price}
            currency={hotel.currency}
          />
        </div>
        <div>
          {item.numberOfItem > 0 ? (
            <NumberPicker
              maxNumber={item.item.max_item_per_booking}
              minNumber={0}
              number={item.numberOfItem}
              onSelectedNumber={this.onPickItem}
            />
          ) : (
            <button
              className={s.selectButton}
              onClick={() => {
                this.onPickItem(1);
              }}
            >
              <FormattedMessage {...messages.select} />
            </button>
          )}
        </div>
      </div>
    );
  }
}

export default withStyles(s)(SundryItem);
