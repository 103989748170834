import React from 'react';
import PropTypes from 'prop-types';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';

import cx from 'classnames';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import s from './ErrorForm.css';
import { closeErrorModal } from '../../actions/user';

import Button from '../Button';

// const messages = defineMessages({
//   signUp: {
//     id: 'signup.sign_up',
//     defaultMessage: '##Sign Up!##',
//     description: 'Red Planet Hotel part on header of Sign up page',
//   },
//   formHeader: {
//     id: 'signup.personal_details',
//     defaultMessage: '##Warning##',
//     description: 'Warning',
//   },
//   personal: {
//     id: 'signup.personal_details.personal',
//     defaultMessage: '##Personal##',
//     description: '{personal} part of {personal} details',
//   },
// });
class ErrorForm extends React.Component {
  static propTypes = {
    closeErrorModal: PropTypes.func,
    closeErrorRemoveQuery: PropTypes.func,
  };

  static contextTypes = {
    intl: intlShape,
    closeErrorModal: () => {},
  };

  onCloseErrorModal = () => {
    this.props.closeErrorModal();
    this.props.closeErrorRemoveQuery();
  };

  render() {
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <div className={s.container}>
          <h4> Payment Rejected </h4>
          <h4>Apologies, please contact with your Bank provider.</h4>
        </div>
      </div>
    );
  }
}

const mapState = state => ({});

const mapDispatch = {
  closeErrorModal,
};

export default connect(mapState, mapDispatch)(withStyles(s)(ErrorForm));
