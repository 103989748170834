// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.BirthdayField-root {\n  position: relative;\n  width: 100%;\n}\n\n.BirthdayField-col4 {\n  float: left;\n  padding: 0 15px;\n  width: 33.333339%;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "BirthdayField-root",
	"col4": "BirthdayField-col4"
};
module.exports = exports;
