/* eslint-disable react/no-unused-state */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/static-property-placement */
/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import HotelType from '../../data/types/HotelType';

let L;
let Leaflet;
if (process.env.BROWSER) {
  L = require('leaflet');
  Leaflet = require('react-leaflet');
}

class LeafletMap extends React.Component {
  static propTypes = {
    hotels: PropTypes.arrayOf(HotelType.shape()).isRequired,
    hoveringHotel: HotelType.shape(),
    className: PropTypes.string,
    onClickBookNow: PropTypes.func,
    showHotelCardWhenHover: PropTypes.bool,
    disableMouse: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    hoveringHotel: null,
    onClickBookNow: () => {},
    showHotelCardWhenHover: true,
    disableMouse: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      hotels: props.hotels,
      selectingHotel: props.hoveringHotel,
      mapWidth: 300,
      mapHeight: 400,
      boundChanged: false,
      zoom: 12,
      center: { lat: -25.363, lng: 131.044 },
      bounds: null,
      // L: require('leaflet'),
      // Leaflet: require('react-leaflet'),
    };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      this.iconPerson = L.icon({
        iconUrl:
          'https://cdn.redplanethotels.com/web3/library/mapbox/red-pin-resize.png',
      });
    }
    const cssUrl =
      'https://cdn.redplanethotels.com/web3/public/leaflet/leaflet.css';
    this.addStyle(cssUrl);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      hotels: nextProps.hotels,
      selectingHotel: nextProps.hoveringHotel,
      bounds: null,
    });
  }

  componentDidUpdate() {
    // this.FlyTo();
  }

  addStyle = url => {
    const style = document.createElement('link');
    style.href = url;
    style.rel = 'stylesheet';
    style.async = true;

    document.head.appendChild(style);
  };

  FlyTo() {
    if (this.props.hoveringHotel !== null) {
      const latlng = this.props.hoveringHotel.coordinate.split(',');
      const lat = parseFloat(latlng[0], 10);
      const lng = parseFloat(latlng[1], 10);
      this.state.lat = lat;
      this.state.lng = lng;
      this.state.zoom = 14;
    }
  }

  render() {
    if (process.env.BROWSER) {
      const { hotels } = this.state;
      if (hotels && hotels.length) {
        const { Map, TileLayer, Marker, Popup } = Leaflet;

        const features = this.state.hotels.map(hotel => {
          const latlng = hotel.coordinate.split(',');
          const lat = parseFloat(latlng[0], 10);
          const lng = parseFloat(latlng[1], 10);
          const { name } = hotel;
          return { lat, lng, name };
        });

        const coord = this.state.hotels[0].coordinate.split(',');
        const lat1 = parseFloat(coord[0], 10);
        const lng1 = parseFloat(coord[1], 10);

        return (
          <div
            style={{
              maxWidth: '100%',
              maxHeight: '100%',
            }}
          >
            <Map
              style={{
                width: 615,
                height: 651,
              }}
              // zoom={13}
              zoom={this.state.zoom}
              // center={position}
              center={[lat1, lng1]}
              scrollWheelZoom
            >
              <TileLayer
                attribution='<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                url="https://{s}.tile.jawg.io/jawg-sunny/{z}/{x}/{y}{r}.png?access-token=mMeF0PJ9Xg3nDK5ha8qiXPHioIEmJ6j2htpxjzhISzpMda8rwwsm7lKs81dCaohg"
              />
              {features.map(c => (
                <Marker
                  key={c.name}
                  position={[c.lat, c.lng]}
                  icon={this.iconPerson}
                >
                  <Popup>{c.name}</Popup>
                </Marker>
              ))}
            </Map>
          </div>
        );
      }
    }
    return null;
  }
}

export default LeafletMap;
