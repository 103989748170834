/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable css-modules/no-unused-class, no-unused-vars */
import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';

// import mp from '../../analytic';
import history from '../../history';
import HotelType from '../../data/types/HotelType';
import {
  joinRemembered,
  includeBreakfast,
  includeSmoking,
  includePetFriendly,
  addSundryItem,
  BookingShape,
} from '../../actions/booking';
import { UserShape, openErrorModal } from '../../actions/user';

import BookingProgress, {
  BP_ROOM_SELECT,
} from '../../components/BookingProgress';
import BookingProgressHeader, {
  BH_ROOM_SELECT,
} from '../../components/BookingProgressHeader';
import CheckBox from '../../components/CheckBox';
import PriceSummary, { PS_ROOM_SELECT } from '../../components/PriceSummary';
import Button from '../../components/Button';

import CurrencyDisplay from '../../components/CurrencyDisplay';
import StickyPriceSummary from '../../components/StickyPriceSummary';
import SundrySales from '../../components/SundrySale';

import s from './RoomSelect.css';
import RoomCard from './RoomCard';

import iconSmoking from './icon-smoking.webp';
import iconSmokingAdd2x from './icon-smoking@2x.webp';
import iconPetFriendly from './icon-petfriendly.webp';
import iconPetFriendlyAdd2x from './icon-petfriendly@2x.webp';
import iconClock from './icon-clock.webp';
import iconClockAdd2x from './icon-clock@2x.webp';
import iconBreakfast from './icon-breakfast.webp';
import iconBreakfastAdd2x from './icon-breakfast@2x.webp';
import NPCLogo from './NPC_Logo.webp';

const messages = defineMessages({
  joinOurRememberedProgram: {
    id: 'select_room.join_loyalty_program_title',
    defaultMessage: '##Join Our Remembe{red} Program##',
    description:
      'Join our remembered program checkbox. Do not remove or translate {red} path. This is the placeholder for red-highlight text',
  },
  joinOurRememberedProgramRed: {
    id: 'select_room.join_loyalty_program_title.red',
    defaultMessage: '##red##',
    description: "'red' path of Join Our Remembe{red} Program",
  },
  joinAndReceive: {
    id: 'select_room.join_loyalty_program_detail',
    defaultMessage:
      'Join us and receive {tenPercentOff} all your bookings on our app and website',
    description:
      'Text under Join our remembered program checkbox. Do not remove or translate {tenPercentOff} path. This will be translated separately.',
  },
  tenPercentOff: {
    id: 'select_room.join_loyalty_program_detail.tenPercentOff',
    defaultMessage: '##10% off##',
    description:
      "'tenPercentOff' path of Join us and receive {tenPercentOff} all your bookings on our app and website",
  },
  includeBreakfast: {
    id: 'select_room.include_breakfast',
    defaultMessage: '##Include {breakfast} for {price} / person##',
    description:
      "Label for include breakfast checkbox. Do not remove ore translate the {breakfast} and {price} path. It is a placeholder for breakfast' name and price",
  },
  includeSmoking: {
    id: 'select_room.include_smoking',
    defaultMessage: '##Include Smoking room for {price} / night##',
    description: 'Label for include breakfast smoking.',
  },
  includePetFriendly: {
    id: 'select_room.include_petFriendly',
    defaultMessage: '##Include Pet Friendly room for {price} / night##',
    description: 'Label for include Pet Friendly.',
  },
  quickInfo: {
    id: 'select_room.information',
    defaultMessage: '##Information / {hotel}##',
    description:
      "Quick info title. Do not remove or translate {hotel} path. It is a placeholder for hotel's name",
  },
  noRoomAvailable: {
    id: 'select_room.noRoomAvailable',
    defaultMessage: '##No room available##',
    description: 'Label show when no room available for hotel',
  },
  breakfast: {
    id: 'select_room.breakfast',
    defaultMessage: '##Breakfast##',
    description: 'Breakfast title',
  },
  smoking: {
    id: 'select_room.smoking',
    defaultMessage: '##Smoking##',
    description: 'Smoking title',
  },
  petFriendly: {
    id: 'select_room.petFriendly',
    defaultMessage: '##Pet Friendly##',
    description: 'Pet Friendly title',
  },
  accessibilityHeader: {
    id: 'booking_confirm_page.accessibilityHeader',
    defaultMessage: '##Accessibility##',
    description: 'Header for accessibility',
  },
  GuaranteeCancellationPolicyHeader: {
    id: 'booking_confirm_page.GuaranteeCancellationPolicyHeader',
    defaultMessage: '##Guarantee and Cancelation Policy##',
    description: 'Header for Guarantee And Cancellation policies',
  },
  GuaranteeCancellationPolicyPayLater: {
    id: 'booking_confirm_page.GuaranteeCancellationPolicyPayLater',
    defaultMessage:
      '##Pay At Hotel: Pay in cash / card / e-wallet on check-in; Room guaranteed only until 4PM; No cancellation fee##',
    description:
      'Pay At Hotel: Pay in cash / card / e-wallet on check-in; Room guaranteed only until 4PM; No cancellation fee.',
  },
  GuaranteeCancellationPolicyPayNow: {
    id: 'booking_confirm_page.GuaranteeCancellationPolicyPayNow',
    defaultMessage:
      '##Pay Now: Enjoy lower rates; Room guaranteed; Non-cancellable##',
    description:
      'Pay Now: Enjoy lower rates; Room guaranteed; Non-cancellable.',
  },
  accessibility: {
    id: 'booking_confirm_page.accessibility',
    defaultMessage:
      'Our hotels and rooms offer amenities for guests with access needs, including wheelchair-friendly rooms, roll-in showers, and lowered switches and clothes rails. Please contact the hotel directly to book an accessible room or learn more about accessibility at Red Planet Hotels ',
    description: 'Accessibility',
  },
  accessibilityLink: {
    id: 'booking_confirm_page.accessibilityLink',
    defaultMessage: 'here',
    description: 'Accessibility Link',
  },
  accessibilityUrl: {
    id: 'booking_confirm_page.accessibilityUrl',
    defaultMessage: 'https://info.redplanethotels.com/en/accessibility',
    description: 'Accessibility URL',
  },
  accessibilityExtra: {
    id: 'booking_confirm_page.accessibilityExtra',
    defaultMessage: '.',
    description: 'Accessibility Extra',
  },
});

const rootPaddingTop = 30;
const maxMobileWidth = 992;

class RoomSelect extends React.Component {
  initialColRightPosition = {
    x: 0,
    y: 0,
  };

  bindWindowScroll = null;

  bindWindowResize = null;

  constructor(props) {
    super(props);

    this.imageTooltipRef = createRef();

    this.state = {
      fixedRightColumn: false,
      isMobile: false,
      showingFeatureForRoom: 0,
    };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      setTimeout(() => {
        this.bindWindowScroll = this.onWindowScroll.bind(this);
        this.bindWindowResize = this.onWindowResize.bind(this);
        window.addEventListener('scroll', this.bindWindowScroll);
        window.addEventListener('resize', this.bindWindowResize);
        this.setState({ isMobile: window.innerWidth < maxMobileWidth });
      }, 500);
    }

    const { user, query } = this.props;
    setTimeout(() => {
      this.initialColRightPosition.x =
        this.colRight.offsetLeft + this.container.offsetLeft;
      this.initialColRightPosition.y = this.root.offsetTop + rootPaddingTop;
    }, 300);
    // mp.track('#Select Room Type');
    const {
      checkIn,
      checkOut,
      hotel,
      adults,
      children,
      numberOfRooms,
    } = this.props.booking;
    // mp.browse(
    //   hotel,
    //   hotel.start_price_remembered,
    //   user,
    //   checkIn.format('YYYY-MM-DD'),
    //   checkOut.format('YYYY-MM-DD'),
    //   adults,
    //   children,
    //   numberOfRooms,
    //   this.context.intl.locale,
    // );
    if (query && query.error === 'PAYMENT_REJECTED') {
      this.props.openErrorModal();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindWindowScroll);
    window.removeEventListener('resize', this.bindWindowResize);
  }

  onWindowScroll = () => {
    this.setState({
      fixedRightColumn:
        window.scrollY >= this.initialColRightPosition.y &&
        window.innerHeight > this.colRight.offsetHeight,
    });
  };

  onWindowResize = () => {
    setTimeout(() => {
      this.initialColRightPosition.x =
        this.container.offsetLeft +
        this.container.offsetWidth -
        this.colRight.offsetWidth;

      this.setState({
        fixedRightColumn:
          window.scrollY >= this.initialColRightPosition.y &&
          window.innerHeight > this.colRight.offsetHeight,
        isMobile: window.innerWidth < maxMobileWidth,
      });
    }, 500);
  };

  onClickJoinRemembered = ({ value }) => {
    this.props.joinRemembered(value);
  };

  onClickIncludeBreakfast = ({ value }) => {
    this.props.includeBreakfast(value);
  };

  onClickIncludeSmoking = ({ value }) => {
    this.props.includeSmoking(value);
  };

  onClickIncludePetFriendly = ({ value }) => {
    this.props.includePetFriendly(value);
  };

  onClickContinueButton = () => {
    const { booking, user } = this.props;
    let selectedNumberOfRooms = 0;
    booking.roomTypes.forEach(roomType => {
      if (roomType.numberOfRooms > 0) {
        selectedNumberOfRooms += roomType.numberOfRooms;
      }
    });
    if (selectedNumberOfRooms === 0) {
      return;
    }
    // mp.addCart(booking, user, this.context.intl.locale);
    history.push({
      pathname: `/${this.context.intl.locale}/booking/guest-profile/`,
      search: `hotel=${booking.hotel.id}&check_in=${booking.checkIn.format(
        'YYYY-MM-DD',
      )}&check_out=${booking.checkOut.format('YYYY-MM-DD')}&rooms=${
        booking.numberOfRooms
      }&adults=${booking.adults}&children=${booking.children}${
        booking.includeBreakfast ? '&breakfast=1' : ''
      }${booking.includeSmoking ? '&smoking=1' : ''}${
        booking.includePetFriendly ? '&pet_friendly=1' : ''
      }
      ${booking.flashCode ? `&code=${booking.flashCode}` : ''}${
        booking.bookingId ? `&booking_id=${booking.bookingId}` : ''
      }${
        booking.bookingNumber ? `&confirmation=${booking.bookingNumber}` : ''
      }${booking.email ? `&email=${encodeURIComponent(booking.email)}` : ''}`,
    });
  };

  onClickShowFeature = roomId => {
    this.setState({ showingFeatureForRoom: roomId });
  };

  render() {
    const {
      hotelQuickInfo,
      user: { isLoggedIn },
      booking,
    } = this.props;
    if (isLoggedIn) {
      booking.joinRemembered = true;
    }
    if (!booking || !booking.hotel) {
      return <div />;
    }
    const { hotel, sundrySales } = booking;
    const { fixedRightColumn, isMobile, showingFeatureForRoom } = this.state;
    const {
      breakfast,
      smoking,
      pet_friendly,
      currency,
      rooms,
      code,
      name,
      policy_guarantee,
      country_code,
    } = hotel;
    const { pay_later_rules, pay_now_rules } = policy_guarantee;
    const quickInfoCode = code.toLowerCase();
    const guaranteecancellationList = [pay_later_rules, pay_now_rules];
    guaranteecancellationList.forEach((policy, index) => {
      guaranteecancellationList[index] = <li key={policy}>{policy}</li>;
    });
    // const cancellation = this.context.intl.formatMessage(
    //   messages.cancellationPolicy,
    // );
    const accessibilityURL = this.context.intl.formatMessage(
      messages.accessibilityUrl,
    );
    return (
      <div>
        <div
          ref={root => {
            this.root = root;
          }}
          className={cx(s.root, s[this.context.intl.locale])}
        >
          <div
            ref={container => {
              this.container = container;
            }}
            className={s.container}
          >
            <div
              ref={colLeft => {
                this.colLeft = colLeft;
              }}
              className={s.colLeft}
            >
              <BookingProgress currentProgress={BP_ROOM_SELECT} />
              <BookingProgressHeader currentProgress={BH_ROOM_SELECT} />
              {!isLoggedIn && !booking.bookingId && (
                <div className={s.joinRememberedBox}>
                  <div className={s.joinRememberedCheckbox}>
                    <CheckBox
                      className={s.checkboxRoomSelect}
                      name="joinRemembered"
                      isChecked={booking.joinRemembered}
                      onChange={this.onClickJoinRemembered}
                    />
                  </div>
                  <div className={s.joinRememberedCheckboxLabel}>
                    <FormattedMessage
                      {...messages.joinOurRememberedProgram}
                      values={{
                        red: (
                          <span className={s.highlight}>
                            <FormattedMessage
                              {...messages.joinOurRememberedProgramRed}
                            />
                          </span>
                        ),
                      }}
                    />
                  </div>
                  <div className={s.joinRememberedDescription}>
                    <FormattedMessage
                      {...messages.joinAndReceive}
                      values={{
                        tenPercentOff: (
                          <span className={s.highlight}>
                            <FormattedMessage {...messages.tenPercentOff} />
                          </span>
                        ),
                      }}
                    />
                  </div>
                </div>
              )}
              <br />
              <div>
                {rooms.length > 0 ? (
                  rooms.map(room => (
                    <RoomCard
                      key={room.id}
                      room={room}
                      onClickShowFeature={this.onClickShowFeature}
                      showFeature={room.id === showingFeatureForRoom}
                    />
                  ))
                ) : (
                  <div>
                    <h3>
                      <FormattedMessage {...messages.noRoomAvailable} />
                    </h3>
                  </div>
                )}
              </div>
              {rooms.length > 0 && breakfast && (
                <div id="breakfast" className={s.breakfast}>
                  <h2>
                    <FormattedMessage {...messages.breakfast} />
                  </h2>
                  <div className={s.box}>
                    <div className={s.breakFastCheckbox}>
                      <CheckBox
                        className={s.checkboxRoomSelect}
                        name="breakfast"
                        isChecked={booking.includeBreakfast}
                        onChange={this.onClickIncludeBreakfast}
                      />
                    </div>
                    <div className={s.breakFastCheckboxLabel}>
                      <img
                        className={s.breakFastCheckboxIcon}
                        src={iconBreakfast}
                        srcSet={`${iconBreakfastAdd2x} 2x`}
                        alt="breakfast"
                        width={38}
                        height={24}
                      />
                      <FormattedMessage
                        {...messages.includeBreakfast}
                        values={{
                          breakfast: (
                            <span className={s.highlight}>
                              {breakfast.name}
                            </span>
                          ),
                          price: (
                            <span className={s.highlight}>
                              <CurrencyDisplay
                                value={breakfast.price_per_guest}
                                currency={currency}
                              />
                            </span>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {rooms.length > 0 && smoking && (
                <div id="smoking" className={s.smoking}>
                  <h2>
                    <FormattedMessage {...messages.smoking} />
                  </h2>
                  <div className={s.box}>
                    <div className={s.smokingCheckbox}>
                      <CheckBox
                        className={s.checkboxRoomSelect}
                        name="smoking"
                        isChecked={booking.includeSmoking}
                        onChange={this.onClickIncludeSmoking}
                      />
                    </div>
                    <div className={s.smokingCheckboxLabel}>
                      <img
                        className={s.smokingCheckboxIcon}
                        src={iconSmoking}
                        srcSet={`${iconSmokingAdd2x} 2x`}
                        alt="smoking"
                      />
                      <FormattedMessage
                        {...messages.includeSmoking}
                        values={{
                          price: (
                            <span className={s.highlight}>
                              <CurrencyDisplay
                                value={smoking.price_per_night}
                                currency={currency}
                              />
                            </span>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {rooms.length > 0 && pet_friendly && (
                <div id="pet_friendly" className={s.petFriendly}>
                  <h2>
                    <FormattedMessage {...messages.petFriendly} />
                  </h2>
                  <div className={s.box}>
                    <div className={s.petFriendlyCheckbox}>
                      <CheckBox
                        className={s.checkboxRoomSelect}
                        name="pet_friendly"
                        isChecked={booking.includePetFriendly}
                        onChange={this.onClickIncludePetFriendly}
                      />
                    </div>
                    <div className={s.petFriendlyCheckboxLabel}>
                      <img
                        className={s.petFriendlyCheckboxIcon}
                        src={iconPetFriendly}
                        srcSet={`${iconPetFriendlyAdd2x} 2x`}
                        alt="Pet Friendly"
                      />
                      <FormattedMessage
                        {...messages.includePetFriendly}
                        values={{
                          price: (
                            <span className={s.highlight}>
                              <CurrencyDisplay
                                type={
                                  pet_friendly.price_per_night === 0
                                    ? 'free'
                                    : null
                                }
                                value={pet_friendly.price_per_night}
                                currency={currency}
                              />
                            </span>
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              {rooms.length > 0 && sundrySales.length > 0 && (
                <SundrySales imageTooltipRef={this.imageTooltipRef} />
              )}
              {rooms.length > 0 && (
                <div className={s.quickInfo}>
                  <h2>
                    <FormattedMessage
                      {...messages.quickInfo}
                      values={{
                        hotel: <span className={s.highlight}>{name}</span>,
                      }}
                    />
                  </h2>
                  <table>
                    <tbody>
                      <tr>
                        <td colSpan={3}>
                          <span className={s.pullLeft}>
                            <h3>
                              <img
                                className={s.iconClock}
                                src={iconClock}
                                srcSet={`${iconClockAdd2x} 2x`}
                                alt="stay dates"
                              />
                              {hotelQuickInfo['quick_info.check_in.label']}
                            </h3>
                            <span className={s.checkInAndOutPolicy}>
                              {
                                hotelQuickInfo[
                                  `quick_info.check_in.${quickInfoCode}`
                                ]
                              }
                            </span>
                          </span>
                          <span className={s.pullLeft}>
                            <h3>
                              <img
                                className={s.iconClock}
                                src={iconClock}
                                srcSet={`${iconClockAdd2x} 2x`}
                                alt="stay dates"
                              />
                              {hotelQuickInfo['quick_info.check_out.label']}
                            </h3>
                            <span className={s.checkInAndOutPolicy}>
                              {
                                hotelQuickInfo[
                                  `quick_info.check_out.${quickInfoCode}`
                                ]
                              }
                            </span>
                          </span>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <h3>
                            {hotelQuickInfo['quick_info.child_policy.label']}
                          </h3>
                          <ul>
                            <li>
                              {
                                hotelQuickInfo[
                                  `quick_info.child_policy_line1.${quickInfoCode}`
                                ]
                              }
                            </li>
                            <li>
                              {
                                hotelQuickInfo[
                                  `quick_info.child_policy_line2.${quickInfoCode}`
                                ]
                              }
                            </li>
                          </ul>
                        </td>
                        <td>
                          <h3>
                            {
                              hotelQuickInfo[
                                'quick_info.maximum_occupancy.label'
                              ]
                            }
                          </h3>
                          {
                            hotelQuickInfo[
                              `quick_info.maximum_occupancy.${quickInfoCode}`
                            ]
                          }
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <h3>
                            {hotelQuickInfo['quick_info.extra_bed.label']}
                          </h3>
                          {
                            hotelQuickInfo[
                              `quick_info.extra_bed.${quickInfoCode}`
                            ]
                          }
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <h3>
                            <FormattedMessage
                              {...messages.accessibilityHeader}
                            />
                          </h3>
                          <FormattedMessage {...messages.accessibility} />
                          <a
                            href={accessibilityURL}
                            hrefLang={this.context.intl.locale}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ color: 'red' }}
                          >
                            <FormattedMessage {...messages.accessibilityLink} />
                          </a>
                          <FormattedMessage {...messages.accessibilityExtra} />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={3}>
                          <h3>
                            <FormattedMessage
                              {...messages.GuaranteeCancellationPolicyHeader}
                            />
                          </h3>
                          <div className={s.guaranteecancellationList}>
                            <ul>{guaranteecancellationList}</ul>
                          </div>
                        </td>
                        {country_code === 'PH' && (
                          <td colSpan={1}>
                            <div className={s.guaranteecancellationBadge}>
                              <img src={NPCLogo} alt="NPC Badge" />
                            </div>
                          </td>
                        )}
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
            </div>
            <div
              ref={colRight => {
                this.colRight = colRight;
              }}
              className={cx(s.colRight, fixedRightColumn && s.fixed)}
              style={{ left: isMobile ? 0 : this.initialColRightPosition.x }}
            >
              {rooms.length > 0 && (
                <PriceSummary
                  usingIn={PS_ROOM_SELECT}
                  onClickButton={this.onClickContinueButton}
                />
              )}
            </div>
          </div>
          <div className={s.sticky}>
            <StickyPriceSummary
              usingIn={PS_ROOM_SELECT}
              onClick={this.onClickContinueButton}
            />
          </div>
        </div>
        <div ref={this.imageTooltipRef} id="imageTooltip" />
      </div>
    );
  }
}

RoomSelect.propTypes = {
  hotelQuickInfo: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  joinRemembered: PropTypes.func,
  includeBreakfast: PropTypes.func,
  includeSmoking: PropTypes.func,
  includePetFriendly: PropTypes.func,
  booking: BookingShape.isRequired,
  user: UserShape.isRequired,
  openErrorModal: PropTypes.func,
  // eslint-disable-next-line react/require-default-props, react/forbid-prop-types
  query: PropTypes.object,
};

RoomSelect.defaultProps = {
  joinRemembered: () => {},
  includeBreakfast: () => {},
  includeSmoking: () => {},
  includePetFriendly: () => {},
  openErrorModal: () => {},
};

RoomSelect.contextTypes = {
  intl: intlShape.isRequired,
};

const mapState = state => ({
  booking: state.booking,
  user: state.user,
});

const mapDispatch = {
  joinRemembered,
  includeBreakfast,
  includeSmoking,
  includePetFriendly,
  addSundryItem,
  openErrorModal,
};

export default connect(mapState, mapDispatch)(withStyles(s)(RoomSelect));
