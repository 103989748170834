import {
  GraphQLFloat as Float,
  GraphQLObjectType as ObjectType,
  GraphQLNonNull as NonNull,
} from 'graphql';

import PropTypes from 'prop-types';

const RateType = new ObjectType({
  name: 'Rate',
  fields: {
    price: { type: new NonNull(Float) },
    additional_amount_adult: { type: new NonNull(Float) },
    additional_amount_child: { type: new NonNull(Float) },
    price_no_tax: { type: new NonNull(Float) },
    additional_amount_adult_no_tax: { type: new NonNull(Float) },
    additional_amount_child_no_tax: { type: new NonNull(Float) },
  },
});

RateType.shape = () =>
  PropTypes.shape({
    price: PropTypes.number,
    additional_amount_adult: PropTypes.number,
    additional_amount_child: PropTypes.number,
    price_no_tax: PropTypes.number,
    additional_amount_adult_no_tax: PropTypes.number,
    additional_amount_child_no_tax: PropTypes.number,
  });

export default RateType;
