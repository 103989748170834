// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.CurrencyDisplay-root b {\n  text-transform: uppercase;\n  font-weight: normal;\n  font-size: 1em;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "CurrencyDisplay-root"
};
module.exports = exports;
