/**
 * Created by methee on 9/12/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './BlueNoticeBox.css';

class BlueNoticeBox extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  render() {
    const children = React.Children.only(this.props.children);
    return <div className={s.root}>{children}</div>;
  }
}

export default withStyles(s)(BlueNoticeBox);
