/* eslint-disable arrow-body-style */
/* eslint-disable react/react-in-jsx-scope */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import s from './MeetingRoomForm.css';

const MeetingRoom = props => {
  const { link } = props;

  if (link !== '') {
    return (
      <div>
        <iframe
          className={s.root}
          src={link}
          width="100%"
          height="100%"
          title="meeting room form"
        />
      </div>
    );
  }
  return (
    <div className={s.noLink}>
      <h2 className={s.marginAuto}>Page is not found!</h2>
    </div>
  );
};

MeetingRoom.propTypes = {
  link: PropTypes.string,
};

MeetingRoom.defaultProps = {
  link: null,
};

const mapState = state => ({
  link: state.common.link,
});

export default connect(mapState, null)(withStyles(s)(MeetingRoom));
// import React, { useEffect, useState } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Link,
//   useLocation
// } from "react-router-dom";

// function delay(time) {
//   return new Promise((resolve) => setTimeout(resolve, time));
// }

// const fakeApi = async () => {
//   await delay(1000);
//   return {
//     src: "https://fr.wikipedia.org/wiki/Main_Page"
//   };
// };

// const Page1 = () => <div>Page with sample content</div>;

// const Page2 = () => {
//   const [iframeUrl, setIframeUrl] = useState(null);

//   const loadIframe = async () => {
//     const { src } = await fakeApi();
//     setIframeUrl(src);
//   };

//   useEffect(() => {
//     loadIframe();
//   }, []);

//   return (
//     <div>
//       <h3>Page with sample iframe</h3>
//       <p>
//         Eveytime a users moves to this page the iframe is reloaded (ex. if you
//         try to scroll, navigate to "Page with content" and back to "Page with
//         iframe", you'll see a new iframe is loaded)
//       </p>
//       <iframe src={iframeUrl} width="640" height="480" title="test wikipedia" />
//     </div>
//   );
// };

// const App = () => {
//   const loc = useLocation();
//   console.log(loc);
//   return (
//     <>
//       <ul>
//         <li>
//           <Link to="/contentpage">Page with content</Link>
//         </li>
//         <li>
//           <Link to="/iframepage">Page with iframe</Link>
//         </li>
//       </ul>

//       <hr />
//       <div hidden={loc.pathname !== "/iframepage"}>
//         <Page2 />
//       </div>
//       <Routes>
//         <Route exact path="/contentpage" element={<Page1 />} />
//         <Route exact path="/iframepage" element={<></>} />
//       </Routes>
//     </>
//   );
// };

// export default () => (
//   <Router>
//     <App />
//   </Router>
// );
