// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.Button-root {\n  width: 100%;\n  height: 50px;\n  padding: 5px;\n  color: #000;\n  background-color: #ffe900;\n  position: relative;\n  cursor: pointer;\n  -webkit-appearance: none;\n  border-radius: 10px;\n  border-color: transparent;\n  text-transform: uppercase;\n  font-size: 26px;\n  line-height: 20px;\n  font-weight: bold;\n  font-family: 'Lemon Sans', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n}\n\n.Button-root img {\n  margin-right: 15px;\n}\n\n.Button-root:disabled {\n  background-color: #cacaca !important;\n  cursor: not-allowed;\n}\n\n.Button-root:active {\n  -webkit-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);\n          box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Button-root"
};
module.exports = exports;
