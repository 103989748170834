/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';

import TextBox from '../TextBox';
import Link from '../Link';
import Button from '../Button';
import FacebookButton from '../FacebookButton';
import UserLoginType from '../../data/types/UserLoginType';

import { login, openSignUpModal, closeSignInModal } from '../../actions/user';

import s from './SignInForm.css';
import iconEmail from './icon-email.webp';
import iconEmailAdd2x from './icon-email@2x.webp';
import iconLock from './icon-lock.webp';
import iconLockAdd2x from './icon-lock@2x.webp';

const messages = defineMessages({
  formHeader: {
    id: 'signin.log_in_to_your_remembered_account',
    defaultMessage: '##Sign In to your Remembe{red} account##',
    description:
      'The header above sign in form. Do not translate or remove {red} part',
  },
  rememberedRed: {
    id: 'signin.remembered.red',
    defaultMessage: '##red##',
    description: '{red} part of Remembe{red}',
  },
  email: {
    id: 'signin.email',
    defaultMessage: '##Email##',
    description: 'Label for email',
  },
  password: {
    id: 'signin.password',
    defaultMessage: '##Password##',
    description: 'Label for password',
  },
  forgotPassword: {
    id: 'signin.forgot_your_password',
    defaultMessage: '##Forgot your password?##',
    description: 'For got password link',
  },
  signIn: {
    id: 'signin.sign_in',
    defaultMessage: '##Sign In##',
    description: 'Sign in button',
  },
  signupCTA: {
    id: 'signin.not_a_member_yet',
    defaultMessage: '##Not a member? {joinNow}##',
    description:
      'Call to action for sign up in the bottom of Sign in page. Do not remove or translate the {joinNow} part',
  },
  joinNow: {
    id: 'signin.not_a_member_yet_join_now',
    defaultMessage: '##Join now!##',
    description: '{joinNow} part of the Sign up call to action',
  },
  error: {
    id: 'errorMessage.general',
    defaultMessage: '##Please check the highlighted fields##',
    description: 'General error description',
  },
});

const isError = (errors, field) =>
  errors.length > 0 && errors.indexOf(field) > -1;

class SignInForm extends React.Component {
  static propTypes = {
    user: UserLoginType.shape(),
    login: PropTypes.func,
    openSignUpModal: PropTypes.func,
    closeSignInModal: PropTypes.func,
  };

  static defaultProps = {
    user: {
      errors: [],
      errorMessage: '',
    },
    login: () => {},
    openSignUpModal: () => {},
    closeSignInModal: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      submitOnce: false,
    };
  }

  onEmailInputMounted = emailInput => {
    emailInput.focus();
  };

  onEmailChange = email => {
    this.email = email;
  };

  onPasswordChange = password => {
    this.password = password;
  };

  onSubmit = event => {
    this.props.login(
      this.email,
      this.password,
      this.context.intl.formatMessage(messages.error),
    );
    this.setState({ submitOnce: true });
    if (event) {
      event.preventDefault();
    }
    return true;
  };

  onClickJoinNow = () => {
    this.props.openSignUpModal();
  };

  email = '';
  password = '';

  render() {
    const {
      user: { errorMessage, errors },
    } = this.props;
    const { submitOnce } = this.state;

    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <div className={s.container}>
          <h3>
            <FormattedMessage
              {...messages.formHeader}
              values={{
                remembered: <span className={s.highlight}>Remembered</span>,
              }}
            />
          </h3>
          <div className={s.row}>
            <form action={''} method={'get'} onSubmit={this.onSubmit}>
              <div className={s.formGroup}>
                <TextBox
                  onRef={this.onEmailInputMounted}
                  inputType={'email'}
                  icon={{
                    normal: iconEmail,
                    add2x: iconEmailAdd2x,
                  }}
                  placeholder={this.context.intl.formatMessage(messages.email)}
                  onChange={this.onEmailChange}
                  shadow
                  errorMessage={errorMessage}
                  isError={submitOnce && errors.length > 0}
                />
              </div>
              <div className={s.formGroup}>
                <TextBox
                  inputType={'password'}
                  icon={{
                    normal: iconLock,
                    add2x: iconLockAdd2x,
                  }}
                  placeholder={this.context.intl.formatMessage(
                    messages.password,
                  )}
                  onChange={this.onPasswordChange}
                  shadow
                  isError={submitOnce && isError(errors, 'password')}
                />
              </div>
              <input type="submit" />
              <div style={{ opacity: 0, position: 'absolute' }}>
                <button type="submit" />
              </div>
            </form>
          </div>
          <div className={s.row}>
            <div className={s.forgotPassword}>
              <Link
                to={'/forgot-password'}
                onClick={() => {
                  this.props.closeSignInModal();
                }}
              >
                <FormattedMessage {...messages.forgotPassword} />
              </Link>
            </div>
          </div>
          <div className={s.row}>
            <div className={s.buttonGroup}>
              <div className={s.pullLeft}>
                <FacebookButton forSignIn className={s.fbButton} />
              </div>
              <Button
                className={s.signinButton}
                onClick={this.onSubmit}
                label={this.context.intl.formatMessage(messages.signIn)}
              />
            </div>
          </div>
        </div>
        <div className={s.joinNow}>
          <FormattedMessage
            {...messages.signupCTA}
            values={{
              joinNow: (
                <button onClick={this.onClickJoinNow}>
                  <FormattedMessage {...messages.joinNow} />
                </button>
              ),
            }}
          />
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  user: state.user,
});

const mapDispatch = {
  login,
  openSignUpModal,
  closeSignInModal,
};

export default connect(
  mapState,
  mapDispatch,
)(withStyles(s)(SignInForm));
