let ReactPixel;
if (process.env.BROWSER) {
  // eslint-disable-next-line global-require
  ReactPixel = require('react-facebook-pixel').default;
}

// eslint-disable-next-line import/prefer-default-export
export const onTrackingPageView = (countryId, facebookPixelId) => {
  if (!facebookPixelId) {
    return true;
    // throw new Error('Invalid facebook pixel tracking ID.');
  }

  if (process.env.BROWSER && ReactPixel) {
    const fbPixelAdvancedMatching = {};
    const fbPixelOptions = {
      autoConfig: true,
      debug: true,
    };

    // Skip on testing.
    if (typeof jest === 'undefined') {
      // Philippines
      if (countryId === '13') {
        ReactPixel.init(
          facebookPixelId.ph,
          fbPixelAdvancedMatching,
          fbPixelOptions,
        );
        ReactPixel.pageView();
      }

      // Thailand
      if (countryId === '3') {
        ReactPixel.init(
          facebookPixelId.th,
          fbPixelAdvancedMatching,
          fbPixelOptions,
        );
        ReactPixel.pageView();
      }
    }
  }

  return true;
};
