/**
 * Created by methee on 8/29/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { intlShape, defineMessages } from 'react-intl';

import s from './BirthdayField.css';

import Dropdown from '../Dropdown';

const messages = defineMessages({
  day: {
    id: 'signup.day',
    defaultMessage: '##Day##',
    description: 'Placeholder for Day',
  },
  errorDay: {
    id: 'signup.errorDay',
    defaultMessage: '##Error Day##',
    description: 'Error Birth Day indicator',
  },
  month: {
    id: 'signup.month',
    defaultMessage: '##Month##',
    description: 'Placeholder for Month',
  },
  errorMonth: {
    id: 'signup.errorMonth',
    defaultMessage: '##Error Month##',
    description: 'Error Birth Month indicator',
  },
  year: {
    id: 'signup.year',
    defaultMessage: '##Year##',
    description: 'Placeholder for Year',
  },
  errorYear: {
    id: 'signup.errorYear',
    defaultMessage: '##Error Year##',
    description: 'Error Birth Year indicator',
  },
});

class BirthdayField extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    birthday: PropTypes.instanceOf(moment),
    isError: PropTypes.bool,
  };

  static defaultProps = {
    birthday: null,
    onChange: () => {},
    isError: false,
  };

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props, context) {
    super(props);
    const { birthday } = this.props;
    const days = [
      {
        value: 0,
        name: context.intl.formatMessage(messages.day),
      },
    ];
    const selectedDay = birthday ? birthday.date() : 0;
    for (let i = 1; i <= 31; i += 1) {
      days.push({ value: i, name: `${i}` });
    }
    const allMonths = moment.monthsShort();
    const months = [
      {
        value: '',
        name: context.intl.formatMessage(messages.month),
      },
    ];
    const selectedMonth = birthday ? birthday.month() : '';
    allMonths.forEach((month, index) => {
      months.push({ value: index, name: month });
    });
    const startYear = moment().subtract(18, 'years');
    const endYear = moment().subtract(100, 'years');
    const years = [
      {
        value: 0,
        name: context.intl.formatMessage(messages.year),
      },
    ];
    const selectedYear = birthday ? birthday.year() : 0;
    for (let i = startYear.year(); i > endYear.year(); i -= 1) {
      years.push({ value: i, name: `${i}` });
    }
    this.state = {
      days,
      months,
      years,
      selectedDay,
      selectedMonth,
      selectedYear,
    };
  }

  componentDidUpdate() {
    if (this.state.selectedDay > this.state.days.length - 1) {
      this.onSelectedDay(0);
    }
  }

  onSelectedDay = value => {
    this.setState({ selectedDay: value });
    this.onChange(this.state.selectedYear, this.state.selectedMonth, value);
  };

  onSelectedMonth = value => {
    this.setState({ selectedMonth: value });
    this.updateDaysInMonth(this.state.selectedYear, value);
    this.onChange(this.state.selectedYear, value, this.state.selectedDay);
  };

  onSelectedYear = value => {
    this.setState({ selectedYear: value });
    this.updateDaysInMonth(value, this.state.selectedMonth);
    this.onChange(value, this.state.selectedMonth, this.state.selectedDay);
  };

  onChange(year, month, day) {
    if (day !== 0 && month !== '' && year !== 0) {
      const birthday = moment()
        .date(day)
        .month(month)
        .year(year);
      this.props.onChange(birthday);
    } else {
      this.props.onChange(null);
    }
  }

  updateDaysInMonth(year, month) {
    const daysInMonth = moment()
      .set('month', month)
      .set('year', year)
      .daysInMonth();
    const days = [
      {
        value: 0,
        name: this.context.intl.formatMessage(messages.day),
      },
    ];
    for (let i = 1; i <= daysInMonth; i += 1) {
      days.push({ value: i, name: `${i}` });
    }
    this.setState({ days });
  }

  render() {
    const {
      days,
      months,
      years,
      selectedDay,
      selectedMonth,
      selectedYear,
    } = this.state;
    const { isError } = this.props;
    return (
      <div className={s.root}>
        <div className={s.col4}>
          <Dropdown
            id={'day'}
            onSelectedValue={this.onSelectedDay}
            options={days}
            preselectedValue={selectedDay}
            shadow
            errorMessage={this.context.intl.formatMessage(messages.errorDay)}
            isError={isError}
          />
        </div>
        <div className={s.col4}>
          <Dropdown
            id={'month'}
            onSelectedValue={this.onSelectedMonth}
            options={months}
            preselectedValue={selectedMonth}
            shadow
            errorMessage={this.context.intl.formatMessage(messages.errorMonth)}
            isError={isError}
          />
        </div>
        <div className={s.col4}>
          <Dropdown
            id={'year'}
            onSelectedValue={this.onSelectedYear}
            options={years}
            preselectedValue={selectedYear}
            shadow
            errorMessage={this.context.intl.formatMessage(messages.errorYear)}
            isError={isError}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(s)(BirthdayField);
