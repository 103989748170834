/**
 * Created by methee on 8/21/17.
 */

/* eslint-disable css-modules/no-unused-class */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';
import { connect } from 'react-redux';

import Link from '../Link';
import { BookingShape } from '../../actions/booking';

import s from './BookingProgress.css';

export const BP_ROOM_SELECT = 'BP_ROOM_SELECT';
export const BP_GUEST_PROFILE = 'BP_GUEST_PROFILE';
export const BP_PAYMENT = 'BP_PAYMENT';

const messages = defineMessages({
  roomSelect: {
    id: 'booking.select_your_room',
    defaultMessage: '##Select your room type##',
    description: 'Booking progress select your room type',
  },
  guestProfile: {
    id: 'booking.enter_your_personal_detail',
    defaultMessage: '##Enter your personal detail##',
    description: 'Booking progress enter your personal detail',
  },
  payment: {
    id: 'booking.confirm_pay',
    defaultMessage: '##Pay & confirm##',
    description: 'Booking progress pay and confirm',
  },
});

class BookingProgress extends React.Component {
  static propTypes = {
    currentProgress: PropTypes.oneOf([
      'BP_ROOM_SELECT',
      'BP_GUEST_PROFILE',
      'BP_PAYMENT',
    ]).isRequired,
    booking: BookingShape,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    booking: null,
  };

  render() {
    let isEdit = false;
    const { currentProgress, booking } = this.props;
    if (!booking || !booking.hotel) {
      return <div />;
    }
    let query = `hotel=${booking.hotel.id}&check_in=${booking.checkIn.format(
      'YYYY-MM-DD',
    )}&check_out=${booking.checkOut.format('YYYY-MM-DD')}&rooms=${
      booking.numberOfRooms
    }&adults=${booking.adults}&children=${booking.children}${
      booking.includeBreakfast ? '&breakfast=1' : ''
    }${booking.includeSmoking ? '&smoking=1' : ''}${
      booking.includePetFriendly ? '&pet_friendly=1' : ''
    }
    ${booking.flashCode ? `&code=${booking.flashCode}` : ''}`;
    if (booking.oldBooking && booking.oldBooking.booking_id) {
      isEdit = true;
      query += `&booking_id=${booking.oldBooking.booking_id}&confirmation=${
        booking.oldBooking.booking_code
      }&email=${encodeURIComponent(
        booking.oldBooking.rooms[0].guests[0].email,
      )}`;
    }
    return (
      <ul className={cx(s.root, s[this.context.intl.locale])}>
        <li
          className={cx(
            currentProgress === 'BP_ROOM_SELECT' ||
              currentProgress === 'BP_GUEST_PROFILE' ||
              currentProgress === 'BP_PAYMENT'
              ? s.selected
              : '',
          )}
        >
          <Link
            to={
              isEdit
                ? `/booking/edit/room-select?${query}`
                : `/booking/room-select?${query}`
            }
          >
            <FormattedMessage {...messages.roomSelect} />
          </Link>
        </li>
        <li
          className={cx(
            currentProgress === 'BP_GUEST_PROFILE' ||
              currentProgress === 'BP_PAYMENT'
              ? s.selected
              : '',
          )}
        >
          <Link
            to={`/booking/guest-profile?${query}`}
            disabled={currentProgress === 'BP_ROOM_SELECT'}
          >
            <FormattedMessage {...messages.guestProfile} />
          </Link>
        </li>
        <li className={cx(currentProgress === 'BP_PAYMENT' ? s.selected : '')}>
          <Link
            to={`/booking/payment?${query}`}
            disabled={
              currentProgress === 'BP_ROOM_SELECT' ||
              currentProgress === 'BP_GUEST_PROFILE'
            }
          >
            <FormattedMessage {...messages.payment} />
          </Link>
        </li>
      </ul>
    );
  }
}

const mapState = state => ({
  booking: state.booking,
});

export default connect(mapState)(withStyles(s)(BookingProgress));
