/**
 * Created by methee on 9/4/17.
 */
import {
  GraphQLObjectType as ObjectType,
  GraphQLNonNull as NonNull,
  GraphQLString as StringType,
} from 'graphql';
import PropTypes from 'prop-types';

import UserType from './UserType';

const UserLoginType = new ObjectType({
  name: 'UserLogin',
  fields: {
    member: {
      type: UserType,
    },
    access_token: { type: new NonNull(StringType) },
  },
});

UserLoginType.shape = () =>
  PropTypes.shape({
    member: UserType.shape(),
    access_token: PropTypes.string,
  });

export default UserLoginType;
