/**
 * Created by methee on 8/29/17.
 */
import {
  GraphQLObjectType as ObjectType,
  GraphQLString as StringType,
  GraphQLNonNull as NonNull,
  GraphQLBoolean as BooleanType,
} from 'graphql';

import PropTypes from 'prop-types';

const CountryType = new ObjectType({
  name: 'CountryOfResidence',
  fields: {
    code: { type: new NonNull(StringType) },
    name: { type: new NonNull(StringType) },
    phone_code: { type: new NonNull(StringType) },
    code_alpha_3: { type: StringType },
    jumio_country_supported: { type: BooleanType },
  },
});

CountryType.shape = () =>
  PropTypes.shape({
    code: PropTypes.string,
    name: PropTypes.string,
    phone_code: PropTypes.string,
    code_alpha_3: PropTypes.string,
    jumio_country_supported: PropTypes.bool,
  });

export default CountryType;
