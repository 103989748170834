import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  item: PropTypes.shape({
    image_thumb: PropTypes.string, // eslint-disable-line camelcase
    title: PropTypes.string,
  }),
  clientX: PropTypes.number,
  clientY: PropTypes.number,
};

const defaultProps = {
  item: null,
  clientX: null,
  clientY: null,
};
class SundryItemTooltip extends React.Component {
  render() {
    const img = new Image();
    img.src = this.props.item.image_thumb;

    return (
      <img
        src={this.props.item.image_thumb}
        alt={this.props.item.title}
        style={{
          height: img.height / 2,
          width: img.width / 2,
          position: 'absolute',
          top: this.props.clientY - img.height / 4,
          left: this.props.clientX + 10,
          zIndex: 1,
        }}
      />
    );
  }
}

SundryItemTooltip.propTypes = propTypes;
SundryItemTooltip.defaultProps = defaultProps;

export default SundryItemTooltip;
