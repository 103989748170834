/**
 * Created by methee on 8/30/17.
 */

import googleLibPhone, { PhoneNumberFormat } from 'google-libphonenumber';

export function isStringAlpha(string) {
  const regex = /^([^-\s][-a-zA-Z\s.'’]*)$/;
  return regex.test(string);
}

export function isNonLatinCharacters(string) {
  // eslint-disable-next-line no-control-regex
  const regex = /^([^\u0000-\u007F]*)$/;
  return regex.test(string);
}

export function isTextAlpha(string) {
  const text = string.replace(/^\s+|\s+$/gm, '');
  const regex = /^[-a-zA-Z]*[-a-zA-Z\s.'’]+$/;
  return regex.test(text);
}

export function isNumeric(string) {
  const regex = /^(0|[1-9][0-9]*)$/;
  return regex.test(string);
}

export function isEmail(email) {
  const regex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
}

export function getHeaders(apiKey, cookies, headers, ip) {
  return {
    'Access-Token': cookies['rphl.accessToken'],
    'Api-Key': apiKey,
    'User-Agent': headers['user-agent'],
    'X-Forwarded-For': ip,
    Referer: headers.referer,
    Origin: headers.origin,
    'Rphl-Web3': 1,
  };
}

export function formatNumber(number) {
  // eslint-disable-next-line no-restricted-globals
  return isNaN(number)
    ? number
    : number.toFixed(0, 10).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
}

export function formatPhoneNumber(phoneCountry) {
  let formattedPhone = '';
  try {
    let pc = { country: '', phone: '' };
    if (phoneCountry.country && phoneCountry.phone) {
      pc = phoneCountry;
    } else {
      pc = JSON.parse(decodeURI(phoneCountry));
    }
    if (pc.country && pc.phone) {
      const phoneUtil = googleLibPhone.PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parse(pc.phone, pc.country);
      formattedPhone = phoneUtil.format(phoneNumber, PhoneNumberFormat.E164);
    }
  } catch (e) {
    // do nothing
  }
  return formattedPhone;
}

export function phoneToPhoneCountry(phone) {
  try {
    const phoneUtil = googleLibPhone.PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parse(phone);
    if (phoneUtil.isValidNumber(phoneNumber)) {
      return {
        country: phoneUtil.getRegionCodeForNumber(phoneNumber),
        phone: phoneNumber.getNationalNumber(),
      };
    }
  } catch (e) {
    // do nothing
  }
  return { country: '', phone: '' };
}

export function inArray(args, field) {
  return args.length > 0 && args.indexOf(field) > -1;
}

export function isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array;
}

export function addFieldArray(args, field) {
  if (!inArray(args, field)) {
    args.push(field);
  }
  return args;
}
