import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './Options.css';

class Options extends React.Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.number,
      }),
    ).isRequired,
    show: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
  };

  onClick = value => {
    this.props.onChange(value);
  };

  render() {
    const { options, show } = this.props;
    if (!show) {
      return null;
    }
    const displayOptions = [];
    options.forEach(({ value, name }) => {
      displayOptions.push(
        <div key={value}>
          <button
            onClick={() => {
              this.onClick(value);
            }}
          >
            {name}
          </button>
        </div>,
      );
    });
    return <div className={s.root}>{displayOptions}</div>;
  }
}

export default withStyles(s)(Options);
