/**
 * Created by methee on 9/13/17.
 */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import PriceSummary, {
  PS_PAYMENT,
  PS_GUEST_PROFILE,
  PS_ROOM_SELECT,
} from '../PriceSummary';
import { UserShape } from '../../actions/user';
import { getPrices, BookingShape } from '../../actions/booking';
import CurrencyDisplay from '../CurrencyDisplay';
import Button from '../Button';

import s from './StickyPriceSummary.css';

const messages = defineMessages({
  total: {
    id: 'stickySummary.total',
    defaultMessage: '##Total {price}##',
    description:
      'Total price on sticky price summary. Do not remove or translate the {price}',
  },
  totalBirthday: {
    id: 'stickySummary.total_birthday',
    defaultMessage: '##Total Birthday {price}##',
    description: 'Total birthday label on price summary',
  },
  continue: {
    id: 'stickySummary.continue',
    defaultMessage: '##Continue##',
    description: 'Continue button in price summary',
  },
  pleaseSelectRoom: {
    id: 'stickySummary.pleaseSelectRoom',
    defaultMessage: '##Please select room##',
    description: 'Word indicate please select room on continue button',
  },
  confirmAndPay: {
    id: 'stickySummary.confirmAndPay',
    defaultMessage: '##Confirm & Pay##',
    description: 'Confirm & pay button in price summary box',
  },
  pleaseFillGuestInformation: {
    id: 'stickySummary.pleaseFillGuestInformation',
    defaultMessage: '##Please fill in guest information##',
    description:
      'Word indicate please fill in guest information on continue button',
  },
  details: {
    id: 'stickySummary.details',
    defaultMessage: '##Details##',
    description: 'Label for showing full price summary',
  },
  submittingBooking: {
    id: 'stickySummary.submittingBooking',
    defaultMessage: '##Submitting Booking##',
    description: 'Label on button while submitting booking to server',
  },
  pleaseSelectMore: {
    id: 'price_summary.pleaseSelectMore',
    defaultMessage: '##Please select at least {roomCount}##',
    description: 'Word indicate please select room on continue button',
  },
  roomCount: {
    id: 'price_summary.roomCount',
    defaultMessage:
      '{roomCount, plural, zero {{value} {rooms}} one {{value} {room}} other {{value} {rooms}}}',
    description: 'Ignore this phrase',
  },
  room: {
    id: 'price_summary.room',
    defaultMessage: '##Room##',
    description: 'Room count',
  },
  rooms: {
    id: 'price_summary.rooms',
    defaultMessage: '##Rooms##',
    description: 'Rooms count',
  },
});

class StickyPriceSummaryBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showDetail: false,
      initialized: false,
    };
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ initialized: true });
    }, 300);
  }

  onClick = () => {
    this.props.onClick();
  };

  onClickDetail = () => {
    this.setState({ showDetail: true });
  };

  onClickClose = () => {
    this.setState({ showDetail: false });
  };

  render() {
    const { user, booking, usingIn } = this.props;
    if (!booking || !booking.hotel) {
      return <div />;
    }
    const {
      hotel: { is_birthday_remembered },
      roomTypes,
      adults,
      children,
      numberOfRooms,
      joinRemembered,
    } = booking;
    const { showDetail, initialized } = this.state;
    const { totalMember, totalNormal } = getPrices(booking, user);
    const addedRooms = [];
    let hasError = false;
    booking.roomTypes.forEach(roomType => {
      if (roomType.numberOfRooms > 0) {
        addedRooms.push({ ...roomType });
      }
    });
    let continueButtonLabel = this.context.intl.formatMessage(
      messages.continue,
    );
    if (usingIn === PS_PAYMENT) {
      continueButtonLabel = this.context.intl.formatMessage(
        messages.confirmAndPay,
      );
    }
    if (addedRooms.length === 0 && usingIn === PS_ROOM_SELECT) {
      hasError = true;
      continueButtonLabel = this.context.intl.formatMessage(
        messages.pleaseSelectRoom,
      );
    }
    const profileErrors = [
      'firstName',
      'lastName',
      'email',
      'phone',
      'country',
      'birthday',
    ];
    let hasProfileError = false;
    booking.errors.forEach(field => {
      if (profileErrors.indexOf(field) > -1) {
        hasProfileError = true;
        return true;
      }
      return false;
    });
    if (hasProfileError && usingIn === PS_GUEST_PROFILE) {
      hasError = true;
      continueButtonLabel = this.context.intl.formatMessage(
        messages.pleaseFillGuestInformation,
      );
    }
    let selectedNumberOfRooms = 0;
    roomTypes.forEach(roomType => {
      if (roomType.numberOfRooms > 0) {
        addedRooms.push({ ...roomType });
        selectedNumberOfRooms += roomType.numberOfRooms;
      }
    });
    if (usingIn === PS_ROOM_SELECT) {
      if (selectedNumberOfRooms === 0) {
        hasError = true;
        continueButtonLabel = this.context.intl.formatMessage(
          messages.pleaseSelectRoom,
        );
      } else if (selectedNumberOfRooms < numberOfRooms) {
        hasError = true;
        const roomCount = this.context.intl.formatMessage(messages.roomCount, {
          roomCount: numberOfRooms,
          value: numberOfRooms,
          room: this.context.intl.formatMessage(messages.room),
          rooms: this.context.intl.formatMessage(messages.rooms),
        });
        continueButtonLabel = this.context.intl.formatMessage(
          messages.pleaseSelectMore,
          {
            roomCount,
          },
        );
      } else {
        const getMaxNumberOfSupportedAdultsInRoom = room => {
          const as = [];
          room.guest_combination.forEach(combination => {
            as.push(combination.adult);
          });
          return Math.max(...as);
        };

        const getMaxNumberOfSupportedChildrenInRoom = (room, as) => {
          let cs = [0];
          room.guest_combination.some(combination => {
            if (combination.adult === as) {
              cs = combination.child;
              return true;
            }
            return false;
          });
          return Math.max(...cs);
        };
        let supportedAdults = 0;
        let supportedChildren = 0;
        roomTypes.forEach(roomType => {
          if (roomType.numberOfRooms > 0) {
            const a = getMaxNumberOfSupportedAdultsInRoom(roomType.room);
            const c = getMaxNumberOfSupportedChildrenInRoom(roomType.room, a);
            supportedAdults += a * roomType.numberOfRooms;
            supportedChildren += c * roomType.numberOfRooms;
          }
        });
        if (adults > supportedAdults || children > supportedChildren) {
          hasError = true;
          let rc = adults - supportedAdults;
          if (rc < 0) {
            rc = 1;
          }
          const roomCount = this.context.intl.formatMessage(
            messages.roomCount,
            {
              roomCount: rc,
              value: rc,
              room: this.context.intl.formatMessage(messages.room),
              rooms: this.context.intl.formatMessage(messages.rooms),
            },
          );
          continueButtonLabel = this.context.intl.formatMessage(
            messages.pleaseSelectMore,
            {
              roomCount,
            },
          );
        }
      }
    }
    if (usingIn === PS_PAYMENT && booking.isSubmittingBooking) {
      hasError = booking.errors.length > 0;
      continueButtonLabel = this.context.intl.formatMessage(
        messages.submittingBooking,
      );
    }
    return (
      <div className={cx(s.root, showDetail && s.overlay)}>
        {!showDetail && (
          <div>
            <div>
              <h4>
                {is_birthday_remembered ? (
                  <FormattedMessage
                    {...messages.totalBirthday}
                    values={{
                      price: (
                        <CurrencyDisplay
                          currency={booking.hotel.currency}
                          value={totalMember}
                        />
                      ),
                    }}
                  />
                ) : (
                  <FormattedMessage
                    {...messages.total}
                    values={{
                      price: (
                        <CurrencyDisplay
                          currency={booking.hotel.currency}
                          value={joinRemembered ? totalMember : totalNormal}
                        />
                      ),
                    }}
                  />
                )}
                <button
                  type="button"
                  className={s.detail}
                  onClick={this.onClickDetail}
                >
                  <FormattedMessage {...messages.details} />
                </button>
              </h4>
            </div>
            <div>
              <Button
                label={continueButtonLabel}
                onClick={this.onClick}
                disabled={
                  !initialized || hasError || booking.isSubmittingBooking
                }
              />
            </div>
          </div>
        )}
        {showDetail && (
          <div>
            <button
              type="button"
              className={s.close}
              onClick={this.onClickClose}
            >
              ×
            </button>
            <PriceSummary
              usingIn={usingIn}
              onClickButton={this.onClick}
              onClickEdit={this.onClickClose}
            />
          </div>
        )}
      </div>
    );
  }
}

StickyPriceSummaryBox.propTypes = {
  usingIn: PropTypes.oneOf([PS_ROOM_SELECT, PS_GUEST_PROFILE, PS_PAYMENT]),
  user: UserShape,
  booking: BookingShape,
  onClick: PropTypes.func.isRequired,
};

StickyPriceSummaryBox.contextTypes = {
  intl: intlShape.isRequired,
};

StickyPriceSummaryBox.defaultProps = {
  usingIn: PS_ROOM_SELECT,
  user: null,
  booking: null,
};

const mapState = state => ({
  user: state.user,
  booking: state.booking,
});

export default connect(mapState)(withStyles(s)(StickyPriceSummaryBox));
