// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".WhiteRoundedBox-root {\n  padding: 50px;\n  background-color: #fff;\n  border-radius: 36px;\n  border: 1px #979797 solid;\n  -webkit-box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);\n          box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);\n}\n\n.WhiteRoundedBox-root::after {\n  content: '';\n  display: table;\n  clear: both;\n}\n\n@media only screen and (min-width: 270px) {\n  .WhiteRoundedBox-root {\n    padding: 0;\n    background-color: transparent;\n    border-radius: 0;\n    border: none;\n    -webkit-box-shadow: none;\n            box-shadow: none;\n  }\n}\n\n@media only screen and (min-width: 768px) {\n  .WhiteRoundedBox-root {\n    padding: 0;\n    background-color: transparent;\n    border-radius: 0;\n    border: none;\n    -webkit-box-shadow: none;\n            box-shadow: none;\n  }\n}\n\n@media only screen and (min-width: 992px) {\n  .WhiteRoundedBox-root {\n    padding: 50px;\n    background-color: #fff;\n    border-radius: 36px;\n    border: 1px #979797 solid;\n    -webkit-box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);\n            box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);\n  }\n}\n\n@media only screen and (min-width: 1280px) {\n  .WhiteRoundedBox-root {\n    padding: 50px;\n    background-color: #fff;\n    border-radius: 36px;\n    border: 1px #979797 solid;\n    -webkit-box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);\n            box-shadow: 2px 2px 20px 5px rgba(0, 0, 0, 0.2);\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"root": "WhiteRoundedBox-root"
};
module.exports = exports;
