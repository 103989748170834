/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';

class CDNImage extends React.Component {
  static propTypes = {
    path: PropTypes.string,
    cdnUrl: PropTypes.string,
    image: PropTypes.shape({
      normal: PropTypes.string.isRequired,
      add2x: PropTypes.string.isRequired,
    }).isRequired,
    alt: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
  };

  static contextTypes = {
    cdnUrl: PropTypes.string.isRequired,
  };

  static defaultProps = {
    cdnUrl: '',
    path: '',
    alt: '',
    width: '',
    height: '',
  };

  render() {
    const { path, alt } = this.props;
    let {
      cdnUrl,
      image: { normal, add2x },
    } = this.props;
    if (!cdnUrl) {
      cdnUrl = this.context.cdnUrl;
    }
    normal = `${cdnUrl}${path}${normal}`;
    add2x = `${cdnUrl}${path}${add2x} 2x`;
    return (
      <img
        src={normal}
        srcSet={add2x}
        alt={alt}
        width={this.props.width}
        height={this.props.height}
      />
    );
  }
}

export default CDNImage;
