/**
 * Created by methee on 8/28/17.
 */

/* eslint-disable camelcase */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import moment from 'moment';
import cx from 'classnames';

import { connect } from 'react-redux';
import TextBox from '../TextBox';
import Dropdown from '../Dropdown';
import BirthdayField from '../BirthdayField';
import PhoneInput from '../PhoneInput';
import CountryType from '../../data/types/CountryType';
import { isEmail } from '../../utils';

import s from './SignUpForm.css';
import iconGlobe from './icon-globe.webp';
import iconGlobeAdd2x from './icon-globe@2x.webp';
import iconEmail from './icon-email.webp';
import iconEmailAdd2x from './icon-email@2x.webp';
import iconLock from './icon-lock.webp';
import iconLockAdd2x from './icon-lock@2x.webp';

const messages = defineMessages({
  name: {
    id: 'signup.name',
    defaultMessage: '##Name##',
    description: "Label 'Name'",
  },
  title: {
    id: 'signup.title',
    defaultMessage: '##Title##',
    description: "Label 'Title'",
  },
  errorTitle: {
    id: 'signup.errorTitle',
    defaultMessage: 'Error Name Title',
    description: "Error name title indicator'",
  },
  firstName: {
    id: 'signup.first_name',
    defaultMessage: '##First name##',
    description: "Label 'First Name'",
  },
  errorFirstName: {
    id: 'signup.errorFirstName',
    defaultMessage: '##Error First Name##',
    description: "Error first name indicator'",
  },
  lastName: {
    id: 'signup.last_name',
    defaultMessage: '##Last name##',
    description: "Label 'Last name'",
  },
  errorLastName: {
    id: 'signup.errorLastName',
    defaultMessage: '##Error Last Name##',
    description: "Error last name indicator'",
  },
  birthday: {
    id: 'signup.birthday',
    defaultMessage: '##Birthday##',
    description: "Label 'Birthday'",
  },
  countryOfResidence: {
    id: 'signup.country_of_residence',
    defaultMessage: '##Country of Residence##',
    description: "Label 'Country of Residence'",
  },
  errorCountryOfResidence: {
    id: 'signup.errorCountryOfResidence',
    defaultMessage: '##Error Country of Residence##',
    description: "Error Country of Residence indicator'",
  },
  country: {
    id: 'signUpForm.country',
    defaultMessage: '##Country##',
    description: "Label 'Country'",
  },
  preferredLanguage: {
    id: 'signup.preferredLanguage',
    defaultMessage: 'Preferred language',
    description: "Label 'Preferred Language'",
  },
  errorPreferredLanguage: {
    id: 'signup.errorPreferredLanguage',
    defaultMessage: '##Error Preferred Language##',
    description: "Error Preferred Language indicator'",
  },
  phone: {
    id: 'signUpForm.phone',
    defaultMessage: '##Phone##',
    description: "Label 'Phone'",
  },
  errorPhone: {
    id: 'signup.errorPhone',
    defaultMessage: '##Error Phone##',
    description: "Error Phone indicator'",
  },
  email: {
    id: 'signUpForm.email',
    defaultMessage: '##Email##',
    description: "Label 'Email'",
  },
  errorEmail: {
    id: 'signup.errorEmail',
    defaultMessage: '##Error Email##',
    description: "Error Email indicator'",
  },
  password: {
    id: 'signUpForm.password',
    defaultMessage: '##Password##',
    description: "Label 'Password'",
  },
  errorPassword: {
    id: 'signup.errorPassword',
    defaultMessage: '##Error Password##',
    description: "Error Password indicator'",
  },
});

const isError = (errors, field) =>
  errors.length > 0 && errors.indexOf(field) > -1;

class SignUpForm extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    countries: PropTypes.arrayOf(CountryType.shape()).isRequired,
    onFormUpdated: PropTypes.func.isRequired,
    profile: PropTypes.shape({
      nameTitle: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      birthday: PropTypes.instanceOf(moment),
      country: PropTypes.string,
      preferLang: PropTypes.string,
      phoneCountry: PropTypes.object,
      email: PropTypes.string,
      password: PropTypes.string,
    }),
    fullForm: PropTypes.bool,
    showPassword: PropTypes.bool,
    compact: PropTypes.bool,
    onSubmit: PropTypes.func,
    errors: PropTypes.arrayOf(PropTypes.string),
  };

  // eslint-disable-next-line react/sort-comp,react/static-property-placement
  static defaultProps = {
    profile: {
      nameTitle: 0,
      firstName: '',
      lastName: '',
      birthday: null,
      country: '',
      preferLang: 'en',
      phoneCountry: null,
      email: '',
      password: '',
      inputField: '',
    },
    fullForm: true,
    showPassword: false,
    compact: false,
    onSubmit: () => {},
    // eslint-disable-next-line react/default-props-match-prop-types
    errorMessage: '',
    errors: [],
  };

  // eslint-disable-next-line react/static-property-placement
  static contextTypes = {
    intl: intlShape,
    fetch: PropTypes.func,
    apiUrl: PropTypes.string,
  };

  constructor(props) {
    super(props);
    const { profile } = props;
    this.state = {
      profile,
      userLocation: {
        country_code: '',
        ip: '',
      },
      nameTitles: [],
      preferredLanguages: [],
    };
  }

  // eslint-disable-next-line react/no-deprecated
  async componentDidMount() {
    if (process.env.BROWSER) {
      const { apiUrl, intl } = this.context;
      const response = await this.context.fetch(`${apiUrl}user_location`);
      const dt = await response.json();
      this.setState({ userLocation: dt.data });
      if (!this.props.profile.country) {
        this.setCountry(dt.data.country_code);
      }

      const nameTitleResponse = await this.context.fetch(
        `${apiUrl}${intl.locale}/name_title`,
      );
      const nameTitles = await nameTitleResponse.json();
      this.setState({ nameTitles: nameTitles.data });
      const { profile } = this.state;
      const nameTitle = profile.nameTitle ? profile.nameTitle : 0;
      profile.nameTitle = nameTitle;
      this.setState({ profile });

      const preferredLanguagesResponse = await this.context.fetch(
        `${apiUrl}${intl.locale}/prefer_lang`,
      );
      const preferredLanguages = await preferredLanguagesResponse.json();
      const dataPreferredLanguages = preferredLanguages.data;
      const dataArray = [];
      const keys = Object.keys(dataPreferredLanguages);
      keys.forEach(key => {
        dataArray.push({
          value: key,
          name: dataPreferredLanguages[key],
        });
      });
      this.setState({ preferredLanguages: dataArray });
      const preferLang = profile.preferLang ? profile.preferLang : 'en';
      profile.preferLang = preferLang;
      this.setState({ profile });
    }
  }

  // eslint-disable-next-line react/no-deprecated
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.profile) {
      this.setState({ profile: nextProps.profile });
    }
  }

  onSelectedNameTitle = nameTitle => {
    this.setNameTitle(parseInt(nameTitle, 10));
    const { profile } = this.state;
    this.props.onFormUpdated(profile);
  };

  setNameTitle = nameTitle => {
    const { profile } = this.state;
    profile.nameTitle = nameTitle;
    this.setState({ profile });
  };

  onSelectedPreferredLanguage = preferLang => {
    this.setPreferredLanguage(preferLang);
    const { profile } = this.state;
    this.props.onFormUpdated(profile);
  };

  setPreferredLanguage = preferLang => {
    const { profile } = this.state;
    profile.preferLang = preferLang;
    this.setState({ profile });
  };

  onFirstNameChange = firstName => {
    const { profile } = this.state;
    profile.firstName = firstName;
    profile.inputField = 'firstName';
    this.setState({ profile });
    this.props.onFormUpdated(profile);
  };

  onLastNameChange = lastName => {
    const { profile } = this.state;
    profile.lastName = lastName;
    profile.inputField = 'lastName';
    this.setState({ profile });
    this.props.onFormUpdated(profile);
  };

  onSelectedBirthday = value => {
    const { profile } = this.state;
    profile.birthday = moment(value);
    profile.inputField = 'email';
    this.setState({ profile });
    this.props.onFormUpdated(profile);
  };

  onSelectedCountry = country => {
    this.setCountry(country);
    const { profile } = this.state;
    this.props.onFormUpdated(profile);
  };

  setCountry = country => {
    const { profile } = this.state;
    profile.country = country;
    this.setState({ profile });
  };

  onPhoneNumberChange = phoneCountry => {
    const { profile } = this.state;
    // const { booking } = this.props;

    // if(booking.profile.phoneCountry && booking.profile.phoneCountry.phone && phoneCountry.phone === '')
    // return;

    const currentPhoneCountry = profile.phoneCountry;
    profile.phoneCountry = phoneCountry;

    profile.inputField = 'phone';
    this.setState({ profile });
    if (currentPhoneCountry !== null) {
      this.props.onFormUpdated(profile);
    }
  };

  onEmailChange = email => {
    const { profile } = this.state;
    profile.email = email;
    profile.inputField = 'email';
    this.setState({ profile });
    this.props.onFormUpdated(profile);
  };

  onPasswordChange = password => {
    const { profile } = this.state;
    profile.password = password;
    this.setState({ profile });
    this.props.onFormUpdated(profile);
  };

  onSubmit = event => {
    this.props.onSubmit(event);
  };

  render() {
    const { countries, fullForm, showPassword, compact, errors } = this.props;
    const {
      profile: {
        nameTitle,
        firstName,
        lastName,
        birthday,
        country,
        preferLang,
        phoneCountry,
        email,
        password,
      },
      nameTitles,
      preferredLanguages,
      userLocation: { country_code },
    } = this.state;
    const countryOfResidence = [
      {
        value: '',
        name: this.context.intl.formatMessage(messages.country),
      },
    ];
    countries.forEach(c => {
      countryOfResidence.push({
        value: c.code,
        name: c.name,
      });
    });

    const titles = [
      {
        value: 0,
        name: 'Select',
      },
    ];
    if (nameTitles) {
      nameTitles.forEach(c => {
        titles.push({
          value: c.id,
          name: c.name,
        });
      });
    }
    const languages = [];
    if (preferredLanguages && preferredLanguages.length > 0) {
      preferredLanguages.forEach(c => {
        languages.push({
          value: c.value,
          name: c.name,
        });
      });
    }

    return (
      <form
        className={cx(
          s.root,
          compact && s.compact,
          s[this.context.intl.locale],
        )}
        onSubmit={this.onSubmit}
      >
        {fullForm && (
          <div>
            <div
              className={cx(s.label, isError(errors, 'nameTitle') && s.error)}
            >
              <FormattedMessage {...messages.title} />
            </div>
            <div id="nameTitle" className={s.formGroup}>
              <div className={s.col12}>
                <Dropdown
                  onSelectedValue={this.onSelectedNameTitle}
                  options={titles}
                  firstOptionIsPlaceholder={false}
                  value={nameTitle}
                  shadow
                  errorMessage={this.context.intl.formatMessage(
                    messages.errorTitle,
                  )}
                  isError={isError(errors, 'nameTitle')}
                  showFloatingLabel={false}
                />
              </div>
            </div>
          </div>
        )}
        <div
          className={cx(
            s.label,
            (isError(errors, 'firstName') || isError(errors, 'lastName')) &&
              s.error,
          )}
        >
          <FormattedMessage {...messages.name} />
        </div>
        <div className={s.formGroup}>
          <div className={s.col6}>
            <TextBox
              placeholder={this.context.intl.formatMessage(messages.firstName)}
              value={firstName}
              onChange={this.onFirstNameChange}
              shadow
              errorMessage={this.context.intl.formatMessage(
                messages.errorFirstName,
              )}
              isError={isError(errors, 'firstName')}
            />
          </div>
          <div className={s.col6}>
            <TextBox
              placeholder={this.context.intl.formatMessage(messages.lastName)}
              value={lastName}
              onChange={this.onLastNameChange}
              shadow
              errorMessage={this.context.intl.formatMessage(
                messages.errorLastName,
              )}
              isError={isError(errors, 'lastName')}
            />
          </div>
        </div>
        {fullForm && (
          <div>
            <div
              className={cx(s.label, isError(errors, 'birthday') && s.error)}
            >
              <FormattedMessage {...messages.birthday} />
            </div>
            <div className={s.formGroup}>
              <BirthdayField
                onChange={this.onSelectedBirthday}
                birthday={birthday}
                isError={isError(errors, 'birthday')}
              />
            </div>
            <div className={cx(s.label, isError(errors, 'country') && s.error)}>
              <FormattedMessage {...messages.countryOfResidence} />
            </div>
            <div id="country" className={s.formGroup}>
              <div className={s.col12}>
                <Dropdown
                  icon={{
                    normal: iconGlobe,
                    add2x: iconGlobeAdd2x,
                  }}
                  onSelectedValue={this.onSelectedCountry}
                  options={countryOfResidence}
                  value={country || country_code} // eslint-disable-line camelcase
                  shadow
                  errorMessage={this.context.intl.formatMessage(
                    messages.errorCountryOfResidence,
                  )}
                  isError={isError(errors, 'country')}
                  showFloatingLabel={false}
                />
              </div>
            </div>
            {languages.length > 0 && (
              <div>
                <div
                  className={cx(
                    s.label,
                    isError(errors, 'preferLang') && s.error,
                  )}
                >
                  <FormattedMessage {...messages.preferredLanguage} />
                </div>
                <div id="preferLang" className={s.formGroup}>
                  <div className={s.col12}>
                    <Dropdown
                      onSelectedValue={this.onSelectedPreferredLanguage}
                      options={languages}
                      firstOptionIsPlaceholder={false}
                      value={preferLang}
                      shadow
                      errorMessage={this.context.intl.formatMessage(
                        messages.errorPreferredLanguage,
                      )}
                      isError={isError(errors, 'preferLang')}
                      showFloatingLabel={false}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={cx(s.label, isError(errors, 'phone') && s.error)}>
          <FormattedMessage {...messages.phone} />
        </div>
        <div className={s.formGroup}>
          <div className={s.col12}>
            <PhoneInput
              onChange={this.onPhoneNumberChange}
              phoneNumber={(phoneCountry && phoneCountry.phone) || ''}
              errorMessage={this.context.intl.formatMessage(
                messages.errorPhone,
              )}
              isError={isError(errors, 'phone')}
              country={(phoneCountry && phoneCountry.country) || country_code}
            />
          </div>
        </div>
        <div className={cx(s.label, isError(errors, 'email') && s.error)}>
          <FormattedMessage {...messages.email} />
        </div>
        <div id="email" className={s.formGroup}>
          <div className={s.col12}>
            <TextBox
              icon={{
                normal: iconEmail,
                add2x: iconEmailAdd2x,
              }}
              inputType="email"
              placeholder={this.context.intl.formatMessage(messages.email)}
              value={email}
              onChange={this.onEmailChange}
              validation={value => isEmail(value)}
              shadow
              errorMessage={this.context.intl.formatMessage(
                messages.errorEmail,
              )}
              isError={isError(errors, 'email')}
              showFloatingLabel={false}
            />
          </div>
        </div>
        {showPassword && (
          <div>
            <div
              className={cx(s.label, isError(errors, 'password') && s.error)}
            >
              <FormattedMessage {...messages.password} />
            </div>
            <div className={s.formGroup}>
              <div className={s.col12}>
                <TextBox
                  icon={{
                    normal: iconLock,
                    add2x: iconLockAdd2x,
                  }}
                  inputType="password"
                  placeholder={this.context.intl.formatMessage(
                    messages.password,
                  )}
                  value={password}
                  onChange={this.onPasswordChange}
                  shadow
                  errorMessage={this.context.intl.formatMessage(
                    messages.errorPassword,
                  )}
                  isError={isError(errors, 'password')}
                  showFloatingLabel={false}
                />
              </div>
            </div>
          </div>
        )}
        <input type="submit" style={{ display: 'none' }} />
      </form>
    );
  }
}

const mapState = state => ({
  // booking: state.booking,
  user: state.user,
});

export default connect(mapState)(withStyles(s)(SignUpForm));
