/* eslint-disable react/static-property-placement */
/**
 * Created by methee on 8/29/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import googleLibPhone from 'google-libphonenumber';

import s from './PhoneInput.css';

const preferredCountries = ['ph', 'id', 'jp', 'th'];

class PhoneInput extends React.Component {
  static propTypes = {
    onChange: PropTypes.func,
    phoneNumber: PropTypes.string,
    country: PropTypes.string,
    errorMessage: PropTypes.string,
    isError: PropTypes.bool,
    isSuccess: PropTypes.bool,
  };

  static defaultProps = {
    onChange: () => {},
    phoneNumber: '',
    country: 'auto',
    errorMessage: '',
    isError: false,
    isSuccess: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: props.phoneNumber,
      isError: props.isError,
      isSuccess: props.isSuccess,
    };
  }

  componentDidMount() {
    const { country } = this.props;
    /* eslint-disable no-undef */
    $(this.phone).intlTelInput({
      autoFormat: false,
      autoHideDialCode: false,
      autoPlaceholder: 'aggressive',
      initialCountry: country.toLowerCase(),
      nationalMode: true,
      numberType: 'MOBILE',
      preferredCountries,
      responsiveDropdown: false,
      geoIpLookup: async callback => {
        const response = await this.context.fetch(`${apiUrl}user_location`);
        const data = await response.json();
        callback(data.data.country_code);
      },
    });
    $(this.phone).on('countrychange', () => {
      this.onChange({
        target: {
          value: '',
        },
      });
    });
    /* eslint-enable no-undef */
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState({
      isError: nextProps.isError,
      isSuccess: nextProps.isSuccess,
    });
    if (nextProps.phoneNumber) {
      const phoneUtil = googleLibPhone.PhoneNumberUtil.getInstance();
      try {
        phoneUtil.parse(nextProps.phoneNumber);
      } catch (e) {
        if (nextProps.country) {
          /* eslint-disable no-undef */
          $(this.phone).intlTelInput(
            'setCountry',
            nextProps.country.toLowerCase(),
          );
          /* eslint-enable no-undef */
        }
      }
      this.setState({
        phoneNumber: nextProps.phoneNumber,
      });
      $(this.phone).intlTelInput('setNumber', nextProps.phoneNumber); // eslint-disable-line no-undef
    } else if (nextProps.country) {
      $(this.phone).intlTelInput('setCountry', nextProps.country.toLowerCase()); // eslint-disable-line no-undef
    }
  }

  onChange = event => {
    /* eslint-disable no-undef */
    const { onChange } = this.props;
    const phoneRegex = /^([0-9]|\(|\)|\+|-|\s)*$/;
    if (phoneRegex.test(event.target.value)) {
      this.setState({ phoneNumber: event.target.value });
      onChange({
        phone: event.target.value,
        country: $(this.phone).intlTelInput('getSelectedCountryData').iso2,
      });
    }
    /* eslint-enable no-undef */
  };

  onBlur = () => {
    /* eslint-disable no-undef */
    if (!$(this.phone).intlTelInput('isValidNumber')) {
      this.setState({ isError: true });
    }
    /* eslint-enable no-undef */
  };

  render() {
    const { errorMessage } = this.props;
    const { phoneNumber, isError, isSuccess } = this.state;
    return (
      <div className={s.root}>
        <input
          ref={phone => {
            this.phone = phone;
          }}
          id="phone"
          type="tel"
          className={s.input}
          onChange={this.onChange}
          onBlur={this.onBlur}
          defaultValue={phoneNumber}
        />
        {(isError || isSuccess) && (
          <div
            className={cx(s.border, isError && s.error, isSuccess && s.success)}
          />
        )}
        {isError && errorMessage && (
          <small className={s.errorMessage}>{errorMessage}</small>
        )}
      </div>
    );
  }
}

export default withStyles(s)(PhoneInput);
