// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.BookingProgressHeader-root {\n  margin: 15px 0;\n  font-family: 'Lemon Sans Cnd Uni', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n  font-size: 4.2em;\n  line-height: 0.6em;\n  text-transform: uppercase;\n  pointer-events: none;\n}\n\n.BookingProgressHeader-root.BookingProgressHeader-ja,\n.BookingProgressHeader-root.BookingProgressHeader-id,\n.BookingProgressHeader-root.BookingProgressHeader-ko,\n.BookingProgressHeader-root.BookingProgressHeader-zh-hans,\n.BookingProgressHeader-root.BookingProgressHeader-zh-hant,\n.BookingProgressHeader-root.BookingProgressHeader-th {\n  font-size: 2.2em;\n  line-height: normal;\n}\n\n.BookingProgressHeader-root .BookingProgressHeader-highlight {\n  color: #da291c;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "BookingProgressHeader-root",
	"ja": "BookingProgressHeader-ja",
	"id": "BookingProgressHeader-id",
	"ko": "BookingProgressHeader-ko",
	"zh-hans": "BookingProgressHeader-zh-hans",
	"zh-hant": "BookingProgressHeader-zh-hant",
	"th": "BookingProgressHeader-th",
	"highlight": "BookingProgressHeader-highlight"
};
module.exports = exports;
