/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */

import { OPEN_MEETINGROOM_MODAL, CLOSE_MEETINGROOM_MODAL } from '../constants';

export function openMeetingRoomModal(link) {
  return async dispatch => {
    dispatch({
      type: OPEN_MEETINGROOM_MODAL,
      payload: link,
    });
  };
}

export function closeMeetingRoomModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_MEETINGROOM_MODAL,
    });
  };
}
