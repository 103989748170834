/**
 * Created by methee on 8/25/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { FormattedNumber } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './CurrencyDisplay.css';

class CurrencyDisplay extends React.Component {
  static propTypes = {
    value: PropTypes.number.isRequired,
    type: PropTypes.string,
    currency: PropTypes.string.isRequired,
    className: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    type: null,
  };

  render() {
    const { value, currency, className, type } = this.props;
    if (type === 'free') {
      return (
        <span className={cx(s.root, className)}>
          <b>{type.toLowerCase()}</b>
        </span>
      );
    }
    return (
      <span className={cx(s.root, className)}>
        <b>{currency.toLowerCase()}</b>
        &nbsp;
        <FormattedNumber
          value={value}
          minimumFractionDigits={0}
          maximumFractionDigits={0}
        />
      </span>
    );
  }
}

export default withStyles(s)(CurrencyDisplay);
