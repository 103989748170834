/**
 * Created by methee on 8/21/17.
 */

/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import { setRuntimeVariable } from '../../actions/runtime';
import Button from '../Button';

import iconLeft from './icon-arrow-left.svg';
import s from './ImageGalleryModal.css';

const messages = defineMessages({
  close: {
    id: 'imageGallery.close',
    defaultMessage: '##Close X##',
    description: 'Close button of image gallery',
  },
});

class ImageGalleryModal extends React.Component {
  static propTypes = {
    setRuntimeVariable: PropTypes.func,
    images: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    currentIndex: PropTypes.number,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    setRuntimeVariable: () => {},
    images: [],
    currentIndex: 0,
  };

  constructor(props) {
    super(props);
    const { images, currentIndex } = props;
    this.state = {
      selectedImage: images[currentIndex],
      currentScrollY: window.pageYOffset,
      currentIndex,
    };
  }

  onClickGalleryControl(direction) {
    this.controlClicked = true;
    const { images } = this.props;
    let { currentIndex } = this.state;
    currentIndex += direction;
    if (currentIndex < 0) {
      currentIndex = images.length - 1;
    }
    if (currentIndex >= images.length) {
      currentIndex = 0;
    }
    this.setState({
      selectedImage: images[currentIndex],
      currentIndex,
    });
  }

  onCloseModel = () => {
    this.props.setRuntimeVariable({
      name: 'imageGallery',
      value: {
        visible: false,
      },
    });
    window.scrollTo(0, this.state.currentScrollY);
  };

  render() {
    const { selectedImage } = this.state;
    return (
      <div className={s.root}>
        <div className={s.background} onClick={this.onCloseModel} />
        <div className={s.content}>
          <img src={selectedImage} alt={selectedImage} className={s.image} />
          <Button
            className={s.closeButton}
            onClick={this.onCloseModel}
            label={this.context.intl.formatMessage(messages.close)}
          />
          <button
            className={cx(s.arrow, s.left)}
            onClick={() => {
              this.onClickGalleryControl(-1);
            }}
          >
            <img src={iconLeft} alt={'left'} />
          </button>
          <button
            className={cx(s.arrow, s.right)}
            onClick={() => {
              this.onClickGalleryControl(+1);
            }}
          >
            <img src={iconLeft} alt={'right'} />
          </button>
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  setRuntimeVariable,
};

export default connect(
  null,
  mapDispatch,
)(withStyles(s)(ImageGalleryModal));
