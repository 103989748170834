import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './CarouselImagesController.css';

const imageRotationTime = 6000;

class CarouselImagesController extends React.Component {
  timeoutPromise = null;

  constructor(props) {
    super(props);
    this.state = { selectedImage: props.selectedImage, isClicked: false };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      this.timeoutPromise = setTimeout(
        this.autoImageRotation,
        imageRotationTime,
      );
    }
  }

  componentWillUnmount() {
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
  }

  onClick = index => {
    this.props.onSelectedImage(index);
    this.setState({ selectedImage: index, isClicked: true });
  };

  autoImageRotation = () => {
    if (!this.state.isClicked) {
      const { images } = this.props;
      const { selectedImage } = this.state;
      let nextIndex = selectedImage + 1;
      if (nextIndex >= images.length) {
        nextIndex = 0;
      }
      this.props.onSelectedImage(nextIndex);
      this.setState({ selectedImage: nextIndex });
      this.timeoutPromise = setTimeout(
        this.autoImageRotation,
        imageRotationTime,
      );
    }
  };

  render() {
    const { images, selectedImage } = this.props;
    return (
      <div className={s.root}>
        <ul>
          {images.map((cover, index) => (
            <li
              key={cover.image}
              className={cx(index === selectedImage && s.select)}
            >
              <button
                id={index}
                aria-label="button $index"
                aria-labelledby="labeldiv"
                type="button"
                onClick={() => {
                  this.onClick(index);
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

CarouselImagesController.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
    }),
  ),
  selectedImage: PropTypes.number,
  onSelectedImage: PropTypes.func,
};

CarouselImagesController.defaultProps = {
  images: [],
  selectedImage: 0,
  onSelectedImage: () => {},
};

export default withStyles(s)(CarouselImagesController);
