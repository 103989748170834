/* eslint-disable react/jsx-curly-newline */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { defineMessages, intlShape, FormattedMessage } from 'react-intl';
import { DateRangePicker } from 'react-dates';
import {
  START_DATE,
  END_DATE,
  VERTICAL_SCROLLABLE,
  HORIZONTAL_ORIENTATION,
} from 'react-dates/constants';
import { connect } from 'react-redux';

import Options from './Options';
import s from './BookingEngineSmall.css';
import iconGlobe from './icon-globe.webp';
import iconGlobeAdd2x from './icon-globe@2x.webp';
import iconLocation from './icon-location.webp';
import iconLocationAdd2x from './icon-location@2x.webp';
import iconCalendar from './icon-calendar.webp';
import iconCalendarAdd2x from './icon-calendar@2x.webp';
import { addFlashMessage, TYPE_ERROR } from '../../actions/flashMessage';
import { onTrackingPageView } from '../../lib/fbpixel';

const messages = defineMessages({
  checkin: {
    id: 'booking_engine.check_in',
    defaultMessage: '##Check in##',
    description: 'Placeholder text for check in textbox on room-select engine',
  },
  checkout: {
    id: 'booking_engine.check_out',
    defaultMessage: '##Check out##',
    description: 'Placeholder text for check out textbox on room-select engine',
  },
  country: {
    id: 'booking_engine.country',
    defaultMessage: '##Country##',
    description: 'Placeholder for document dropdown on Booking engine',
  },
  city: {
    id: 'booking_engine.city',
    defaultMessage: '##City##',
    description: 'Placeholder for city dropdown on Booking engine',
  },
  pleaseSelectStayDates: {
    id: 'booking_engine.pleaseSelectStayDates',
    defaultMessage: '##Please select your dates.##',
    description:
      'Error message when user click continue without selecting check in / check out',
  },
  pleaseSelectCountry: {
    id: 'booking_engine.please_select_country',
    defaultMessage: '##Please select document first##',
    description: 'Error message please select document',
  },
  allCountries: {
    id: 'booking_engine.all_countries',
    defaultMessage: '##All Countries##',
    description: 'Menu to select all countries',
  },
  allCities: {
    id: 'booking_engine.all_cities',
    defaultMessage: '##All Cities##',
    description: 'Menu to select all cities',
  },
});

const maximumNights = 30;
const dateFormat = 'D MMM YYYY';
const minimumDate = moment().subtract(1, 'd');
const maximumDate = minimumDate
  .clone()
  .add(1, 'year')
  .endOf('month');
let oldCheckIn = null;
let oldCheckout = null;

let cookies = null;
let domain = '';
if (process.env.BROWSER) {
  cookies = new Cookies();
  const { hostname } = window.location;
  const paths = hostname.split('.');
  domain = paths.slice(paths.length - 2, paths.length).join('.');
}

class BookingEngineSmall extends React.Component {
  static propTypes = {
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    ),
    selectedCountry: PropTypes.number,
    selectedCity: PropTypes.number,
    checkIn: PropTypes.instanceOf(moment),
    checkOut: PropTypes.instanceOf(moment),
    isMobileDevice: PropTypes.bool.isRequired,
    addFlashMessage: PropTypes.func,
    onUpdatedSearch: PropTypes.func,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    locations: [],
    selectedCountry: 0,
    selectedCity: 0,
    checkIn: moment(),
    checkOut: moment().add(1, 'day'),
    addFlashMessage: () => {},
    onUpdatedSearch: () => {},
  };

  constructor(props) {
    super(props);
    const { selectedCity, checkIn, checkOut, locations } = props;
    let { selectedCountry } = props;
    oldCheckIn = checkIn;
    oldCheckout = checkOut;
    if (!selectedCountry && selectedCity && locations) {
      locations.forEach(country => {
        country.children.forEach(city => {
          if (city.id === parseInt(selectedCity, 10)) {
            selectedCountry = country.id;
          }
        });
      });
    }
    this.state = {
      focusedInput: null,
      selectedCountry,
      selectedCity,
      checkIn,
      checkOut,
      mouseOverCountry: false,
      mouseOverCity: false,
      showCountry: false,
      showCity: false,
    };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      const elems = document.getElementsByClassName('DateRangePickerInput');
      elems[0].style.display = 'none';
    }
  }

  componentDidUpdate() {
    const { selectedCountry, selectedCity, checkIn, checkOut } = this.state;
    if (this.pushHistory) {
      this.pushHistory = false;
      this.props.onUpdatedSearch(
        selectedCountry,
        selectedCity,
        checkIn,
        checkOut,
      );
    }
  }

  onClickCountry = () => {
    this.setState({ showCountry: true, showCity: false });
    const self = this;
    const onClick = () => {
      if (!this.state.mouseOverCountry) {
        self.setState({ showCountry: false });
        window.removeEventListener('click', onClick);
      }
    };
    window.addEventListener('click', onClick);
  };

  onClickCity = () => {
    if (!this.state.selectedCountry) {
      this.props.addFlashMessage(
        this.context.intl.formatMessage(messages.pleaseSelectCountry),
        TYPE_ERROR,
      );
      return;
    }
    this.setState({ showCity: true });
    const self = this;
    const onClick = () => {
      if (!this.state.mouseOverCity) {
        self.setState({ showCity: false });
        window.removeEventListener('click', onClick);
      }
    };
    window.addEventListener('click', onClick);
  };

  onClickDate = () => {
    this.setState({ focusedInput: START_DATE });
  };

  onMouseOverCountry = () => {
    this.setState({ mouseOverCountry: true });
  };

  onMouseOverCity = () => {
    this.setState({ mouseOverCity: true });
  };

  onMouseLeaveCountry = () => {
    this.setState({ mouseOverCountry: false });
  };

  onMouseLeaveCity = () => {
    this.setState({ mouseOverCity: false });
  };

  onSelectedCountry = value => {
    const selectedCity = 0;
    const self = this;
    this.setState({
      showCountry: false,
      selectedCountry: value,
      showCity: true,
      selectedCity,
    });
    setTimeout(() => {
      const onClick = () => {
        if (!this.state.mouseOverCity) {
          self.setState({ showCity: false });
          window.removeEventListener('click', onClick);
        }
      };
      window.addEventListener('click', onClick);
    }, 100);
    cookies.set('rphl.search.country', value, {
      path: '/',
      domain,
    });
    cookies.remove('rphl.search.city', {
      path: '/',
      domain,
    });
    onTrackingPageView(value, window.App.facebookPixelId);
  };

  onSelectedCity = value => {
    this.pushHistory = true;
    this.setState({ showCity: false, selectedCity: value });
    cookies.set('rphl.search.city', value, {
      path: '/',
      domain,
    });
  };

  onSelectedStayDate = ({ startDate, endDate }) => {
    if (startDate && endDate) {
      this.pushHistory = true;
    }
    this.setState({
      checkIn: startDate,
      checkOut: endDate,
    });
    if (endDate) {
      oldCheckIn = startDate;
      oldCheckout = endDate;
    }
    if (startDate) {
      cookies.set('rphl.search.checkIn', startDate.format('YYYY-MM-DD'), {
        path: '/',
        domain,
      });
    }
    if (endDate) {
      cookies.set('rphl.search.checkOut', endDate.format('YYYY-MM-DD'), {
        path: '/',
        domain,
      });
    }
  };

  onSelectingStayDate = focusedInput => {
    this.setState({
      focusedInput,
    });
    if (!focusedInput) {
      this.setState({
        checkIn: oldCheckIn,
        checkOut: oldCheckout,
      });
    }
  };

  pushHistory = false;

  render() {
    const {
      focusedInput,
      selectedCountry,
      selectedCity,
      checkIn,
      checkOut,
      showCountry,
      showCity,
    } = this.state;
    const { locations, isMobileDevice } = this.props;
    const countries = [
      {
        value: 0,
        name: this.context.intl.formatMessage(messages.allCountries),
      },
    ];
    if (locations) {
      locations.map(country =>
        countries.push({
          value: country.id,
          name: country.name,
        }),
      );
    }
    const cities = [
      {
        value: 0,
        name: this.context.intl.formatMessage(messages.allCities),
      },
    ];
    let countryName = '';
    let cityName = '';
    if (selectedCountry && locations) {
      locations.forEach(country => {
        if (country.id === parseInt(selectedCountry, 10)) {
          countryName = country.name;
          country.children.forEach(city => {
            if (city.id === parseInt(selectedCity, 10)) {
              cityName = city.name;
            }
            cities.push({
              value: city.id,
              name: city.name,
            });
          });
        }
      });
    } else if (!selectedCountry && selectedCity && locations) {
      locations.forEach(country => {
        country.children.forEach(city => {
          if (city.id === parseInt(selectedCity, 10)) {
            countryName = country.name;
            cityName = city.name;
          }
          cities.push({
            value: city.id,
            name: city.name,
          });
        });
      });
    }
    const orientation = isMobileDevice
      ? VERTICAL_SCROLLABLE
      : HORIZONTAL_ORIENTATION;
    const monthsCount = isMobileDevice ? 12 : 2;
    const withFullScreenPortal = isMobileDevice;
    let isOutsideRange;
    if (checkIn) {
      isOutsideRange = day =>
        focusedInput === END_DATE &&
        (day.isBefore(minimumDate) ||
          day.isSameOrAfter(checkIn.clone().add(maximumNights, 'days')));
    }
    return (
      <div className={s.root}>
        <div className={s.container}>
          <div className={s.body}>
            <img
              src={iconGlobe}
              srcSet={`${iconGlobeAdd2x} 2x`}
              alt="icon globe"
            />
            <span
              className={s.options}
              onMouseOver={this.onMouseOverCountry}
              onMouseLeave={this.onMouseLeaveCountry}
            >
              <span className={s.button} onClick={this.onClickCountry}>
                {countryName || <FormattedMessage {...messages.allCountries} />}
              </span>
              <Options
                options={countries}
                show={showCountry}
                onChange={this.onSelectedCountry}
              />
            </span>
            <img
              className={s.options}
              src={iconLocation}
              srcSet={`${iconLocationAdd2x} 2x`}
              alt="icon globe"
            />
            <span
              className={s.options}
              onMouseOver={this.onMouseOverCity}
              onMouseLeave={this.onMouseLeaveCity}
            >
              <span className={s.button} onClick={this.onClickCity}>
                {cityName || <FormattedMessage {...messages.allCities} />}
              </span>
              <Options
                options={cities}
                show={showCity}
                onChange={this.onSelectedCity}
              />
            </span>
            <span className={s.dates}>
              <img
                src={iconCalendar}
                srcSet={`${iconCalendarAdd2x} 2x`}
                alt="icon calendar"
              />
              <span className={s.dateGroup}>
                <span className={s.button} onClick={this.onClickDate}>
                  {checkIn !== null && checkOut !== null ? (
                    `${checkIn.format('DD MMM')} - ${checkOut.format('DD MMM')}`
                  ) : (
                    <FormattedMessage {...messages.pleaseSelectStayDates} />
                  )}
                </span>
                <span
                  className={s.datePicker}
                  style={{ display: focusedInput ? 'block' : 'none' }}
                >
                  <DateRangePicker
                    id="iconCalendar"
                    aria-labelledby="dateRangePicker"
                    aria-label="dateRangePicker"
                    orientation={orientation}
                    isOutsideRange={isOutsideRange}
                    displayFormat={dateFormat}
                    readOnly
                    hideKeyboardShortcutsPanel
                    showDefaultInputIcon
                    customInputIcon={
                      <img
                        src={iconCalendar}
                        srcSet={`${iconCalendarAdd2x} 2x`}
                        alt="icon"
                      />
                    }
                    startDatePlaceholderText={this.context.intl.formatMessage(
                      messages.checkin,
                    )}
                    endDatePlaceholderText={this.context.intl.formatMessage(
                      messages.checkout,
                    )}
                    numberOfMonths={monthsCount}
                    startDate={checkIn} // momentPropTypes.momentObj or null,
                    endDate={checkOut} // momentPropTypes.momentObj or null,
                    onDatesChange={this.onSelectedStayDate} // PropTypes.func.isRequired,
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={this.onSelectingStayDate}
                    withFullScreenPortal={withFullScreenPortal}
                    isDayBlocked={day =>
                      day.isBefore(minimumDate) || day.isAfter(maximumDate)
                    }
                  />
                </span>
              </span>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  addFlashMessage,
};

export default connect(null, mapDispatch)(withStyles(s)(BookingEngineSmall));
