import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';

import { BookingShape } from '../../actions/booking';
import CurrencyDisplay from '../CurrencyDisplay';
import s from './CheckInTotalPrice.css';

const messages = defineMessages({
  total: {
    id: 'ProjectHello.total',
    defaultMessage: '##Total##',
    description: 'Total label on price summary',
  },
  includeTaxAndServiceCharge: {
    id: 'ProjectHello.includeTaxAndServiceCharge',
    defaultMessage: '##Included Tax & Service Charge##',
    description: 'Total label on price summary',
  },
});

class CheckInTotalPrice extends React.Component {
  static propTypes = {
    booking: BookingShape,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    booking: null,
  };

  render() {
    const { booking } = this.props;
    const { total, hotel, tax } = booking;
    return (
      <div className={s.root}>
        <div className={s.row}>
          <div className={s.totalDesc}>
            <h3>
              <FormattedMessage {...messages.total} />
            </h3>
            <div>
              <FormattedMessage {...messages.includeTaxAndServiceCharge} />
            </div>
            <div>
              (<CurrencyDisplay value={tax} currency={hotel.currency} />)
            </div>
          </div>
          <div className={s.total}>
            <CurrencyDisplay
              className={s.currency}
              value={total}
              currency={hotel.currency}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CheckInTotalPrice);
