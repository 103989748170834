/* eslint-disable no-console */
/**
 * Created by methee on 7/27/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './CarouselImages.css';
import CarouselImageController from '../CarouselImagesController';

const heroHeight = 496;
const parallaxVelocity = 8;
const imageRotationTime = 6000;
class CarouselImages extends React.Component {
  bindWindowScroll = null;

  constructor(props) {
    super(props);
    this.state = {
      selectImage: props.selectImage,
      scrollY: 0,
      isClickedGallery: false,
    };
  }

  componentDidMount() {
    // if (process.env.BROWSER) {
    this.bindWindowScroll = this.onWindowScroll.bind(this);
    window.addEventListener('scroll', this.bindWindowScroll);
    if (this.props.autoRotateImages) {
      this.timeoutPromise = setTimeout(
        this.autoImageRotation,
        imageRotationTime,
      );
    }
    // }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.selectImage !== this.state.selectImage) {
      this.setState({
        selectImage: nextProps.selectImage,
        isClickedGallery: true,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.bindWindowScroll);
    if (this.timeoutPromise) {
      clearTimeout(this.timeoutPromise);
    }
  }

  onSelectedImage = selectImage => {
    this.setState({ selectImage });
  };

  onWindowScroll = () => {
    this.setState({ scrollY: window.scrollY });
  };

  autoImageRotation = () => {
    if (!this.state.isClickedGallery) {
      const { images, onChange } = this.props;
      const { selectImage } = this.state;
      let nextIndex = selectImage + 1;
      if (nextIndex >= images.length) {
        nextIndex = 0;
      }
      this.setState({ selectImage: nextIndex });
      this.timeoutPromise = setTimeout(
        this.autoImageRotation,
        imageRotationTime,
      );
      onChange(nextIndex);
    }
  };

  render() {
    const { images, dim, title, classNames, customController } = this.props;
    const { selectImage, scrollY } = this.state;
    const heroBackgroundStyle = {
      transform: `translateY(${scrollY /
        parallaxVelocity}px) scale(${(heroHeight + scrollY / parallaxVelocity) /
        heroHeight}, ${(heroHeight + scrollY / parallaxVelocity) /
        heroHeight})`,
    };
    const heroTextStyle = {
      opacity: (heroHeight - (scrollY * parallaxVelocity) / 6) / heroHeight,
    };
    return (
      <div
        className={cx(s.root, classNames)}
        style={{
          opacity: (heroHeight - scrollY) / heroHeight,
        }}
      >
        {images.map((img, index) => (
          <div
            key={img.image}
            className={cx(s.background, index !== selectImage && s.hidden)}
            style={{
              ...heroBackgroundStyle,
              backgroundImage: `url('${img.image}')`,
            }}
          />
        ))}
        {dim && <div className={s.dim} />}
        <p style={heroTextStyle}>{title}</p>
        {customController || (
          <CarouselImageController
            images={images}
            selectedImage={selectImage}
            onSelectedImage={this.onSelectedImage}
          />
        )}
      </div>
    );
  }
}

CarouselImages.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
    }),
  ).isRequired,
  dim: PropTypes.bool,
  selectImage: PropTypes.number,
  title: PropTypes.string,
  classNames: PropTypes.string,
  customController: PropTypes.node,
  autoRotateImages: PropTypes.bool,
  onChange: PropTypes.func,
};

CarouselImages.defaultProps = {
  dim: false,
  selectImage: 0,
  title: '',
  classNames: '',
  customController: null,
  autoRotateImages: false,
  onChange: () => {},
};

export default withStyles(s)(CarouselImages);
