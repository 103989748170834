/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
/**
 * Created by methee on 9/13/17.
 */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';

import {
  closeSignInModal,
  closeSignUpModal,
  closeErrorModal,
  closeDeleteAccountModal,
} from '../../actions/user';
import { closeMeetingRoomModal } from '../../actions/common';
import SignInForm from '../SignInForm';
import SignUpForm from './SignUpForm';
import ErrorForm from './ErrorForm';
import MeetingRoomForm from '../Voucher/MeetingRoomForm';
import DeleteAccountForm from '../DeleteAccountForm/DeleteAccountForm';

import s from './FormModal.css';

import history from '../../history';

class FormModal extends React.Component {
  static propTypes = {
    showSignInModal: PropTypes.bool,
    showSignUpModal: PropTypes.bool,
    showErrorModal: PropTypes.bool,
    showMeetingRoomModal: PropTypes.bool,
    showDeleteAccountModal: PropTypes.bool,
    closeSignInModal: PropTypes.func,
    closeSignUpModal: PropTypes.func,
    closeErrorModal: PropTypes.func,
    closeMeetingRoomModal: PropTypes.func,
    closeDeleteAccountModal: PropTypes.func,
  };

  static defaultProps = {
    showSignInModal: false,
    showSignUpModal: false,
    showErrorModal: false,
    showMeetingRoomModal: false,
    showDeleteAccountModal: false,
    closeSignInModal: () => {},
    closeSignUpModal: () => {},
    closeErrorModal: () => {},
    closeMeetingRoomModal: () => {},
    closeDeleteAccountModal: () => {},
  };

  compocomponentDidMountnentWillMount() {
    if (process.env.BROWSER) {
      this.bindOnKeydown = this.onKeydown.bind(this);
      window.addEventListener('keydown', this.bindOnKeydown);
    }
  }

  onCloseModal = () => {
    if (this.props.showErrorModal === true) {
      this.closeErrorRemoveQuery();
    }
    this.props.closeSignInModal();
    this.props.closeSignUpModal();
    this.props.closeErrorModal();
    this.props.closeMeetingRoomModal();
    this.props.closeDeleteAccountModal();
    window.removeEventListener('keydown', this.bindOnKeydown);
  };

  onKeydown(event) {
    if (event.keyCode === 27) {
      this.onCloseModal();
    }
  }

  closeErrorRemoveQuery = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('error');
    history.replace({
      search: queryParams.toString(),
    });
  };

  render() {
    const {
      showSignInModal,
      showSignUpModal,
      showErrorModal,
      showMeetingRoomModal,
      showDeleteAccountModal,
    } = this.props;
    return (
      <div
        className={cx(
          s.root,
          showSignInModal && s.signIn,
          showSignUpModal && s.signUp,
          showErrorModal && s.error,
          showMeetingRoomModal && s.meetingRoom,
          showDeleteAccountModal && s.deleteAccount,
        )}
      >
        <div className={s.background} onClick={this.onCloseModal} />
        <div className={s.body}>
          <button
            type="button"
            className={cx(
              s.closeButton,
              showMeetingRoomModal && s.zIndexTop,
              showMeetingRoomModal && s.closeButtonMeetingRoom,
            )}
            onClick={this.onCloseModal}
          >
            ×
          </button>
          {showSignInModal && <SignInForm />}
          {showSignUpModal && <SignUpForm />}
          {showErrorModal && (
            <ErrorForm closeErrorRemoveQuery={this.closeErrorRemoveQuery} />
          )}
          {showMeetingRoomModal && <MeetingRoomForm />}
          {showDeleteAccountModal && <DeleteAccountForm />}
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  closeSignInModal,
  closeSignUpModal,
  closeErrorModal,
  closeMeetingRoomModal,
  closeDeleteAccountModal,
};

export default connect(null, mapDispatch)(withStyles(s)(FormModal));
