/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './HeroWithTitle.css';

class HeroWithTitle extends React.Component {
  static propTypes = {
    bgImage: PropTypes.string,
    title: PropTypes.string,
  };

  static defaultProps = {
    bgImage: '',
    title: '',
  };

  render() {
    const { bgImage, title } = this.props;
    return (
      <div className={s.root}>
        <div
          className={s.background}
          style={{ backgroundImage: `url(${bgImage})` }}
        />
        <div className={s.title}>{title}</div>
      </div>
    );
  }
}

export default withStyles(s)(HeroWithTitle);
