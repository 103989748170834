/**
 * Created by methee on 8/23/17.
 */
/* eslint-disable camelcase */
/* eslint-disable css-modules/no-unused-class, css-modules/no-undef-class */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { FormattedMessage, defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import {
  getPrices,
  BookingShape,
  PAYMENT_PAY_AT_HOTEL,
} from '../../actions/booking';
import { setRuntimeVariable } from '../../actions/runtime';
import { UserShape } from '../../actions/user';
import s from './PriceSummary.css';
import Button from '../Button';
import CurrencyDisplay from '../CurrencyDisplay';
import Link from '../Link';

import iconCalendar from './icon-calendar.webp';
import iconCalendarAdd2x from './icon-calendar@2x.webp';
import iconBreakfast from './icon-breakfast.webp';
import iconBreakfastAdd2x from './icon-breakfast@2x.webp';
import iconSmoking from './icon-smoking.webp';
import iconSmokingAdd2x from './icon-smoking@2x.webp';
import iconPetFriendly from './icon-petfriendly.webp';
import iconPetFriendlyAdd2x from './icon-petfriendly@2x.webp';
import iconSundry from './icon-can.webp';
import iconSundryAdd2x from './icon-can@2x.webp';

export const PS_ROOM_SELECT = 'BP_ROOM_SELECT';
export const PS_GUEST_PROFILE = 'BP_GUEST_PROFILE';
export const PS_PAYMENT = 'BP_PAYMENT';
export const PS_BOOKING_CONFIRM = 'PS_BOOKING_CONFIRM';

const messages = defineMessages({
  checkIn: {
    id: 'price_summary.checkIn',
    defaultMessage: '##Check In##',
    description: 'Check in title on price summary box',
  },
  checkOut: {
    id: 'price_summary.checkOut',
    defaultMessage: '##Check Out##',
    description: 'Check Out title on price summary box',
  },
  editBookingDetails: {
    id: 'price_summary.edit_booking_details',
    defaultMessage: '##Edit booking details##',
    description: 'Link for edit booking details',
  },
  stayNights: {
    id: 'price_summary.stayNights',
    defaultMessage:
      '{nightCount, plural, zero {{nightCount} {nights}} one {{nightCount} {night}} other {{nightCount} {nights}}}',
    description: 'Ignore this phrase',
  },
  night: {
    id: 'price_summary.night',
    defaultMessage: '##Night##',
    description: 'Night count',
  },
  nights: {
    id: 'price_summary.nights',
    defaultMessage: '##Nights##',
    description: 'Nights count',
  },
  adultCount: {
    id: 'price_summary.adultCount',
    defaultMessage:
      '{adultCount, plural, zero {{value} {adults}} one {{value} {adult}} other {{value} {adults}}}',
    description: 'Ignore this phrase',
  },
  adult: {
    id: 'price_summary.adult',
    defaultMessage: '##Adult##',
    description: 'Adult count',
  },
  adults: {
    id: 'price_summary.adults',
    defaultMessage: '##Adults##',
    description: 'Adults count',
  },
  childCount: {
    id: 'price_summary.childCount',
    defaultMessage:
      '{childCount, plural, zero {{value} {children}} one {{value} {child}} other {{value} {children}}}',
    description: 'Ignore this phrase',
  },
  child: {
    id: 'price_summary.child',
    defaultMessage: '##Child##',
    description: 'Child count',
  },
  children: {
    id: 'price_summary.children',
    defaultMessage: '##Children##',
    description: 'Children count',
  },
  roomCharge: {
    id: 'price_summary.roomCharge',
    defaultMessage: '##Room Charge##',
    description: 'Room Charge text in price summary',
  },
  taxAndServices: {
    id: 'price_summary.taxAndService',
    defaultMessage: '##Tax & Service##',
    description: 'Tax & Service in price summary',
  },
  taxAndServicesPH: {
    id: 'price_summary.taxAndServicePH',
    defaultMessage: '##Value Added Tax##',
    description: 'Value Added Tax in price summary',
  },
  breakfast: {
    id: 'price_summary.breakfast',
    defaultMessage: '##Breakfast##',
    description: 'Breakfast price label on price summary',
  },
  smoking: {
    id: 'price_summary.smoking',
    defaultMessage: '##Smoking##',
    description: 'Smoking price label on price summary',
  },
  pet_friendly: {
    id: 'price_summary.petFriendly',
    defaultMessage: '##Pet Friendly##',
    description: 'Pet Friendly price label on price summary',
  },
  redItems: {
    id: 'select_room.red_items',
    defaultMessage: '##{red} Store##',
    description: 'Header for red items. Do not translate or remove {red} path',
  },
  redItemsRed: {
    id: 'select_room.red_items.red',
    defaultMessage: '##Convenience##',
    description: '{red} path of {red} items',
  },
  paymentMethod: {
    id: 'price_summary.paymentMethod',
    defaultMessage: '##Payment Method##',
    description: 'Payment method label on price summary',
  },
  creditCard: {
    id: 'price_summary.creditCard',
    defaultMessage: '##Credit CheckInCard##',
    description: 'Credit CheckInCard label',
  },
  payAtHotel: {
    id: 'price_summary.payAtHotel',
    defaultMessage: '##Pay at hotel##',
    description: 'Pay at hotel label',
  },
  nonRememberedRate: {
    id: 'price_summary.nonRememberedRate',
    defaultMessage: '##Non Remembered Rate##',
    description: 'Non Remembered Rate label on price summary',
  },
  beforePromotion: {
    id: 'price_summary.beforePromotion',
    defaultMessage: '##Before promotion ##',
    description: 'Before promotion Rate label on price summary',
  },
  total: {
    id: 'price_summary.total',
    defaultMessage: '##Total##',
    description: 'Total label on price summary',
  },
  totalBirthday: {
    id: 'price_summary.total_birthday',
    defaultMessage: '##Total Birthday##',
    description: 'Total birthday label on price summary',
  },
  birthdayDiscount25: {
    id: 'price_summary.birthdayDiscount25',
    defaultMessage: '##25% Birthday Discount##',
    description: 'Label for birthday discount on price summary',
  },
  free: {
    id: 'price_summary.free',
    defaultMessage: '##Free##',
    description: 'Free text on price summary',
  },
  continue: {
    id: 'price_summary.continue',
    defaultMessage: '##Continue##',
    description: 'Continue button in price summary',
  },
  pleaseSelectRoom: {
    id: 'price_summary.pleaseSelectRoom',
    defaultMessage: '##Please select room##',
    description: 'Word indicate please select room on continue button',
  },
  pleaseSelectMore: {
    id: 'price_summary.pleaseSelectMore',
    defaultMessage: '##Please select at least {roomCount}##',
    description: 'Word indicate please select room on continue button',
  },
  roomCount: {
    id: 'price_summary.roomCount',
    defaultMessage:
      '{roomCount, plural, zero {{value} {rooms}} one {{value} {room}} other {{value} {rooms}}}',
    description: 'Ignore this phrase',
  },
  room: {
    id: 'price_summary.room',
    defaultMessage: '##Room##',
    description: 'Room count',
  },
  rooms: {
    id: 'price_summary.rooms',
    defaultMessage: '##Rooms##',
    description: 'Rooms count',
  },
  pleaseFillGuestInformation: {
    id: 'price_summary.pleaseFillGuestInformation',
    defaultMessage: '##Please fill in guest information##',
    description:
      'Word indicate please fill in guest information on continue button',
  },
  acceptTermsAndConditions: {
    id: 'price_summary.acceptTermsAndConditions',
    defaultMessage:
      '##By confirming this booking, you agree to the {termsAndConditionLink}. Have questions about our hotels? {faqsLink}.##',
    description:
      'Accept terms and condition text in summary box. Do not translate or remove the {termsAndConditionLink} path it is a placeholder for the link to terms and conditions page',
  },
  termsAndConditionLink: {
    id: 'price_summary.termsAndConditionLink',
    defaultMessage: '##Terms and Conditions of booking##',
    description: 'Link to Terms and Conditions in price summary box',
  },
  faqsLink: {
    id: 'price_summary.faqsLink',
    defaultMessage: '##Faqs##',
    description: 'Link to Faqs in price summary box',
  },
  confirmAndPay: {
    id: 'price_summary.confirm_pay',
    defaultMessage: '##Confirm & Pay##',
    description: 'Confirm & pay button in price summary box',
  },
  submitBooking: {
    id: 'price_summary.submitBooking',
    defaultMessage: '##Submit Booking##',
    description: 'Label on button while submitting booking to server',
  },
  submittingBooking: {
    id: 'price_summary.submittingBooking',
    defaultMessage: '##Submitting Booking##',
    description: 'Label on button while submitting booking to server',
  },
  changeHotel: {
    id: 'price_summary.changeHotel',
    defaultMessage: '##Change hotel##',
    description: 'Label on change hotel link',
  },
  editButton: {
    id: 'price_summary.editButton',
    defaultMessage: '##Edit##',
    description: 'Label on edit link to edit check-in / check-out dates',
  },
});

class PriceSummary extends React.Component {
  onClickEditButton = () => {
    this.props.setRuntimeVariable({ name: 'showBookingEngine', value: true });
    this.props.onClickEdit();
  };

  onClickButton = () => {
    this.props.onClickButton();
  };

  render() {
    const { usingIn } = this.props;
    if (!this.props.booking || !this.props.booking.hotel) {
      return (
        <div
          className={cx(
            s.root,
            usingIn === PS_BOOKING_CONFIRM && s.noBackground,
          )}
        >
          <h2>Price Summary</h2>
        </div>
      );
    }
    const {
      hotel: {
        id,
        name,
        slug_name,
        currency,
        is_birthday_remembered,
        location: { parent },
      },
      checkIn,
      checkOut,
      adults,
      children,
      numberOfRooms,
      joinRemembered,
      roomTypes,
      sundrySales,
      promotion,
      profile,
      errors,
      payment,
      isSubmittingBooking,
      oldCheckIn,
      oldCheckOut,
      oldRoomTypes,
      oldAdults,
      oldChildren,
      includePetFriendly,
    } = this.props.booking;
    let hasError = false;
    const addedRooms = [];
    const {
      roomChargeNoTax,
      taxAndServices,
      breakfastPrice,
      smokingPrice,
      petFriendlyPrice,
      discounted,
      memberDiscounted,
      beforePromotionNormal,
      totalNormal,
      totalMember,
    } = getPrices(this.props.booking, this.props.user);
    const nights = checkOut.diff(checkIn, 'd');
    let selectedNumberOfRooms = 0;
    roomTypes.forEach(roomType => {
      if (roomType.numberOfRooms > 0) {
        addedRooms.push({ ...roomType });
        selectedNumberOfRooms += roomType.numberOfRooms;
      }
    });

    const sundryItems = [];
    sundrySales.forEach(item => {
      if (item.numberOfItem > 0) {
        sundryItems.push(
          <tr key={item.item.id}>
            <td className={cx(s.sundryItem, s.grayColor)}>
              <span className={s.highlight}>{item.numberOfItem}</span>{' '}
              <span className={s.lowercase}>x</span> {item.item.title}
            </td>
            <td className={s.price}>
              <CurrencyDisplay
                value={item.item.price * item.numberOfItem}
                currency={currency}
              />
            </td>
          </tr>,
        );
      }
    });

    let beforePromotion = 0;
    let beforePromotionText = '';

    if (promotion === null) {
      if (joinRemembered) {
        beforePromotion = totalNormal;
        beforePromotionText = messages.nonRememberedRate;
      }
    } else if (joinRemembered) {
      beforePromotion = beforePromotionNormal;
      beforePromotionText = messages.nonRememberedRate;
    } else {
      beforePromotion = beforePromotionNormal;
      beforePromotionText = messages.beforePromotion;
    }
    let continueButtonLabel = this.context.intl.formatMessage(
      messages.continue,
    );
    if (usingIn === PS_PAYMENT) {
      continueButtonLabel = this.context.intl.formatMessage(
        messages.confirmAndPay,
      );
    }
    if (usingIn === PS_ROOM_SELECT) {
      if (selectedNumberOfRooms > 0) {
        if (selectedNumberOfRooms < numberOfRooms) {
          hasError = true;
          const roomCount = this.context.intl.formatMessage(
            messages.roomCount,
            {
              roomCount: numberOfRooms,
              value: numberOfRooms,
              room: this.context.intl.formatMessage(messages.room),
              rooms: this.context.intl.formatMessage(messages.rooms),
            },
          );
          continueButtonLabel = this.context.intl.formatMessage(
            messages.pleaseSelectMore,
            {
              roomCount,
            },
          );
        } else {
          const getMaxNumberOfSupportedAdultsInRoom = room => {
            const as = [];
            room.guest_combination.forEach(combination => {
              as.push(combination.adult);
            });
            return Math.max(...as);
          };

          const getMaxNumberOfSupportedChildrenInRoom = (room, as) => {
            let cs = [0];
            room.guest_combination.some(combination => {
              if (combination.adult === as) {
                cs = combination.child;
                return true;
              }
              return false;
            });
            return Math.max(...cs);
          };
          let supportedAdults = 0;
          let supportedChildren = 0;
          roomTypes.forEach(roomType => {
            if (roomType.numberOfRooms > 0) {
              const a = getMaxNumberOfSupportedAdultsInRoom(roomType.room);
              const c = getMaxNumberOfSupportedChildrenInRoom(roomType.room, a);
              supportedAdults += a * roomType.numberOfRooms;
              supportedChildren += c * roomType.numberOfRooms;
            }
          });
          if (adults > supportedAdults || children > supportedChildren) {
            hasError = true;
            let rc = adults - supportedAdults;
            if (rc < 0) {
              rc = 1;
            }
            const roomCount = this.context.intl.formatMessage(
              messages.roomCount,
              {
                roomCount: rc,
                value: rc,
                room: this.context.intl.formatMessage(messages.room),
                rooms: this.context.intl.formatMessage(messages.rooms),
              },
            );
            continueButtonLabel = this.context.intl.formatMessage(
              messages.pleaseSelectMore,
              {
                roomCount,
              },
            );
          }
        }
      }
    }
    const profileErrors = [
      'firstName',
      'lastName',
      'email',
      'phone',
      'country',
      'birthday',
    ];
    let hasProfileError = false;
    errors.forEach(field => {
      if (profileErrors.indexOf(field) > -1) {
        hasProfileError = true;
        return true;
      }
      return false;
    });
    if (usingIn === PS_GUEST_PROFILE && (hasProfileError || (profile.firstName === '' || profile.lastName === '' || profile.email === '' || profile.phoneCountry.phone === '')) ) {
      hasError = true;
      continueButtonLabel = this.context.intl.formatMessage(
        messages.pleaseFillGuestInformation,
      );
    }
    if (usingIn === PS_PAYMENT) {
      hasError = errors.length > 0;
      continueButtonLabel = this.context.intl.formatMessage(
        isSubmittingBooking
          ? messages.submittingBooking
          : messages.submitBooking,
      );
    }
    let paymentText = this.context.intl.formatMessage(messages.creditCard);
    if (payment === PAYMENT_PAY_AT_HOTEL) {
      paymentText = this.context.intl.formatMessage(messages.payAtHotel);
    }
    return (
      <div
        className={cx(
          s.root,
          usingIn === PS_BOOKING_CONFIRM && s.noBackground,
          s[this.context.intl.locale],
        )}
      >
        {usingIn !== PS_BOOKING_CONFIRM && (
          <h2>
            <a
              href={`/hotel/${slug_name}`}
              hrefLang={this.context.intl.locale}
              target="__blank"
            >
              {name}
            </a>
          </h2>
        )}
        {usingIn !== PS_BOOKING_CONFIRM && !this.props.booking.bookingId && (
          <Link
            className={s.smallLink}
            to={`/search/?city=${parent}&check_in=${checkIn.format(
              'YYYY-MM-DD',
            )}&check_out=${checkOut.format(
              'YYYY-MM-DD',
            )}&rooms=${numberOfRooms}&adults=${adults}&children=${children}`}
          >
            <FormattedMessage {...messages.changeHotel} />
          </Link>
        )}
        <table>
          <tbody>
            {usingIn !== PS_BOOKING_CONFIRM && (
              <tr>
                <td className={s.stayDates}>
                  <h3>
                    <img
                      className={s.iconCalendar}
                      src={iconCalendar}
                      srcSet={`${iconCalendarAdd2x} 2x`}
                      alt="Stay dates"
                    />
                    <FormattedMessage {...messages.checkIn} />
                  </h3>
                  {oldCheckIn &&
                    oldCheckOut &&
                    (!oldCheckIn.isSame(checkIn) ||
                      !oldCheckOut.isSame(checkOut)) && (
                      <div className={s.oldStayDates}>
                        {oldCheckIn.format('ddd, DD MMM YYYY')}
                      </div>
                    )}
                  {checkIn.format('ddd, DD MMM YYYY')}
                </td>
                <td className={s.stayDates}>
                  <h3>
                    <img
                      className={s.iconCalendar}
                      src={iconCalendar}
                      srcSet={`${iconCalendarAdd2x} 2x`}
                      alt="Stay dates"
                    />
                    <FormattedMessage {...messages.checkOut} />
                  </h3>
                  {oldCheckIn &&
                    oldCheckOut &&
                    (!oldCheckIn.isSame(checkIn) ||
                      !oldCheckOut.isSame(checkOut)) && (
                      <div className={s.oldStayDates}>
                        {oldCheckOut.format('ddd, DD MMM YYYY')}
                      </div>
                    )}
                  {checkOut.format('ddd, DD MMM YYYY')}
                </td>
              </tr>
            )}
            {usingIn !== PS_BOOKING_CONFIRM && (
              <tr>
                <td>
                  <FormattedMessage
                    {...messages.stayNights}
                    values={{
                      nightCount: nights,
                      night: <FormattedMessage {...messages.night} />,
                      nights: <FormattedMessage {...messages.nights} />,
                    }}
                  />
                </td>
              </tr>
            )}
            {usingIn !== PS_BOOKING_CONFIRM && (
              <tr>
                <td>
                  <button
                    type="button"
                    className={s.editLink}
                    onClick={this.onClickEditButton}
                  >
                    <FormattedMessage {...messages.editButton} />
                  </button>
                </td>
              </tr>
            )}
            {usingIn !== PS_BOOKING_CONFIRM && (
              <tr>
                <td>
                  <hr />
                </td>
              </tr>
            )}
            {addedRooms.length > 0 &&
              addedRooms.map(room => (
                <tr key={room.room.id} className={s.grayColor}>
                  <td colSpan={2}>
                    <img
                      className={s.iconRoom}
                      src={`https://cdn.redplanethotels.com/photos/photo/icons_room/room_${room.room.id}.webp`}
                      srcSet={`https://cdn.redplanethotels.com/photos/photo/icons_room/room_${room.room.id}@2x.webp 2x`}
                      alt="room icon"
                    />
                    {oldRoomTypes &&
                      oldRoomTypes[room.room.id] &&
                      oldRoomTypes[room.room.id].numberOfRooms > 0 &&
                      oldRoomTypes[room.room.id].numberOfRooms !==
                        room.numberOfRooms && (
                        <span>
                          <span className={s.oldRoom}>
                            <span className={s.highlight}>
                              {oldRoomTypes[room.room.id].numberOfRooms}
                            </span>{' '}
                            {room.room.name}
                          </span>
                          &nbsp;
                        </span>
                      )}
                    <span className={s.highlight}>{room.numberOfRooms}</span>{' '}
                    {room.room.name}
                  </td>
                </tr>
              ))}
            <tr>
              <td colSpan={2}>
                {typeof oldAdults !== 'undefined' &&
                  oldAdults !== adults &&
                  oldAdults > 0 && (
                    <span>
                      <span className={s.oldGuests}>
                        <FormattedMessage
                          {...messages.adultCount}
                          values={{
                            adultCount: oldAdults,
                            value: (
                              <span className={s.highlight}>{oldAdults}</span>
                            ),
                            adult: <FormattedMessage {...messages.adult} />,
                            adults: <FormattedMessage {...messages.adults} />,
                          }}
                        />
                      </span>
                      &nbsp;
                    </span>
                  )}
                {typeof oldChildren !== 'undefined' &&
                  oldChildren !== children &&
                  oldChildren > 0 && (
                    <span className={s.oldGuests}>
                      /&nbsp;
                      <FormattedMessage
                        {...messages.childCount}
                        values={{
                          childCount: oldChildren,
                          value: (
                            <span className={s.highlight}>{oldChildren}</span>
                          ),
                          child: <FormattedMessage {...messages.child} />,
                          children: <FormattedMessage {...messages.children} />,
                        }}
                      />
                      &nbsp;
                    </span>
                  )}
                <FormattedMessage
                  {...messages.adultCount}
                  values={{
                    adultCount: adults,
                    value: <span className={s.highlight}>{adults}</span>,
                    adult: <FormattedMessage {...messages.adult} />,
                    adults: <FormattedMessage {...messages.adults} />,
                  }}
                />
                {children > 0 && (
                  <span>
                    &nbsp;/&nbsp;
                    <FormattedMessage
                      {...messages.childCount}
                      values={{
                        childCount: children,
                        value: <span className={s.highlight}>{children}</span>,
                        child: <FormattedMessage {...messages.child} />,
                        children: <FormattedMessage {...messages.children} />,
                      }}
                    />
                  </span>
                )}
              </td>
            </tr>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            <tr>
              <td className={s.grayColor}>
                <FormattedMessage {...messages.roomCharge} />
              </td>
              <td className={s.price}>
                <CurrencyDisplay value={roomChargeNoTax} currency={currency} />
              </td>
            </tr>
            <tr>
              <td className={s.grayColor}>
                {[1, 2, 3, 4, 24].includes(id) && (
                  <FormattedMessage {...messages.taxAndServices} />
                )}
                {![1, 2, 3, 4, 24].includes(id) && (
                  <FormattedMessage {...messages.taxAndServicesPH} />
                )}
              </td>
              <td className={s.price}>
                <CurrencyDisplay value={taxAndServices} currency={currency} />
              </td>
            </tr>
            <tr>
              <td>
                <hr />
              </td>
            </tr>
            {breakfastPrice > 0 && (
              <tr>
                <td className={s.grayColor}>
                  <img
                    className={s.iconBreakfast}
                    src={iconBreakfast}
                    srcSet={`${iconBreakfastAdd2x} 2x`}
                    alt="Breakfast"
                  />
                  <FormattedMessage {...messages.breakfast} />
                </td>
                <td className={s.price}>
                  <CurrencyDisplay value={breakfastPrice} currency={currency} />
                </td>
              </tr>
            )}
            {smokingPrice > 0 && (
              <tr>
                <td className={s.grayColor}>
                  <img
                    className={s.iconSmoking}
                    src={iconSmoking}
                    srcSet={`${iconSmokingAdd2x} 2x`}
                    alt="Smoking"
                  />
                  <FormattedMessage {...messages.smoking} />
                </td>
                <td className={s.price}>
                  <CurrencyDisplay value={smokingPrice} currency={currency} />
                </td>
              </tr>
            )}
            {petFriendlyPrice >= 0 && includePetFriendly && (
              <tr>
                <td className={s.grayColor}>
                  <img
                    className={s.iconPetFriendly}
                    src={iconPetFriendly}
                    srcSet={`${iconPetFriendlyAdd2x} 2x`}
                    alt="Pet Friendly"
                  />
                  <FormattedMessage {...messages.pet_friendly} />
                </td>
                <td className={s.price}>
                  <CurrencyDisplay
                    type={petFriendlyPrice === 0 ? 'free' : null}
                    value={petFriendlyPrice}
                    currency={currency}
                  />
                </td>
              </tr>
            )}
            {sundryItems.length > 0 && (
              <tr>
                <td colSpan={2}>
                  <img
                    className={s.iconBreakfast}
                    src={iconSundry}
                    srcSet={`${iconSundryAdd2x} 2x`}
                    alt="Breakfast"
                  />
                  <FormattedMessage
                    {...messages.redItems}
                    values={{
                      red: (
                        <span className={s.highlight}>
                          <FormattedMessage {...messages.redItemsRed} />
                        </span>
                      ),
                    }}
                  />
                </td>
              </tr>
            )}
            {sundryItems.length > 0 && sundryItems}
            {(breakfastPrice > 0 || sundryItems.length > 0) && (
              <tr>
                <td>
                  <hr />
                </td>
              </tr>
            )}
            {usingIn === PS_BOOKING_CONFIRM && (
              <tr>
                <td className={s.grayColor}>
                  <FormattedMessage {...messages.paymentMethod} />
                </td>
                <td className={s.price}>{paymentText}</td>
              </tr>
            )}
            {beforePromotion > 0 && (
              <tr>
                <td className={cx(s.strikethrough, s.grayColor)}>
                  <FormattedMessage {...beforePromotionText} />
                </td>
                <td className={cx(s.strikethrough, s.price, s.grayColor)}>
                  <CurrencyDisplay
                    value={beforePromotion}
                    currency={currency}
                  />
                </td>
              </tr>
            )}
            {promotion !== null && (
              <tr>
                <td>{promotion.title}</td>
                <td className={s.price}>
                  <CurrencyDisplay
                    value={joinRemembered ? memberDiscounted : discounted}
                    currency={currency}
                  />
                </td>
              </tr>
            )}
            {totalMember > 0 && (
              <tr>
                <td>
                  <div className={cx(s.total, s.grayColor)}>
                    <FormattedMessage {...messages.total} />
                  </div>
                  <div className={cx(s.discountBirthday, s.grayColor)}>
                    {is_birthday_remembered && (
                      <FormattedMessage {...messages.birthdayDiscount25} />
                    )}
                  </div>
                </td>
                <td
                  className={cx(
                    s.total,
                    s.price,
                    s.highlight,
                    is_birthday_remembered && s.birthday,
                  )}
                  style={{ verticalAlign: 'top' }}
                >
                  <CurrencyDisplay
                    value={joinRemembered ? totalMember : totalNormal}
                    currency={currency}
                  />
                </td>
              </tr>
            )}
            {addedRooms.length > 0 && totalMember <= 0 && (
              <tr>
                <td className={cx(s.total, s.grayColor)} />
                <td className={cx(s.total, s.price, s.highlight)}>
                  <FormattedMessage {...messages.free} />
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {usingIn === PS_PAYMENT && (
          <div className={s.terms}>
            <FormattedMessage
              {...messages.acceptTermsAndConditions}
              values={{
                termsAndConditionLink: (
                  <Link to="/conditions">
                    <FormattedMessage {...messages.termsAndConditionLink} />
                  </Link>
                ),
                faqsLink: (
                  <Link to="/faq">
                    <FormattedMessage {...messages.faqsLink} />
                  </Link>
                ),
              }}
            />
          </div>
        )}
        {usingIn !== PS_BOOKING_CONFIRM && (
          <Button
            className={cx(s.continueButton)}
            label={continueButtonLabel}
            onClick={this.onClickButton}
            disabled={hasError || isSubmittingBooking}
          />
        )}
      </div>
    );
  }
}

PriceSummary.propTypes = {
  usingIn: PropTypes.oneOf([
    PS_ROOM_SELECT,
    PS_GUEST_PROFILE,
    PS_PAYMENT,
    PS_BOOKING_CONFIRM,
  ]),
  user: UserShape.isRequired,
  booking: BookingShape.isRequired,
  onClickButton: PropTypes.func,
  onClickEdit: PropTypes.func,
  setRuntimeVariable: PropTypes.func,
};

PriceSummary.defaultProps = {
  usingIn: PS_BOOKING_CONFIRM,
  onClickButton: () => {},
  onClickEdit: () => {},
  setRuntimeVariable: () => {},
};

PriceSummary.contextTypes = {
  intl: intlShape.isRequired,
};

const mapState = state => ({
  user: state.user,
  booking: state.booking,
});

const mapDispatch = {
  setRuntimeVariable,
};

export default connect(mapState, mapDispatch)(withStyles(s)(PriceSummary));
