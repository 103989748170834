import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import { intlShape } from 'react-intl';
import moment from 'moment';

import s from './BookingEngineModal.css';
import BookingEngine from '../BookingEngine';
import { setRuntimeVariable } from '../../actions/runtime';
import { BookingShape } from '../../actions/booking';
import history from '../../history';

class BookingEngineModal extends React.Component {
  static propTypes = {
    booking: BookingShape,
    setRuntimeVariable: PropTypes.func,
  };

  static defaultProps = {
    booking: null,
    setRuntimeVariable: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  onClose = () => {
    this.props.setRuntimeVariable({ name: 'showBookingEngine', value: false });
  };

  onSearch = ({ checkIn, checkOut, numberOfRooms, adults, children }) => {
    if (!checkIn) {
      checkIn = moment(); // eslint-disable-line
    }
    if (!checkOut) {
      checkOut = checkIn.clone().add(1, 'day'); // eslint-disable-line
    }
    const {
      booking: {
        hotel,
        flashCode,
        includeBreakfast,
        includeSmoking,
        includePetFriendly,
      },
    } = this.props;
    if (!this.props.booking.bookingId) {
      history.push({
        pathname: `/${this.context.intl.locale}/booking/room-select/`,
        search: `hotel=${hotel.id}&check_in=${checkIn.format(
          'YYYY-MM-DD',
        )}&check_out=${checkOut.format(
          'YYYY-MM-DD',
        )}&rooms=${numberOfRooms}&adults=${adults}&children=${children}${
          includeBreakfast ? '&breakfast=1' : ''
        }${includeSmoking ? '&smoking=1' : ''}${
          includePetFriendly ? '&pet_friendly=1' : ''
        }
        ${flashCode ? `&code=${flashCode}` : ''}`,
      });
    } else {
      history.push({
        pathname: `/${this.context.intl.locale}/booking/edit/room-select/`,
        search: `hotel=${hotel.id}&check_in=${checkIn.format(
          'YYYY-MM-DD',
        )}&check_out=${checkOut.format(
          'YYYY-MM-DD',
        )}&rooms=${numberOfRooms}&adults=${adults}&children=${children}${
          includeBreakfast ? '&breakfast=1' : '&breakfast=0'
        }${includeSmoking ? '&smoking=1' : '&smoking=0'}${
          includePetFriendly ? '&pet_friendly=1' : '&pet_friendly=0'
        }
        ${flashCode ? `&code=${flashCode}` : ''}&booking_id=${
          this.props.booking.bookingId
        }${
          this.props.booking.bookingNumber
            ? `&confirmation=${this.props.booking.bookingNumber}`
            : ''
        }${
          this.props.booking.email
            ? `&email=${encodeURIComponent(this.props.booking.email)}`
            : ''
        }`,
      });
    }
    this.onClose();
  };

  render() {
    return (
      <div className={s.root}>
        <div className={s.body}>
          <button className={s.btnClose} onClick={this.onClose}>
            ×
          </button>
          <BookingEngine
            forceMobile
            withoutLocation
            booking={this.props.booking}
            overrideOnSearch={this.onSearch}
            scrollable={false}
          />
        </div>
      </div>
    );
  }
}

const mapProps = state => ({
  booking: state.booking,
});

const mapDispatch = {
  setRuntimeVariable,
};

export default connect(
  mapProps,
  mapDispatch,
)(withStyles(s)(BookingEngineModal));
