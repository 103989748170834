import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

import s from './CheckInCard.css';

class CheckInCard extends React.Component {
  static propTypes = {
    icon: PropTypes.shape({
      normal: PropTypes.string,
      add2x: PropTypes.string,
    }),
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  };

  static defaultProps = {
    icon: null,
    label: '',
    value: '',
  };

  render() {
    const { icon } = this.props;
    let normal = null;
    let add2x = null;
    if (icon) {
      normal = icon.normal;
      add2x = icon.add2x;
    }
    const { label, value } = this.props;
    return (
      <div className={s.root}>
        {icon && (
          <div className={s.icon}>
            <img src={normal} srcSet={`${add2x} 2x`} alt={label} />
          </div>
        )}
        <div className={s.content}>
          <div className={s.label}>{label}</div>
          <div className={s.value}>{value}</div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CheckInCard);
