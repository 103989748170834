/* eslint-disable func-names */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/prop-types */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { graphql, compose } from 'react-apollo';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import moment from 'moment';
import Cookies from 'universal-cookie';
import cx from 'classnames';
import { DateRangePicker } from 'react-dates';
import {
  START_DATE,
  END_DATE,
  // VERTICAL_SCROLLABLE,
  VERTICAL_ORIENTATION,
} from 'react-dates/constants';
import withStyles from 'isomorphic-style-loader/lib/withStyles';

// import mp from '../../analytic';
import query from './booking-engine.graphql';
import s from './BookingEngine.css';
import history from '../../history';
import { addFlashMessage, TYPE_ERROR } from '../../actions/flashMessage';
import { BookingShape, clearBooking } from '../../actions/booking';

import Dropdown from '../Dropdown';
import RadioButton from '../RadioButton';
import NumberPicker from '../NumberPicker';
import BookButtonMobile from './BookButtonMobile';
import TextBox from '../TextBox';
import Button from '../Button';
import LoadingIndicator from '../LoadingIndicator';

import iconGlobe from './icon-globe.png';
import iconGlobe2x from './icon-globe@2x.png';
import iconPin from './icon-pin.png';
import iconPin2x from './icon-pin@2x.png';
import iconCalendar from './icon-calendar.png';
import iconCalendarAdd2x from './icon-calendar@2x.png';

// image size discrepancy of webp and png is so small, used png to render on both Safari and other browsers

const message = defineMessages({
  country: {
    id: 'booking_engine.country',
    defaultMessage: '##Country##',
    description: 'Placeholder for document dropdown on Booking engine',
  },
  city: {
    id: 'booking_engine.city',
    defaultMessage: '##City##',
    description: 'Placeholder for city dropdown on Booking engine',
  },
  checkin: {
    id: 'booking_engine.check_in',
    defaultMessage: '##Check in##',
    description: 'Placeholder text for check in textbox on room-select engine',
  },
  checkout: {
    id: 'booking_engine.check_out',
    defaultMessage: '##Check out##',
    description: 'Placeholder text for check out textbox on room-select engine',
  },
  tonight: {
    id: 'booking_engine.tonight',
    defaultMessage: '##Tonight##',
    description: 'Label indicate Tonight radio button on Booking engine',
  },
  tomorrow: {
    id: 'booking_engine.tomorrow',
    defaultMessage: '##Tomorrow##',
    description: 'Label indicate Tomorrow radio button on Booking engine',
  },
  nights: {
    id: 'booking_engine.nights',
    defaultMessage: '##Night(s)##',
    description: 'Label indicate Night(s) on Booking engine',
  },
  rooms: {
    id: 'booking_engine.rooms',
    defaultMessage: '##Room(s)##',
    description: 'Label indicate Rooms on Booking engine',
  },
  adults: {
    id: 'booking_engine.adults',
    defaultMessage: '##Adult(s)##',
    description: 'Label indicate Adults on Booking engine',
  },
  children: {
    id: 'booking_engine.kids',
    defaultMessage: '##Kid(s)##',
    description: 'Label indicate Child(s) on Booking engine',
  },
  search: {
    id: 'booking_engine.book_now',
    defaultMessage: '##Search##',
    description: "Text 'Book Now' on Book Now button on Booking Engine",
  },
  bestPriceGuaranteed: {
    id: 'booking_engine.best_price_guaranteed',
    defaultMessage: '##Best Price Guaranteed##',
    description:
      "Text 'Best Price Guaranteed' on Book Now button on Booking Engine",
  },
  childDisclaimer: {
    id: 'booking_engine.child_disclaimer',
    defaultMessage: "*Please review our child policy in our hotel's page",
    description: 'Disclaimer for child policy on booking engine',
  },
  pleaseSelectCountry: {
    id: 'booking_engine.please_select_country',
    defaultMessage: '##Please select document first##',
    description: 'Error message please select document',
  },
  allCountries: {
    id: 'booking_engine.all_countries',
    defaultMessage: '##All Countries##',
    description: 'Menu to select all countries',
  },
  allCities: {
    id: 'booking_engine.all_cities',
    defaultMessage: '##All Cities##',
    description: 'Menu to select all cities',
  },
});

const maximumNights = 30;
const navAndHeroHeight = 560;
const bookingEngineHeightDesktop = 210;
const bookingEngineHeightMobile = 335;
const marginDiffWhenOpenCalendar = 160;
const maxMobileWidth = 992;
const dateFormatDefault = 'D MMM YYYY';
const dateFormatJa = 'YYYY/MM/DD';
const minimumDate = moment().subtract(1, 'd');
const maximumDate = minimumDate
  .clone()
  .add(1, 'year')
  .endOf('month');
let cookies = null;
let domain = '';
if (process.env.BROWSER) {
  cookies = new Cookies();
  const { hostname } = window.location;
  const paths = hostname.split('.');
  domain = paths.slice(paths.length - 2, paths.length).join('.');
}

let cacheCookies = {
  country: 0,
  city: 0,
  checkIn: null,
  checkOut: null,
  rooms: 1,
  adults: 2,
  children: 0,
};
const getCookie = () => {
  if (process.env.BROWSER) {
    const today = moment().startOf('d');
    let checkIn =
      cookies.get('rphl.search.checkIn', { path: '/', domain }) || null;
    let checkOut =
      cookies.get('rphl.search.checkOut', { path: '/', domain }) || null;
    if (checkIn) {
      checkIn = moment(checkIn);
      if (checkIn.isBefore(today)) {
        checkIn = null;
        checkOut = null;
      }
    }
    if (checkOut) {
      checkOut = moment(checkOut);
      if (checkOut.isBefore(today)) {
        checkIn = null;
        checkOut = null;
      }
    }
    cacheCookies = {
      country: cookies.get('rphl.search.country', { path: '/', domain }) || 13,
      city: cookies.get('rphl.search.city', { path: '/', domain }) || 74,
      checkIn,
      checkOut,
      rooms: cookies.get('rphl.search.rooms', { path: '/', domain }) || 1,
      adults: cookies.get('rphl.search.adults', { path: '/', domain }) || 2,
      children: cookies.get('rphl.search.children', { path: '/', domain }) || 0,
    };
  }
  return cacheCookies;
};

class BookingEngine extends React.Component {
  bindOnWindowResize = null;

  constructor(props) {
    super(props);
    const { selectedCountry, selectedCity } = props;
    let { checkIn, checkOut, numberOfRooms, adults, children } = props;
    if (props.booking) {
      const { booking } = props;
      checkIn = booking.checkIn || checkIn;
      checkOut = booking.checkOut || checkOut;
      numberOfRooms = booking.numberOfRooms || numberOfRooms;
      adults = booking.adults || adults;
      children = booking.children || children;
    }
    this.state = {
      selectedCountry,
      selectedCity,
      checkIn,
      checkOut,
      numberOfRooms,
      adults,
      children,
      currentScrollY: 0,
      initializing: true,
      showingCalendar: false,
      windowHeight: 0,
      selectedTonight: false,
      selectedTomorrow: false,
      focusedInput: null,
      isMobileDevice: false,
      searching: false,
      stickyBookingSearch: false,
    };
  }

  // eslint-disable-next-line react/no-deprecated
  componentDidMount() {
    if (process.env.BROWSER) {
      this.onWindowResize();
      const selectedCountry = parseInt(getCookie().country, 10);
      const selectedCity = parseInt(getCookie().city, 10);
      let checkIn = getCookie().checkIn || moment();
      let checkOut = getCookie().checkOut || moment().add(1, 'day');
      let numberOfRooms = parseInt(getCookie().rooms, 10);
      let adults = parseInt(getCookie().adults, 10);
      let children = parseInt(getCookie().children, 10);
      if (this.props.booking) {
        const { booking } = this.props;
        checkIn = booking.checkIn || checkIn;
        checkOut = booking.checkOut || checkOut;
        numberOfRooms = booking.numberOfRooms || numberOfRooms;
        adults = booking.adults || adults;
        children = booking.children || children;
      }
      this.setState({
        selectedCountry,
        selectedCity,
        checkIn,
        checkOut,
        numberOfRooms,
        adults,
        children,
      });

      if (this.props.scrollable)
        window.addEventListener('scroll', this.handleScroll); //bug
    }
    this.bindOnWindowResize = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.bindOnWindowResize);
    const initialized = () => {
      const {
        data: { loading },
      } = this.props;
      if (!loading) {
        this.setState({ initializing: false });
        this.props.onInitialized();
        this.onWindowResize();
      } else {
        setTimeout(initialized, 500);
      }
    };
    setTimeout(initialized, 500);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.bindOnWindowResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const bookingSearchEngineId = document.getElementById(
      'booking-search-engine',
    );
    const sticky = bookingSearchEngineId.offsetTop + 59;
    if (window.scrollY > sticky) {
      this.setState({ stickyBookingSearch: true });
    } else {
      this.setState({ stickyBookingSearch: false });
    }
  };

  onWindowResize = () => {
    this.setState({
      windowHeight: window.innerHeight,
      isMobileDevice: window.innerWidth < maxMobileWidth,
    });
  };

  onSelectedCountry = country => {
    this.setState({ selectedCountry: country === 'all' ? 0 : country });
    const {
      data: { locations },
    } = this.props;
    if (locations) {
      locations.some(ct => {
        if (ct.id === parseInt(country, 10)) {
          this.setState({ selectedCity: ct.children[0].id });
          return true;
        }
        return false;
      });
    }
    cookies.set('rphl.search.country', country, {
      path: '/',
      domain,
    });
    cookies.remove('rphl.search.city', {
      path: '/',
      domain,
    });
  };

  onSelectedCity = city => {
    this.setState({ selectedCity: city === 'all' ? 0 : city });
    cookies.set('rphl.search.city', city, {
      path: '/',
      domain,
    });
  };

  onClickCity = () => {
    if (!this.state.selectedCountry) {
      this.props.addFlashMessage(
        this.context.intl.formatMessage(message.pleaseSelectCountry),
        TYPE_ERROR,
      );
    }
  };

  onSelectedStayDate = ({ startDate, endDate }) => {
    this.setState({
      checkIn: startDate,
      checkOut: endDate,
      selectedTonight: false,
      selectedTomorrow: false,
    });
    if (startDate) {
      cookies.set('rphl.search.checkIn', startDate.format('YYYY-MM-DD'), {
        path: '/',
        domain,
      });
    }
    if (endDate) {
      cookies.set('rphl.search.checkOut', endDate.format('YYYY-MM-DD'), {
        path: '/',
        domain,
      });
    }
  };

  onSelectingStayDate = focusedInput => {
    const showingCalendar = focusedInput !== null;
    this.setState({
      focusedInput,
    });
    if (!this.state.isMobileDevice) {
      if (showingCalendar && window.scrollY < 180) {
        const animateScrollY = () => {
          window.scrollBy(0, 5);
          if (window.scrollY < 180) {
            setTimeout(animateScrollY, 4);
          }
        };
        setTimeout(animateScrollY, 4);
      }
    }
  };

  onClosePicker = () => {
    if (this.state.isMobileDevice) {
      window.scrollTo(0, this.state.currentScrollY);
    }
  };

  onSelectedToNight = () => {
    const checkIn = moment();
    const checkOut = moment().add(1, 'day');
    this.setState({
      checkIn,
      checkOut,
      selectedTonight: true,
      selectedTomorrow: false,
    });
    // mp.track('#Today Selected');
    cookies.set('rphl.search.checkIn', checkIn.format('YYYY-MM-DD'), {
      path: '/',
      domain,
    });
    cookies.set('rphl.search.checkOut', checkOut.format('YYYY-MM-DD'), {
      path: '/',
      domain,
    });
  };

  onSelectedTomorrow = () => {
    const checkIn = moment().add(1, 'days');
    const checkOut = moment().add(2, 'days');
    this.setState({
      checkIn,
      checkOut,
      selectedTonight: false,
      selectedTomorrow: true,
    });
    // mp.track('#Tomorrow Selected');
    cookies.set('rphl.search.checkIn', checkIn.format('YYYY-MM-DD'), {
      path: '/',
      domain,
    });
    cookies.set('rphl.search.checkOut', checkOut.format('YYYY-MM-DD'), {
      path: '/',
      domain,
    });
  };

  onSelectedNumberOfRooms = number => {
    this.setState({ numberOfRooms: number });
    cookies.set('rphl.search.rooms', number, {
      path: '/',
      domain,
    });
  };

  onSelectedNumberOfAdults = number => {
    this.setState({ adults: number });
    cookies.set('rphl.search.adults', number, {
      path: '/',
      domain,
    });
  };

  onSelectedNumberOfChildren = number => {
    this.setState({ children: number });
    cookies.set('rphl.search.children', number, {
      path: '/',
      domain,
    });
  };

  onClickBookButton = async event => {
    if (this.props.overrideOnSearch) {
      this.props.overrideOnSearch(this.state);
      return;
    }
    if (event) {
      event.preventDefault();
    }
    const {
      data: { locations },
    } = this.props;
    const {
      selectedCountry,
      selectedCity,
      numberOfRooms,
      adults,
      children,
    } = this.state;
    let { checkIn, checkOut } = this.state;
    let hotelCount = 0;
    let hotel = null;
    if (selectedCountry && locations && selectedCity) {
      locations.forEach(country => {
        if (country.id === parseInt(selectedCountry, 10)) {
          country.children.forEach(city => {
            if (city.id === parseInt(selectedCity, 10)) {
              city.children.forEach(area => {
                if (area.hotels !== null) {
                  [hotel] = area.hotels;
                  hotelCount += area.hotels.length;
                }
              });
            }
          });
        }
      });
    }
    if (checkIn === null || checkOut === null) {
      if (checkIn === null) {
        checkIn = moment();
      }
      checkOut = checkIn.clone().add(1, 'd');
    }
    cookies.set('rphl.search.checkIn', checkIn.format('YYYY-MM-DD'), {
      path: '/',
      domain,
    });
    cookies.set('rphl.search.checkOut', checkOut.format('YYYY-MM-DD'), {
      path: '/',
      domain,
    });
    this.setState({ searching: true });
    if (hotelCount === 1) {
      if (this.props.clearBookingBeforeSearch) {
        await this.props.clearBooking();
      }
      history.push({
        pathname: `/${this.context.intl.locale}/hotel/${hotel.slug_name}/`,
        search: `check_in=${checkIn.format(
          'YYYY-MM-DD',
        )}&check_out=${checkOut.format(
          'YYYY-MM-DD',
        )}&rooms=${numberOfRooms}&adults=${adults}&children=${children}`,
      });
    } else {
      let countryAndCity = '';
      if (selectedCountry) {
        countryAndCity += `country=${selectedCountry}&`;
      }
      if (selectedCity) {
        countryAndCity += `city=${selectedCity}&`;
      }
      if (this.props.clearBookingBeforeSearch) {
        await this.props.clearBooking();
      }
      history.push({
        pathname: `/${this.context.intl.locale}/search/`,
        search: `${countryAndCity}check_in=${checkIn.format(
          'YYYY-MM-DD',
        )}&check_out=${checkOut.format(
          'YYYY-MM-DD',
        )}&rooms=${numberOfRooms}&adults=${adults}&children=${children}`,
      });
    }
  };

  render() {
    let dateFormat = dateFormatDefault;
    const { currentLocale } = this.props;
    if (currentLocale === 'ja') {
      dateFormat = dateFormatJa;
    }
    const {
      selectedCountry,
      selectedCity,
      checkIn,
      checkOut,
      numberOfRooms,
      adults,
      children,
      showingCalendar,
      windowHeight,
      selectedTonight,
      selectedTomorrow,
      focusedInput,
      isMobileDevice,
      initializing,
      searching,
      stickyBookingSearch,
    } = this.state;
    const {
      data: { loading, locations },
      buttonText,
      withoutLocation,
      forceMobile,
      booking,
    } = this.props;
    const countries = [
      {
        value: 0,
        name: this.context.intl.formatMessage({ ...message.country }),
      },
      // {
      //   value: 'all',
      //   name: this.context.intl.formatMessage({ ...message.allCountries }),
      // },
    ];
    if (locations) {
      locations.map(country =>
        countries.push({
          value: country.id,
          name: country.name,
        }),
      );
    }
    const cities = [
      {
        value: 0,
        name: this.context.intl.formatMessage({ ...message.city }),
      },
      // {
      //   value: 'all',
      //   name: this.context.intl.formatMessage({ ...message.allCities }),
      // },
    ];
    if (selectedCountry && locations) {
      locations.forEach(country => {
        if (country.id === parseInt(selectedCountry, 10)) {
          country.children.map(city =>
            cities.push({
              value: city.id,
              name: city.name,
            }),
          );
        }
      });
    }
    let { marginTop } = this.props;
    const bookingEngineHeight = !isMobileDevice
      ? bookingEngineHeightDesktop
      : bookingEngineHeightMobile;
    const bottomMostWhenOpenedCalendar =
      navAndHeroHeight +
      bookingEngineHeight +
      marginDiffWhenOpenCalendar +
      marginTop;
    if (windowHeight) {
      if (windowHeight < bottomMostWhenOpenedCalendar) {
        if (showingCalendar) {
          marginTop -= marginDiffWhenOpenCalendar;
        }
      }
    }
    if (marginTop < -bookingEngineHeightDesktop) {
      marginTop = -bookingEngineHeightDesktop;
    }
    let maxSupportAdults = 2;
    let isOneMaxOccupancy = false;
    const oneMaxOccupancyCityArray = []; // Put hotel id on here for limit Occupancy
    const maxAdults = function(room) {
      const a = [];
      room.guest_combination.forEach(combination => {
        a.push(combination.adult);
      });
      return Math.max(...a);
    };
    const availables = [];
    if (selectedCountry && locations && selectedCity) {
      if (oneMaxOccupancyCityArray.indexOf(parseInt(selectedCity, 10)) >= 0) {
        isOneMaxOccupancy = true;
      }
      locations.forEach(country => {
        if (country.id === parseInt(selectedCountry, 10)) {
          country.children.forEach(city => {
            if (city.id === parseInt(selectedCity, 10)) {
              city.children.forEach(area => {
                if (area.hotels !== null) {
                  area.hotels.forEach(hotel => {
                    hotel.rooms.forEach(room => {
                      if (room.available > 1 && room.available <= 5) {
                        availables.push(room.available);
                      }
                      const ma = maxAdults(room);
                      if (maxSupportAdults < ma) {
                        maxSupportAdults = ma;
                      }
                    });
                  });
                }
              });
            }
          });
        }
      });
    }
    if (booking && booking.hotel) {
      maxSupportAdults = 2;
      booking.hotel.rooms.forEach(room => {
        const ma = maxAdults(room);
        if (maxSupportAdults < ma) {
          maxSupportAdults = ma;
        }
      });
    }
    if (isOneMaxOccupancy) {
      maxSupportAdults = 1;
    }
    const maxNumberOfRooms =
      availables.length > 0 ? Math.max(...availables) : 5;
    const minNumberOfAdults = numberOfRooms || 1;
    const maxNumberOfAdults = (numberOfRooms || 1) * maxSupportAdults;
    const maxNumberOfChildren = isOneMaxOccupancy
      ? 0
      : numberOfRooms * maxSupportAdults;
    let isOutsideRange;
    if (checkIn) {
      isOutsideRange = day =>
        focusedInput === END_DATE &&
        (day.isBefore(minimumDate) ||
          day.isSameOrAfter(checkIn.clone().add(maximumNights, 'days')));
    }
    return (
      <div
        id="booking-search-engine"
        data-booking-search={stickyBookingSearch}
        className={cx(
          s.root,
          s[this.context.intl.locale],
          stickyBookingSearch && s.stickyBookingSearch,
        )}
        style={{
          marginTop,
          visibility: loading || initializing ? 'hidden' : 'visible',
        }}
      >
        <div className={cx(s.desktop, forceMobile && s.forced)}>
          <div className={s.container}>
            <div className={s.redBackground} />
            <div className={s.body}>
              <form onSubmit={this.onClickBookButton}>
                <div className={s.colLeft}>
                  <div className={s.row}>
                    {!withoutLocation && (
                      <Dropdown
                        className={s.country}
                        icon={{ normal: iconGlobe, add2x: iconGlobe2x }}
                        options={countries}
                        preselectedValue={selectedCountry}
                        onSelectedValue={this.onSelectedCountry}
                        showFloatingLabel={false}
                        width="16px"
                        height="16px"
                      />
                    )}
                    <div
                      className={cx(
                        s.dateRangePicker,
                        (checkIn == null || checkOut == null) && s.noValue,
                        s[this.context.intl.locale],
                      )}
                    >
                      <DateRangePicker
                        startDateId="dateRangePickerStartDesktop"
                        endDateId="dateRangePickerEndDesktop"
                        // startDateAriaLabel="dateRangePickerStartAriaLabelDesk"
                        // endDateAriaLabel="dateRangePickerEndAriaLabelDesk"
                        displayFormat={dateFormat}
                        isOutsideRange={isOutsideRange}
                        readOnly
                        hideKeyboardShortcutsPanel
                        showDefaultInputIcon
                        customInputIcon={
                          <img
                            src={iconCalendar}
                            srcSet={`${iconCalendarAdd2x} 2x`}
                            alt="icon"
                            width="17px"
                            height="17px"
                          />
                        }
                        startDatePlaceholderText={this.context.intl.formatMessage(
                          message.checkin,
                        )}
                        endDatePlaceholderText={this.context.intl.formatMessage(
                          message.checkout,
                        )}
                        startDate={checkIn} // momentPropTypes.momentObj or null,
                        endDate={checkOut} // momentPropTypes.momentObj or null,
                        onDatesChange={this.onSelectedStayDate} // PropTypes.func.isRequired,
                        focusedInput={
                          !isMobileDevice && !forceMobile ? focusedInput : null
                        } // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                        onFocusChange={this.onSelectingStayDate}
                        isDayBlocked={day =>
                          day.isBefore(minimumDate) || day.isAfter(maximumDate)} // eslint-disable-line
                      />
                    </div>
                  </div>
                  <div className={cx(s.row, s.bottomSpacing)}>
                    <div className={s.columnSpanner} />
                    <div className={s.radioGroup}>
                      <div className={s.radioGroupBody}>
                        <RadioButton
                          id="radioTonightDesk"
                          className={s.toNight}
                          isChecked={selectedTonight}
                          name="tonight"
                          label={this.context.intl.formatMessage({
                            ...message.tonight,
                          })}
                          onChange={this.onSelectedToNight}
                        />
                        <RadioButton
                          id="radioTomorrowDesk"
                          className={s.tomorrow}
                          isChecked={selectedTomorrow}
                          name="tomorrow"
                          label={this.context.intl.formatMessage({
                            ...message.tomorrow,
                          })}
                          onChange={this.onSelectedTomorrow}
                        />
                        <div className={cx(s.nights, s.pullLeft)}>
                          <label htmlFor="nightBox">
                            <FormattedMessage {...message.nights} />
                          </label>
                          <div id="nightBox" className={s.nightBox}>
                            {checkIn && checkOut
                              ? checkOut
                                  .startOf('day')
                                  .diff(checkIn.startOf('day'), 'd')
                              : 0}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={s.row}>
                    {!withoutLocation && (
                      <Dropdown
                        className={s.city}
                        icon={{ normal: iconPin, add2x: iconPin2x }}
                        options={cities}
                        preselectedValue={selectedCity}
                        onSelectedValue={this.onSelectedCity}
                        onClick={this.onClickCity}
                        showFloatingLabel={false}
                        width="16px"
                        height="16px"
                      />
                    )}
                    <div className={cx(s.pullLeft, s.numberPickerGroup)}>
                      <div className={s.pullLeft}>
                        <label htmlFor="rooms" className={s.pullLeft}>
                          <FormattedMessage {...message.rooms} />
                        </label>
                        <NumberPicker
                          id="numberOfRooms"
                          minNumber={1}
                          maxNumber={maxNumberOfRooms}
                          number={numberOfRooms}
                          onSelectedNumber={this.onSelectedNumberOfRooms}
                          width="16px"
                          height="16px"
                        />
                      </div>
                      <div className={s.pullLeft}>
                        <label htmlFor="adults" className={s.pullLeft}>
                          <FormattedMessage {...message.adults} />
                        </label>
                        <NumberPicker
                          id="numberOfAdults"
                          minNumber={minNumberOfAdults}
                          maxNumber={maxNumberOfAdults}
                          number={adults}
                          onSelectedNumber={this.onSelectedNumberOfAdults}
                          width="16px"
                          height="16px"
                        />
                      </div>
                      <div className={s.pullLeft}>
                        <label htmlFor="children" className={s.pullLeft}>
                          <FormattedMessage {...message.children} />
                        </label>
                        <NumberPicker
                          id="numberOfChildren"
                          minNumber={0}
                          maxNumber={maxNumberOfChildren}
                          number={children}
                          onSelectedNumber={this.onSelectedNumberOfChildren}
                          width="16px"
                          height="16px"
                        />
                      </div>
                    </div>
                  </div>
                  {/* <div className={s.childDisclaimer}> */}
                  {/* <FormattedMessage {...message.childDisclaimer} /> */}
                  {/* </div> */}
                </div>
                <div className={s.colRight}>
                  {searching ? (
                    <div className={s.loadingIndicator}>
                      <LoadingIndicator color="#fff" width={100} height={100} />
                    </div>
                  ) : (
                    <Button
                      className={cx(s.btnSearch, s[this.context.intl.locale])}
                      label={this.context.intl.formatMessage(message.search)}
                    />
                  )}
                </div>
              </form>
              <div className={s.rightEdge} />
            </div>
            <div className={cx(s.guaranteed, s[this.context.intl.locale])}>
              <FormattedMessage {...message.bestPriceGuaranteed} />
            </div>
          </div>
        </div>
        <div className={cx(s.mobile, forceMobile && s.forced)}>
          <div className={s.container}>
            <div className={s.body}>
              <form>
                {!withoutLocation && (
                  <div className={s.row}>
                    <div className={s.col6}>
                      <Dropdown
                        className={s.country}
                        icon={{ normal: iconGlobe, add2x: iconGlobe2x }}
                        options={countries}
                        preselectedValue={selectedCountry}
                        onSelectedValue={this.onSelectedCountry}
                        showFloatingLabel={false}
                        width="16px"
                        height="16px"
                      />
                    </div>
                    <div className={s.col6}>
                      <Dropdown
                        className={s.city}
                        icon={{ normal: iconPin, add2x: iconPin2x }}
                        options={cities}
                        preselectedValue={selectedCity}
                        onSelectedValue={this.onSelectedCity}
                        onClick={this.onClickCity}
                        showFloatingLabel={false}
                        width="16px"
                        height="16px"
                      />
                    </div>
                  </div>
                )}
                <div className={s.row}>
                  <div className={s.col6}>
                    <TextBox
                      icon={{
                        normal: iconCalendar,
                        add2x: iconCalendarAdd2x,
                      }}
                      onClick={() => {
                        this.setState({
                          checkIn: null,
                          checkOut: null,
                          focusedInput: START_DATE,
                          currentScrollY: window.pageYOffset,
                        });
                      }}
                      value={checkIn ? checkIn.format(dateFormat) : ''}
                      placeholder={this.context.intl.formatMessage(
                        message.checkin,
                      )}
                      readOnly
                      showFloatingLabel={false}
                      width="17px"
                      height="17px"
                    />
                  </div>
                  <div className={s.col6}>
                    <TextBox
                      icon={{
                        normal: iconCalendar,
                        add2x: iconCalendarAdd2x,
                      }}
                      onClick={() => {
                        this.setState({
                          checkIn: null,
                          checkOut: null,
                          focusedInput: END_DATE,
                          currentScrollY: window.pageYOffset,
                        });
                      }}
                      value={checkOut ? checkOut.format(dateFormat) : ''}
                      placeholder={this.context.intl.formatMessage(
                        message.checkout,
                      )}
                      readOnly
                      showFloatingLabel={false}
                      width="17px"
                      height="17px"
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <div className={s.col6}>
                    <RadioButton
                      id="radioTonightMob"
                      className={s.toNight}
                      isChecked={selectedTonight}
                      name="tonight"
                      label={this.context.intl.formatMessage({
                        ...message.tonight,
                      })}
                      onChange={this.onSelectedToNight}
                    />
                  </div>
                  <div className={s.col6}>
                    <RadioButton
                      id="radioTomorrowMob"
                      className={s.tomorrow}
                      isChecked={selectedTomorrow}
                      name="tomorrow"
                      label={this.context.intl.formatMessage({
                        ...message.tomorrow,
                      })}
                      onChange={this.onSelectedTomorrow}
                    />
                  </div>
                </div>
                <div className={s.row}>
                  <div
                    className={cx(
                      s.numberPickerGroup,
                      s[this.context.intl.locale],
                    )}
                  >
                    <div className={s.pullLeft} style={{ textAlign: 'center' }}>
                      <label htmlFor="rooms">
                        <FormattedMessage {...message.rooms} />
                      </label>
                      <br />
                      <NumberPicker
                        id="numberOfRooms"
                        minNumber={1}
                        maxNumber={maxNumberOfRooms}
                        number={numberOfRooms}
                        onSelectedNumber={this.onSelectedNumberOfRooms}
                        width="16px"
                        height="16px"
                      />
                    </div>
                    <div className={s.pullLeft} style={{ textAlign: 'center' }}>
                      <label htmlFor="adults">
                        <FormattedMessage {...message.adults} />
                      </label>
                      <br />
                      <NumberPicker
                        id="numberOfAdults"
                        minNumber={minNumberOfAdults}
                        maxNumber={maxNumberOfAdults}
                        number={adults}
                        onSelectedNumber={this.onSelectedNumberOfAdults}
                        width="16px"
                        height="16px"
                      />
                    </div>
                    <div className={s.pullLeft} style={{ textAlign: 'center' }}>
                      <label htmlFor="children">
                        <FormattedMessage {...message.children} />
                      </label>
                      <br />
                      <NumberPicker
                        id="numberOfChildren"
                        minNumber={0}
                        maxNumber={maxNumberOfChildren}
                        number={children}
                        onSelectedNumber={this.onSelectedNumberOfChildren}
                        width="16px"
                        height="16px"
                      />
                    </div>
                  </div>
                  {/* <div className={s.childDisclaimer}> */}
                  {/* <FormattedMessage {...message.childDisclaimer} /> */}
                  {/* </div> */}
                </div>
              </form>
            </div>
            <div className={s.footer}>
              {searching ? (
                <div className={s.loadingIndicator}>
                  <LoadingIndicator color="#fff" width={100} height={100} />
                </div>
              ) : (
                <BookButtonMobile
                  onClick={this.onClickBookButton}
                  buttonText={buttonText}
                />
              )}
            </div>
          </div>
          <div className={s.fullScreenDatePicker}>
            <DateRangePicker
              startDateId="dateRangePickerStartMobile"
              endDateId="dateRangePickerEndMobile"
              // startDateAriaLabel="dateRangePickerStartAriaLabelMob"
              // endDateAriaLabel="dateRangePickerEndAriaLabelMob"
              orientation={VERTICAL_ORIENTATION}
              isOutsideRange={isOutsideRange}
              displayFormat={dateFormat}
              showDefaultInputIcon
              readOnly
              customInputIcon={
                <img
                  src={iconCalendar}
                  srcSet={`${iconCalendarAdd2x} 2x`}
                  alt="icon"
                  width="17px"
                  height="17px"
                />
              }
              startDatePlaceholderText={this.context.intl.formatMessage(
                message.checkin,
              )}
              endDatePlaceholderText={this.context.intl.formatMessage(
                message.checkout,
              )}
              numberOfMonths={12}
              hideKeyboardShortcutsPanel
              startDate={checkIn}
              endDate={checkOut}
              onDatesChange={this.onSelectedStayDate}
              focusedInput={isMobileDevice || forceMobile ? focusedInput : null}
              onFocusChange={this.onSelectingStayDate}
              onClose={this.onClosePicker}
              withFullScreenPortal
              isDayBlocked={day =>
                day.isBefore(minimumDate) || day.isAfter(maximumDate)
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

BookingEngine.propTypes = {
  data: PropTypes.shape({
    loading: PropTypes.bool.isRequired,
    locations: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  onInitialized: PropTypes.func,
  marginTop: PropTypes.number,
  selectedCountry: PropTypes.number,
  selectedCity: PropTypes.number,
  checkIn: PropTypes.instanceOf(moment),
  checkOut: PropTypes.instanceOf(moment),
  numberOfRooms: PropTypes.number,
  adults: PropTypes.number,
  children: PropTypes.number,
  buttonText: PropTypes.string,
  addFlashMessage: PropTypes.func,
  withoutLocation: PropTypes.bool,
  forceMobile: PropTypes.bool,
  overrideOnSearch: PropTypes.func,
  booking: BookingShape,
  clearBookingBeforeSearch: PropTypes.bool,
  clearBooking: PropTypes.func,
  scrollable: PropTypes.bool
};

BookingEngine.contextTypes = {
  intl: intlShape.isRequired,
};

BookingEngine.defaultProps = {
  marginTop: 0,
  onInitialized: () => {},
  selectedCountry: parseInt(getCookie().country, 10),
  selectedCity: parseInt(getCookie().city, 10),
  checkIn: getCookie().checkIn,
  checkOut: getCookie().checkOut,
  numberOfRooms: parseInt(getCookie().rooms, 10),
  adults: parseInt(getCookie().adults, 10),
  children: parseInt(getCookie().children, 10),
  buttonText: '',
  addFlashMessage: () => {},
  withoutLocation: false,
  forceMobile: false,
  overrideOnSearch: null,
  booking: null,
  clearBookingBeforeSearch: false,
  clearBooking: () => {},
  scrollable: true
};

const mapProps = state => ({
  booking: state.booking,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  addFlashMessage,
  clearBooking,
};

export default connect(
  mapProps,
  mapDispatch,
)(compose(withStyles(s), graphql(query))(BookingEngine));
