/* eslint-disable no-shadow */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage } from 'react-intl';

import { setLocale } from '../../actions/intl';

import s from './LanguageSwitcher.css';
import angleDown from './angle-down.webp';

const messages = defineMessages({
  en: {
    id: 'languageSwitch.en',
    defaultMessage: '##English##',
    description: 'English language. Do not translate',
  },
  id: {
    id: 'languageSwitch.id',
    defaultMessage: '##Bahasa##',
    description: 'Bahasa Indonesian language. Do not translate',
  },
  th: {
    id: 'languageSwitch.th',
    defaultMessage: '##ไทย##',
    description: 'Thai language. Do not translate',
  },
  ja: {
    id: 'languageSwitch.ja',
    defaultMessage: '##日本語##',
    description: 'Japanese language. Do not translate',
  },
  ko: {
    id: 'languageSwitch.ko',
    defaultMessage: '##한국어##',
    description: 'Korean language. Do not translate',
  },
  'zh-hans': {
    id: 'languageSwitch.zh-hans',
    defaultMessage: '##简体中文##',
    description: 'Simplified Chinese. Do not translate',
  },
  'zh-hant': {
    id: 'languageSwitch.zh-hant',
    defaultMessage: '##繁體中文##',
    description: 'Traditional Chinese. Do not translate',
  },
});

class LanguageSwitcher extends React.Component {
  static propTypes = {
    currentLocale: PropTypes.string.isRequired,
    availableLocales: PropTypes.arrayOf(PropTypes.string).isRequired,
    setLocale: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      selected: false,
      mouseOverList: false,
      mouseOverRoot: false,
    };
  }

  onClick = () => {
    this.setState({ selected: !this.state.selected });
  };

  onMouseOver = () => {
    this.setState({ mouseOver: true });
  };

  onMouseLeave = () => {
    this.setState({ mouseOver: false, selected: false });
  };

  render() {
    const { currentLocale, availableLocales, setLocale } = this.props;
    const { selected } = this.state;
    const isSelected = locale => locale === currentLocale;
    return (
      <div
        className={s.root}
        onMouseOver={this.onMouseOver}
        onMouseLeave={this.onMouseLeave}
        onFocus={this.onFocus}
      >
        <button type="button" onClick={this.onClick}>
          <h3>
            <FormattedMessage {...messages[currentLocale]} />
            <img src={angleDown} alt="angle down" width="12px" height="5px" />
          </h3>
        </button>
        {selected && (
          <ul>
            {availableLocales.map(locale => (
              <li key={locale}>
                {isSelected(locale) ? (
                  <span>
                    <FormattedMessage {...messages[locale]} />
                  </span>
                ) : (
                  <button
                    type="button"
                    onClick={e => {
                      setLocale({ locale });
                      e.preventDefault();
                    }}
                  >
                    <FormattedMessage {...messages[locale]} />
                  </button>
                )}{' '}
              </li>
            ))}
          </ul>
        )}
      </div>
    );
  }
}

const mapState = state => ({
  availableLocales: state.runtime.availableLocales,
  currentLocale: state.intl.locale,
});

const mapDispatch = {
  setLocale,
};

export default connect(mapState, mapDispatch)(withStyles(s)(LanguageSwitcher));
