// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.EditButton-root {\n  margin: -11px 30px 0 0;\n  width: 70px;\n  height: 25px;\n  color: #000;\n  background-color: #fff;\n  cursor: pointer;\n  -webkit-appearance: none;\n  border: 1px solid #da291c;\n  text-transform: uppercase;\n  font-size: 12px;\n  line-height: 16px;\n  border-radius: 2px;\n  font-family: 'Lemon Sans', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n}\n\n.EditButton-root img {\n  float: left;\n  margin-right: 5px;\n}\n\n.EditButton-root:disabled {\n  background-color: #cacaca !important;\n  cursor: not-allowed;\n}\n\n.EditButton-root:active {\n  -webkit-box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);\n          box-shadow: inset 1px 1px 5px 0 rgba(0, 0, 0, 0.3);\n}\n\n@media only screen and (min-width: 270px) {\n  .EditButton-root {\n    margin: -11px 15px 0 0;\n  }\n}\n\n@media only screen and (min-width: 768px) {\n  .EditButton-root {\n    margin: -11px 15px 0 0;\n  }\n}\n\n@media only screen and (min-width: 992px) {\n  .EditButton-root {\n    margin: -11px 30px 0 0;\n  }\n}\n\n@media only screen and (min-width: 1280px) {\n  .EditButton-root {\n    margin: -11px 30px 0 0;\n  }\n}\n", ""]);
// Exports
exports.locals = {
	"root": "EditButton-root"
};
module.exports = exports;
