/* eslint-disable css-modules/no-unused-class */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import { intlShape, FormattedMessage, defineMessages } from 'react-intl';
import { connect } from 'react-redux';

import { BookingShape, addSundryItem } from '../../actions/booking';
import SundryItem from './SundryItem';
import s from './SundrySale.css';
import { setRuntimeVariable } from '../../actions/runtime';

import iconLeft from './icon-arrow-left.webp';
import iconLeftAdd2x from './icon-arrow-left@2x.webp';
import iconRight from './icon-arrow-right.webp';
import iconRightAdd2x from './icon-arrow-right@2x.webp';

const messages = defineMessages({
  redItems: {
    id: 'sundrySale.red_items',
    defaultMessage: '##{red} Store##',
    description: 'Header for red items. Do not translate or remove {red} path'
  },
  redItemsRed: {
    id: 'sundrySale.red_items.red',
    defaultMessage: '##Convenience##',
    description: '{red} path of {red} items'
  }
});

const sundrySaleItemWidth = 128;

class SundrySale extends React.Component {
  static propTypes = {
    booking: BookingShape.isRequired,
    addSundryItem: PropTypes.func,
    setRuntimeVariable: PropTypes.func.isRequired,
    sundrySaleScrolling: PropTypes.bool.isRequired,
    imageTooltipRef: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(HTMLDivElement) })
    ])
  };

  static defaultProps = {
    booking: null,
    addSundryItem: () => {},
    sundrySaleScrolling: false
  };

  static contextTypes = {
    intl: intlShape
  };

  componentDidMount() {
    if (this.scrollPane) {
      this.scrollPane.addEventListener('scroll', this.onScrollingProducts);
    }
  }

  componentWillUnmount() {
    this.scrollPane.removeEventListener('scroll', this.onScrollingProducts);
  }

  onScrollingProducts = () => {
    if (!this.props.sundrySaleScrolling) {
      this.props.setRuntimeVariable({
        name: 'sundrySaleScrolling',
        value: true
      });
      setTimeout(() => {
        this.props.setRuntimeVariable({
          name: 'sundrySaleScrolling',
          value: false
        });
      }, 1000);
    }
  };

  onClickControl = direction => {
    if (this.scrollPane) {
      const ratio = this.scrollPane.scrollLeft / sundrySaleItemWidth;
      const round = Math.round(ratio);
      const gap = sundrySaleItemWidth * (ratio - round);
      const elements = Math.round(
        this.scrollPane.offsetWidth / sundrySaleItemWidth
      );
      const destination =
        this.scrollPane.scrollLeft +
        direction * sundrySaleItemWidth * elements -
        gap;
      this.animateScroll(destination);
    }
  };

  onPickItem = item => {
    this.props.addSundryItem(item);
    const { sundrySales } = this.props.booking;
    const index = sundrySales.indexOf(item);
    const itemPos = index * sundrySaleItemWidth;
    const rightPosInView =
      this.scrollPane.scrollLeft + this.scrollPane.offsetWidth;
    if (itemPos < this.scrollPane.scrollLeft) {
      this.animateScroll(itemPos);
    }
    if (rightPosInView < itemPos + sundrySaleItemWidth) {
      this.animateScroll(
        itemPos - this.scrollPane.offsetWidth + sundrySaleItemWidth
      );
    }
  };

  animateScroll = destination => {
    const easeInOutQuad = (t, b, c, d) => {
      let time = t / (d / 2);
      if (time < 1) return (c / 2) * time * time + b;
      time -= 1;
      return (-c / 2) * (time * (time - 2) - 1) + b;
    };
    if (this.scrollPane) {
      const duration = 0.5 * 1000;
      const start = this.scrollPane.scrollLeft;
      const diff = destination - start;
      let currentTime = 0;
      const framePerSecond = 60;
      const velocity = 1000 / framePerSecond;
      const animate = () => {
        currentTime += velocity;
        this.scrollPane.scrollLeft = easeInOutQuad(
          currentTime,
          start,
          diff,
          duration
        );
        if (currentTime < duration) {
          setTimeout(animate, velocity);
        }
      };
      animate();
    }
  };

  render() {
    const { booking } = this.props;
    if (!booking) {
      return null;
    }
    const { sundrySales, hotel } = booking;
    const additionalItems = [];
    sundrySales.forEach(item => {
      additionalItems.push(
        <li key={item.item.id} className={s.item}>
          <SundryItem
            imageTooltipRef={this.props.imageTooltipRef}
            item={item}
            hotel={hotel}
            booking={booking}
            onPickItem={this.onPickItem}
          />
        </li>
      );
    });
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <h2>
          <FormattedMessage
            {...messages.redItems}
            values={{
              red: (
                <span className={s.highlight}>
                  <FormattedMessage {...messages.redItemsRed} />
                </span>
              )
            }}
          />
        </h2>
        <div className={s.box}>
          <button
            className={cx(s.control, s.left)}
            onClick={event => {
              event.preventDefault();
              this.onClickControl(-1);
            }}
          >
            <img src={iconLeft} srcSet={`${iconLeftAdd2x} 2x`} alt="left" />
          </button>
          <button
            className={cx(s.control, s.right)}
            onClick={event => {
              event.preventDefault();
              this.onClickControl(+1);
            }}
          >
            <img src={iconRight} srcSet={`${iconRightAdd2x} 2x`} alt="right" />
          </button>
          <div
            className={s.mask}
            ref={scrollPane => {
              this.scrollPane = scrollPane;
            }}
          >
            <ul
              className={s.list}
              style={{ width: sundrySaleItemWidth * additionalItems.length }}
            >
              {additionalItems}
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  booking: state.booking,
  sundrySaleScrolling: state.runtime.sundrySaleScrolling
});

const mapDispatch = {
  addSundryItem,
  setRuntimeVariable
};

export default connect(mapState, mapDispatch)(withStyles(s)(SundrySale));
