// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./btn-facebook-repeat.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./btn-facebook-start.webp");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./btn-facebook-end.webp");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.FacebookButton-root {\n  position: relative;\n  width: 230px;\n  height: 64px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") repeat-x;\n}\n\n.FacebookButton-root.FacebookButton-th,\n.FacebookButton-root.FacebookButton-zh-hans,\n.FacebookButton-root.FacebookButton-zh-hant,\n.FacebookButton-root.FacebookButton-ko,\n.FacebookButton-root.FacebookButton-ja {\n  font-size: 0.6em;\n}\n\n.FacebookButton-root::before {\n  content: '';\n  float: left;\n  display: block;\n  width: 64px;\n  height: 64px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") no-repeat;\n}\n\n.FacebookButton-root::after {\n  content: '';\n  float: right;\n  display: block;\n  width: 8px;\n  height: 64px;\n  background: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") no-repeat;\n}\n\n.FacebookButton-root button {\n  position: absolute;\n  -webkit-appearance: none;\n  border: none;\n  background: none;\n  top: 0;\n  right: 0;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  font-size: 1.5em;\n  color: #fff;\n  cursor: pointer;\n  padding-left: 49px;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "FacebookButton-root",
	"th": "FacebookButton-th",
	"zh-hans": "FacebookButton-zh-hans",
	"zh-hant": "FacebookButton-zh-hant",
	"ko": "FacebookButton-ko",
	"ja": "FacebookButton-ja"
};
module.exports = exports;
