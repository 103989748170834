/**
 * Created by methee on 9/5/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

class HotelAddress extends React.Component {
  static propTypes = {
    address: PropTypes.string.isRequired,
  };

  render() {
    const { address } = this.props;
    const lines = address.split('\n');
    return (
      <span>
        {lines.map(line =>
          <p key={line}>
            {line}
          </p>,
        )}
      </span>
    );
  }
}

export default HotelAddress;
