/* eslint-disable camelcase, no-nested-ternary */

import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import cx from 'classnames';

import { BookingShape } from '../../actions/booking';
// import mp from '../../analytic';
import history from '../../history';
import EditButton from '../../routes/check-in-guest_detail/EditButton';
import CheckInCalendars from '../CheckInCalendars';
import CheckInTotalPrice from '../CheckInTotalPrice';
import s from './CheckInBookingSummary.css';
import CheckInCard from '../CheckInCard';
import cardHolderIcon from './icon-guest_id@1x.webp';
import cardHolderIconAdd2x from './icon-guest_id@2x.webp';
import iconGuest from './icon-guest@1x.webp';
import iconGuestAdd2x from './icon-guest@2x.webp';
import iconRoomType from './icon-roomtype@1x.webp';
import iconRoomTypeAdd2x from './icon-roomtype@2x.webp';
import iconBreakfast from './icon-breakfast@1x.webp';
import iconBreakfastAdd2x from './icon-breakfast@2x.webp';
import iconSmoking from './icon-smoking.webp';
import iconSmokingAdd2x from './icon-smoking@2x.webp';
import iconPetFriendly from './icon-petfriendly.webp';
import iconPetFriendlyAdd2x from './icon-petfriendly@2x.webp';
import iconShoppingCart from './icon-shoppingcart@1x.webp';
import iconShoppingCartAdd2x from './icon-shoppingcart@2x.webp';
import iconPayment from './icon-payment@1x.webp';
import iconPaymentAdd2x from './icon-payment@2x.webp';
import iconPreferences from './icon-preferences@1x.webp';
import iconPreferencesAdd2x from './icon-preferences@2x.webp';
import amex from './amex.webp';
import amexAdd2x from './amex@2x.webp';
import cup from './cup.webp';
import cupAdd2x from './cup@2x.webp';
import master from './master.webp';
import masterAdd2x from './master@2x.webp';
import jcb from './jcb.webp';
import jcbAdd2x from './jcb@2x.webp';
import visa from './visa.webp';
import visaAdd2x from './visa@2x.webp';
import iconPaid from './icon-paid.webp';
import iconPaidAdd2x from './icon-paid@2x.webp';

const cardImages = {
  amex: {
    normal: amex,
    add2x: amexAdd2x,
  },
  cup: {
    normal: cup,
    add2x: cupAdd2x,
  },
  master: {
    normal: master,
    add2x: masterAdd2x,
  },
  jcb: {
    normal: jcb,
    add2x: jcbAdd2x,
  },
  visa: {
    normal: visa,
    add2x: visaAdd2x,
  },
};

const messages = defineMessages({
  fullName: {
    id: 'ProjectHello.full_name',
    defaultMessage: '##Red Planet {hotelName}##',
    description: 'Hotel full name. Do not translate or remove {hotelName}',
  },
  checkIn: {
    id: 'ProjectHello.checkIn',
    defaultMessage: '##Check In##',
    description: 'Check in title on price summary box',
  },
  checkOut: {
    id: 'ProjectHello.checkOut',
    defaultMessage: '##Check Out##',
    description: 'Check Out title on price summary box',
  },
  nameOnCard: {
    id: 'ProjectHello.creditCard.nameOnCard',
    defaultMessage: '##Name on card##',
    description: 'Label for name on card',
  },
  guests: {
    id: 'ProjectHello.guests',
    defaultMessage: '##Guest(s)##',
    description: 'Guest(s) label in booking detail',
  },
  adultCount: {
    id: 'price_summary.adultCount',
    defaultMessage:
      '{adultCount, plural, zero {{value} {adults}} one {{value} {adult}} other {{value} {adults}}}',
    description: 'Ignore this phrase',
  },
  adult: {
    id: 'price_summary.adult',
    defaultMessage: '##Adult##',
    description: 'Adult count',
  },
  adults: {
    id: 'price_summary.adults',
    defaultMessage: '##Adults##',
    description: 'Adults count',
  },
  childCount: {
    id: 'price_summary.childCount',
    defaultMessage:
      '{childCount, plural, zero {{value} {children}} one {{value} {child}} other {{value} {children}}}',
    description: 'Ignore this phrase',
  },
  child: {
    id: 'price_summary.child',
    defaultMessage: '##Child##',
    description: 'Child count',
  },
  children: {
    id: 'price_summary.children',
    defaultMessage: '##Children##',
    description: 'Children count',
  },
  roomType: {
    id: 'ProjectHello.roomType',
    defaultMessage: '##Room Type##',
    description: 'Room type label in booking detail',
  },
  breakfast: {
    id: 'ProjectHello.breakfast',
    defaultMessage: '##Breakfast##',
    description: 'Breakfast price label on price summary',
  },
  smoking: {
    id: 'ProjectHello.smoking',
    defaultMessage: '##Smoking##',
    description: 'Smoking label on price summary',
  },
  petFriendly: {
    id: 'ProjectHello.petFriendly',
    defaultMessage: '##Pet Friendly##',
    description: 'Pet Friendly label on price summary',
  },
  breakfastNotIncluded: {
    id: 'ProjectHello.breakfastNotIncluded',
    defaultMessage: '##{breakfastName} - not included##',
    description: 'Breakfast price label on price summary',
  },
  petFriendlyNotIncluded: {
    id: 'ProjectHello.petFriendlyNotIncluded',
    defaultMessage: '##Pet Friendly - not included##',
    description: 'Pet Friendly label on price summary',
  },
  convenienceStore: {
    id: 'ProjectHello.convenienceStore',
    defaultMessage: '##Convenient Store##',
    description: 'Convenient store label on booking detail',
  },
  convenienceStoreIncluded: {
    id: 'ProjectHello.convenienceStoreIncluded',
    defaultMessage: '##Please pick up your items at the front desk##',
    description: 'Convenient store included booking detail',
  },
  payment: {
    id: 'ProjectHello.payment',
    defaultMessage: '##Payment##',
    description: 'Payment label on booking detail',
  },
  paid: {
    id: 'ProjectHello.paid',
    defaultMessage:
      'You have already paid for your booking.<br/>Please continue to check-in.',
    description: 'Paid payment indicator',
  },
  paymentNeeded: {
    id: 'ProjectHello.paymentNeeded',
    defaultMessage:
      '##Your booking must be paid before  continue to check in##',
    description: 'Payment needed indicator',
  },
  preferences: {
    id: 'ProjectHello.preferences',
    defaultMessage: '##Preferences##',
    description: 'Label for room preferences',
  },
  low: {
    id: 'ProjectHello.lowFloor',
    defaultMessage: '##Low Floor##',
    description: 'Label for lower floor button',
  },
  high: {
    id: 'ProjectHello.highFloor',
    defaultMessage: '##High Floor##',
    description: 'Label for higher floor button',
  },
  near: {
    id: 'ProjectHello.nearLift',
    defaultMessage: '##Close to Elevator##',
    description: 'Label for Close to Elevator button',
  },
  far: {
    id: 'ProjectHello.farLift',
    defaultMessage: '##Away from Elevator##',
    description: 'Label for Away from Elevator button',
  },
  elevator: {
    id: 'ProjectHello.elevator',
    defaultMessage: '##Elevator##',
    description: 'Label elevator',
  },
  estimatedArrivalTime: {
    id: 'ProjectHello.estimatedArrivalTime',
    defaultMessage: '##Estimated Arrival Time##',
    description: 'Label for estimated arrival time',
  },
  total: {
    id: 'ProjectHello.total',
    defaultMessage: '##Total##',
    description: 'Total label on price summary',
  },
  includeTaxAndServiceCharge: {
    id: 'ProjectHello.includeTaxAndServiceCharge',
    defaultMessage: '##Included Tax & Service Charge##',
    description: 'Total label on price summary',
  },
});

class CheckInBookingSummary extends React.Component {
  static propTypes = {
    booking: BookingShape,
    showGuestName: PropTypes.bool,
    showGuestCount: PropTypes.bool,
    showRoomType: PropTypes.bool,
    showBreakfast: PropTypes.bool,
    showSmoking: PropTypes.bool,
    showPetFriendly: PropTypes.bool,
    showPaidText: PropTypes.bool,
    cardHolderName: PropTypes.string,
    roomPreferences: PropTypes.shape({
      floor: PropTypes.string,
      elevator: PropTypes.string,
      arrivalTime: PropTypes.string,
    }),
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    booking: null,
    showGuestName: true,
    showGuestCount: true,
    showRoomType: true,
    showBreakfast: true,
    showSmoking: true,
    showPetFriendly: true,
    showPaidText: true,
    cardHolderName: '',
    roomPreferences: null,
  };

  onClickEditRoomPreferences = () => {
    const {
      booking: { booking_id },
    } = this.props;
    // mp.track('H Summary: Edit Room Prefs');
    history.push({
      pathname: `/check-in/${booking_id}/room_preference`,
    });
  };

  render() {
    const {
      booking,
      showGuestName,
      showGuestCount,
      showRoomType,
      showBreakfast,
      showSmoking,
      showPetFriendly,
      showPaidText,
      cardHolderName,
      roomPreferences,
    } = this.props;
    const { hotel } = booking;
    let adults = 0;
    let child = 0;
    let name = '';
    const rts = {};
    booking.rooms.forEach(room => {
      if (!rts[room.name]) {
        rts[room.name] = 0;
      }
      rts[room.name] += 1;
      room.guests.forEach(guest => {
        if (!name) {
          name = `${guest.first_name} ${guest.last_name}`;
        }
        if (guest.type === 'Adult') {
          adults += 1;
        }
        if (guest.type === 'Child') {
          child += 1;
        }
      });
    });
    const roomTypes = [];
    Object.entries(rts).forEach(rt => {
      roomTypes.push(
        <li key={rt[0]}>
          <span className={s.highlight}>{rt[1]}</span> {rt[0]}
        </li>,
      );
    });
    let estimatedArrivalTime = null;
    if (roomPreferences) {
      const a = moment(roomPreferences.arrivalTime, 'HH:mm:ss');
      estimatedArrivalTime = `${a.format('HH:mm')} - ${a
        .add(1, 'h')
        .format('HH:mm')}`;
    }
    return (
      <div className={s.root}>
        {showGuestName && <div className={s.name}>{name}</div>}
        <div className={s.summary}>
          <hr />
          <h2 className={s.hotelName}>
            <FormattedMessage
              {...messages.fullName}
              values={{
                hotelName: <span style={{ color: '#000' }}>{hotel.name}</span>,
              }}
            />
          </h2>
          <hr />
          {cardHolderName !== '' && (
            <div className={s.row}>
              <CheckInCard
                icon={{ normal: cardHolderIcon, add2x: cardHolderIconAdd2x }}
                label={this.context.intl.formatMessage(messages.nameOnCard)}
                value={cardHolderName}
              />
            </div>
          )}
          <CheckInCalendars booking={booking} />
          {showGuestCount && (
            <div className={s.row}>
              <CheckInCard
                icon={{ normal: iconGuest, add2x: iconGuestAdd2x }}
                label={this.context.intl.formatMessage(messages.guests)}
                value={
                  <div>
                    <FormattedMessage
                      {...messages.adultCount}
                      values={{
                        value: <span className={s.highlight}>{adults}</span>,
                        adultCount: adults,
                        adult: this.context.intl.formatMessage(messages.adult),
                        adults: this.context.intl.formatMessage(
                          messages.adults,
                        ),
                      }}
                    />
                    &nbsp;
                    {child > 0 && (
                      <FormattedMessage
                        {...messages.childCount}
                        values={{
                          value: <span className={s.highlight}>{child}</span>,
                          childCount: child,
                          child: this.context.intl.formatMessage(
                            messages.child,
                          ),
                          children: this.context.intl.formatMessage(
                            messages.children,
                          ),
                        }}
                      />
                    )}
                  </div>
                }
              />
            </div>
          )}
          {showRoomType && (
            <div className={s.row}>
              <CheckInCard
                icon={{ normal: iconRoomType, add2x: iconRoomTypeAdd2x }}
                label={this.context.intl.formatMessage(messages.roomType)}
                value={<ul>{roomTypes}</ul>}
              />
            </div>
          )}
          {showBreakfast && hotel.breakfast && (
            <div className={s.row}>
              <CheckInCard
                icon={{ normal: iconBreakfast, add2x: iconBreakfastAdd2x }}
                label={this.context.intl.formatMessage(messages.breakfast)}
                value={
                  booking.breakfast ? (
                    booking.breakfast.name
                  ) : (
                    <FormattedMessage
                      {...messages.breakfastNotIncluded}
                      values={{
                        breakfastName: hotel.breakfast.name,
                      }}
                    />
                  )
                }
              />
            </div>
          )}
          {showSmoking && booking.smoking && (
            <div className={s.row}>
              <CheckInCard
                icon={{ normal: iconSmoking, add2x: iconSmokingAdd2x }}
                label={this.context.intl.formatMessage(messages.smoking)}
                value={
                  booking.smoking ? (
                    'Includ Smoking'
                  ) : (
                    <FormattedMessage
                      {...messages.smokingNotIncluded}
                      values="not including Smoking"
                    />
                  )
                }
              />
            </div>
          )}
          {showPetFriendly && booking.pet_friendly && (
            <div className={s.row}>
              <CheckInCard
                icon={{ normal: iconPetFriendly, add2x: iconPetFriendlyAdd2x }}
                label={this.context.intl.formatMessage(messages.petFriendly)}
                value={
                  booking.pet_friendly ? (
                    'Includ Pet Friendly'
                  ) : (
                    <FormattedMessage
                      {...messages.petFriendlyNotIncluded}
                      values="not including Pet Friendly"
                    />
                  )
                }
              />
            </div>
          )}
          {showBreakfast && booking.additional_products && (
            <div className={s.row}>
              <CheckInCard
                icon={{
                  normal: iconShoppingCart,
                  add2x: iconShoppingCartAdd2x,
                }}
                label={this.context.intl.formatMessage(
                  messages.convenienceStore,
                )}
                value={this.context.intl.formatMessage(
                  messages.convenienceStoreIncluded,
                )}
              />
            </div>
          )}
          <div className={cx(s.row, !roomPreferences && s.redDot)}>
            <div className={cx(s.paidLeft, booking.paid_at && s.paid)}>
              <CheckInCard
                icon={{ normal: iconPayment, add2x: iconPaymentAdd2x }}
                label={this.context.intl.formatMessage(messages.payment)}
                value={
                  booking.paid_at ? (
                    booking.card_type ? (
                      <div>
                        <div className={s.cardNumber}>
                          <img
                            style={{ paddingRight: '10px' }}
                            src={cardImages[booking.card_type].normal}
                            srcSet={`${cardImages[booking.card_type].add2x} 2x`}
                            alt={booking.card_type}
                          />{' '}
                          •••• {booking.suffix_card_number}
                        </div>
                      </div>
                    ) : (
                      <div>
                        {showPaidText && (
                          <div
                            className={s.paidDesc}
                            dangerouslySetInnerHTML={{
                              __html: this.context.intl.formatMessage(
                                messages.paid,
                              ),
                            }}
                          />
                        )}
                      </div>
                    )
                  ) : (
                    this.context.intl.formatMessage(messages.paymentNeeded)
                  )
                }
              />
            </div>
            {booking.paid_at && (
              <div className={s.paidIcon}>
                <img src={iconPaid} srcSet={`${iconPaidAdd2x} 2x`} alt="paid" />
              </div>
            )}
          </div>
          {roomPreferences && (
            <div className={cx(s.row, roomPreferences && s.redDot)}>
              <CheckInCard
                icon={{
                  normal: iconPreferences,
                  add2x: iconPreferencesAdd2x,
                }}
                label={this.context.intl.formatMessage(messages.preferences)}
                value={
                  <div>
                    <ul>
                      {(roomPreferences.floor === 'low' ||
                        roomPreferences.floor === 'high') && (
                        <li>
                          <FormattedMessage
                            {...messages[roomPreferences.floor]}
                          />
                        </li>
                      )}
                      {(roomPreferences.elevator === 'near' ||
                        roomPreferences.elevator === 'far') && (
                        <li>
                          <FormattedMessage
                            {...messages[roomPreferences.elevator]}
                          />
                        </li>
                      )}
                      <li>
                        <FormattedMessage {...messages.estimatedArrivalTime} />
                        {': '}
                        {estimatedArrivalTime}
                      </li>
                    </ul>
                    <EditButton
                      className={s.btnEdit}
                      onClick={this.onClickEditRoomPreferences}
                    />
                  </div>
                }
              />
            </div>
          )}
          <div className={cx(s.row, s.redDot)}>
            <CheckInTotalPrice booking={booking} />
          </div>
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CheckInBookingSummary);
