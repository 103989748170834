import React from 'react';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import moment from 'moment';
import { defineMessages, intlShape, FormattedMessage } from 'react-intl';
import cx from 'classnames';

import { BookingShape } from '../../actions/booking';
import s from './CheckInCalendars.css';
import CheckInCard from '../CheckInCard';
import iconCheckIn from './icon-calendar-checkin@1x.webp';
import iconCheckInAdd2x from './icon-calendar-checkin@2x.webp';
import iconCheckOut from './icon-calendar-checkout@1x.webp';
import iconCheckOutAdd2x from './icon-calendar-checkout@2x.webp';

const messages = defineMessages({
  checkIn: {
    id: 'ProjectHello.checkIn',
    defaultMessage: '##Check In##',
    description: 'Check in title on price summary box',
  },
  checkOut: {
    id: 'ProjectHello.checkOut',
    defaultMessage: '##Check Out##',
    description: 'Check Out title on price summary box',
  },
  nightCount: {
    id: 'price_summary.stayNights',
    defaultMessage:
      '{nightCount, plural, zero {{nightCount} {nights}} one {{nightCount} {night}} other {{nightCount} {nights}}}',
    description: 'Ignore this phrase',
  },
  night: {
    id: 'price_summary.night',
    defaultMessage: '##Night##',
    description: 'Night count',
  },
  nights: {
    id: 'price_summary.nights',
    defaultMessage: '##Nights##',
    description: 'Nights count',
  },
});

class CheckInCalendars extends React.Component {
  static propTypes = {
    booking: BookingShape,
  };

  static contextTypes = {
    intl: intlShape,
  };

  static defaultProps = {
    booking: null,
    roomPreferences: null,
  };

  render() {
    const { booking } = this.props;
    const { check_in, check_out } = booking;
    const checkIn = moment(check_in);
    const checkOut = moment(check_out);
    return (
      <div className={s.root}>
        <div className={cx(s.col, s.ruler)}>
          <CheckInCard
            icon={{ normal: iconCheckIn, add2x: iconCheckInAdd2x }}
            label={this.context.intl.formatMessage(messages.checkIn)}
            value={checkIn.format('ddd, DD MMM YYYY')}
          />
        </div>
        <div className={s.col}>
          <CheckInCard
            icon={{ normal: iconCheckOut, add2x: iconCheckOutAdd2x }}
            label={this.context.intl.formatMessage(messages.checkOut)}
            value={checkOut.format('ddd, DD MMM YYYY')}
          />
        </div>
        <div className={s.night}>
          (
          <FormattedMessage
            {...messages.nightCount}
            values={{
              nightCount: checkOut.diff(checkIn, 'd'),
              night: this.context.intl.formatMessage(messages.night),
              nights: this.context.intl.formatMessage(messages.nights),
            }}
          />
          )
        </div>
      </div>
    );
  }
}

export default withStyles(s)(CheckInCalendars);
