/* eslint-disable no-undef */
/* eslint-disable camelcase */
/* eslint-disable global-require */

import jwt from 'jsonwebtoken';

async function createTokenPaymentOmise(key, card) {
  const omise = require('omise')({
    publicKey: key.publicKey,
    omiseVersion: '2019-05-29',
  });

  const cardDetails = {
    card: {
      name: card.holderName,
      number: card.number,
      expiration_month: card.expiryMonth,
      expiration_year: card.expiryYear,
      security_code: card.cvc,
    },
  };

  const promise = new Promise((resolve, reject) => {
    omise.tokens.create(cardDetails, (err, resp) => {
      if (err) {
        reject(Error('Promise rejected'));
      }
      resolve(resp.id);
    });
  });

  const token = await promise.then(result => result);

  return token;
}

async function createTokenPaymentMaya(key, card) {
  const cardDetails = {
    first_name: card.first_name,
    last_name: card.last_name,
    credit_card_number: card.number,
    expiration_date: card.expiry,
    verification_code: card.cvc,
  };
  const token = jwt.sign(cardDetails, key.privateKey, {
    algorithm: 'HS256',
    expiresIn: 30 * 60,
  });
  return token;
}

export { createTokenPaymentOmise, createTokenPaymentMaya };
