/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';
import s from './CheckBox.scss';

class CheckBox extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    onChange: PropTypes.func,
    isChecked: PropTypes.bool,
  };

  static defaultProps = {
    className: '',
    name: '',
    label: '',
    onChange: () => {},
    isChecked: false,
  };

  handleChange = event => {
    if (this.props.onChange) {
      this.props.onChange({
        name: this.props.name,
        value: event.target.checked,
      });
    }
  };

  render() {
    return (
      <div className={cx(s.root, this.props.className)}>
        <label className={s.checkbox}>
          <input
            type="checkbox"
            onChange={this.handleChange}
            checked={this.props.isChecked}
          />
          <span>{this.props.label}</span>
        </label>
      </div>
    );
  }
}

export default withStyles(s)(CheckBox);
