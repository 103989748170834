/**
 * Created by methee on 8/4/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { intlShape } from 'react-intl';

import s from './Button.css';

class Button extends React.Component {
  static propTypes = {
    onClick: PropTypes.func,
    icon: PropTypes.shape({
      normal: PropTypes.string,
      add2x: PropTypes.string,
    }),
    label: PropTypes.string,
    className: PropTypes.string,
    disabled: PropTypes.bool,
  };

  static defaultProps = {
    onClick: () => {},
    className: '',
    icon: null,
    label: '',
    disabled: false,
  };

  static contextTypes = {
    intl: intlShape,
  };

  onClick = () => {
    if (!this.props.disabled) {
      this.props.onClick();
    }
  };

  render() {
    return (
      <button
        className={cx(
          s.root,
          this.props.className,
          this.context.intl.locale,
          'd-flex',
          'justify-content-center',
          'align-self-center',
        )}
        onClick={this.onClick}
        disabled={this.props.disabled}
      >
        {this.props.icon && (
          <img
            src={this.props.icon.normal}
            srcSet={`${this.props.icon.add2x} 2x`}
            alt={this.props.label}
          />
        )}
        <span>{this.props.label}</span>
      </button>
    );
  }
}

export default withStyles(s)(Button);
