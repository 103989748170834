/* eslint-disable css-modules/no-unused-class */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import cx from 'classnames';
import { intlShape } from 'react-intl';
import s from './Popup.css';
import { OPEN_POPUP_MODAL, CLOSE_POPUP_MODAL } from '../../constants';

// const disablePopupWhenIdleFor = 100 * 60 * 60;

class Popup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      lang: {
        english: true,
        japanese: false,
        bahasa: false,
        thai: false,
      },
      langLabel: 'english',
    };

    this.onClickLang = this.onClickLang.bind(this);
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      this.bindOnKeydown = this.onKeydown.bind(this);
      window.addEventListener('keydown', this.bindOnKeydown);
    }
  }

  onClickLang(e) {
    this.state.lang = {
      english: false,
      japanese: false,
      bahasa: false,
      thai: false,
    };
    this.state.lang[e.name] = !this.state.lang[e.name];
    this.state.langLabel = e.name;
    this.setState(prevState => ({ lang: prevState.lang }));
  }

  onCloseModal = () => {
    this.props.closeModal();
    // window.removeEventListener('keydown', this.bindOnKeydown);
    // if (!this.props.isLoggedIn && window.localStorage) {
    //   window.localStorage.setItem('popup_marketing', new Date().getTime());
    // }
  };

  onKeydown(event) {
    if (event.keyCode === 27) {
      this.onCloseModal();
    }
  }

  onClickSubmit = () => {
    this.onCloseModal();
  };

  render() {
    const {
      pop_up_img,
      pop_up_img_responsive,
      pop_up_call_to_action_url,
    } = this.props.dataPopup;

    return (
      <div className={cx(s.root)}>
        <div className={s.body}>
          <div className={s.content}>
            <div className={s.hotelContainer}>
              <button
                type="button"
                className={s.closeButton}
                onClick={this.onCloseModal}
              >
                ×
              </button>
              {pop_up_call_to_action_url ? (
                <a
                  href={`${pop_up_call_to_action_url}`}
                  hrefLang={this.context.intl.locale}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    className={s.hotelImgDesk}
                    alt="Red Planet Hotel BGC The Fort"
                    src={pop_up_img}
                    style={{ objectFit: 'cover' }}
                  />
                  <img
                    className={s.hotelImgMob}
                    alt="Red Planet Hotel BGC The Fort"
                    src={pop_up_img_responsive}
                    style={{ objectFit: 'cover' }}
                  />
                </a>
              ) : (
                <div>
                  <img
                    className={s.hotelImgDesk}
                    alt="Red Planet Hotel BGC The Fort"
                    src={pop_up_img}
                    style={{ objectFit: 'cover' }}
                  />
                  <img
                    className={s.hotelImgMob}
                    alt="Red Planet Hotel BGC The Fort"
                    src={pop_up_img_responsive}
                    style={{ objectFit: 'cover' }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Popup.propTypes = {
  closeModal: PropTypes.func,
  dataPopup: PropTypes.shape().isRequired,
  isLoggedIn: PropTypes.bool,
};

Popup.contextTypes = {
  intl: intlShape,
};

Popup.defaultProps = {
  closeModal: () => {},
  isLoggedIn: false,
};

export function openModal() {
  return async (dispatch, getState) => {
    const { user } = getState();
    if (!window.localStorage || user.openPopupModal) {
      return;
    }
    dispatch({
      type: OPEN_POPUP_MODAL,
    });
    // const lastOpenForIdle = window.localStorage.getItem('popup_marketing');
    // const openForIdleGuest =
    //   !user.isLoggedIn &&
    //   (!lastOpenForIdle ||
    //     (lastOpenForIdle &&
    //       new Date().getTime() - lastOpenForIdle >= disablePopupWhenIdleFor));
    // const fromFooter = !idleUser || (!idleUser && user.isLoggedIn);

    // if (openForIdleGuest || fromFooter) {
    //   dispatch({
    //     type: OPEN_POPUP_MODAL,
    //     payload: {
    //       source: idleUser ? 'idle_popup' : 'footer',
    //     },
    //   });
    //   window.localStorage.removeItem('popup_marketing');
    // }
  };
}

function closeModal() {
  return async dispatch => {
    dispatch({
      type: CLOSE_POPUP_MODAL,
    });
  };
}

const mapDispatch = {
  closeModal,
};

const mapState = state => ({
  source: state.user.popupSource,
  isLoggedIn: state.user.isLoggedIn,
});

export default connect(mapState, mapDispatch)(withStyles(s)(Popup));
