/* eslint-disable react/static-property-placement */
/**
 * Created by methee on 8/2/17.
 */
import React from 'react';
import PropTypes from 'prop-types';

class Option extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.string.isRequired,
    hidden: PropTypes.bool,
  };

  static defaultProps = {
    hidden: false,
  };

  render() {
    const { hidden } = this.props;
    return (
      <option value={this.props.value} hidden={hidden} disabled={hidden}>
        {this.props.name}
      </option>
    );
  }
}

export default Option;
