// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.BlueNoticeBox-root {\n  padding: 20px;\n  margin: 0 15px;\n  background-color: #def5fa;\n  border-radius: 4px;\n  font-family: 'Lemon Sans', 'HelveticaNeue-Light', 'Thai Sans Neue', 'Meiryo', sans-serif;\n  font-size: 18px;\n  line-height: 18px;\n  font-weight: lighter;\n  text-align: center;\n}\n\n.BlueNoticeBox-root::after {\n  content: '';\n  display: table;\n  clear: both;\n}\n\n.BlueNoticeBox-root a {\n  color: #da291c;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "BlueNoticeBox-root"
};
module.exports = exports;
