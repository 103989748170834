/**
 * Created by methee on 7/27/17.
 */

import {
  GraphQLObjectType as ObjectType,
  GraphQLString as StringType,
  GraphQLBoolean as BooleanType,
  GraphQLNonNull as NonNull,
  GraphQLList as List,
} from 'graphql';

import PropTypes from 'prop-types';

const PromotionType = new ObjectType({
  name: 'Promotion',
  fields: {
    code: { type: StringType },
    description: { type: new NonNull(StringType) },
    secondary_description: { type: StringType },
    terms: { type: StringType },
    url: { type: StringType },
    url_path: { type: StringType },
    url_query: { type: StringType },
    image_url: { type: StringType },
    mini_image_url: { type: StringType },
    image_mobile_url: { type: StringType },
    button_title: { type: StringType },
    valid_date: { type: StringType },
    expire_date: { type: StringType },
    stay_in_date: { type: StringType },
    stay_out_date: { type: StringType },
    show_stay_date: { type: BooleanType },
    show_book_period: { type: BooleanType },
    payment_name: { type: StringType },
    payment_methods: { type: new List(new NonNull(StringType)) },
  },
});

PromotionType.shape = () =>
  PropTypes.shape({
    code: PropTypes.string,
    description: PropTypes.string,
    secondary_description: PropTypes.string,
    terms: PropTypes.string,
    image_url: PropTypes.string,
    url: PropTypes.string,
    button_title: PropTypes.string,
    valid_date: PropTypes.string,
    expire_date: PropTypes.string,
    stay_in_date: PropTypes.string,
    stay_out_date: PropTypes.string,
    show_stay_date: PropTypes.bool,
    show_book_period: PropTypes.bool,
    payment_name: PropTypes.string,
    payment_methods: PropTypes.arrayOf(PropTypes.string),
  });

export default PromotionType;
