/**
 * Created by methee on 9/2/17.
 */
/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage } from 'react-intl';

import s from './ErrorMessages.css';

const messages = defineMessages({
  general: {
    id: 'errorMessage.general',
    defaultMessage: '##Please check the highlighted fields##',
    description: 'General error description',
  },
});

export const ERROR_GENERAL = 'general';

class ErrorMessages extends React.Component {
  static propTypes = {
    errorKey: PropTypes.string,
    errorMessage: PropTypes.string,
    fontColor: PropTypes.string,
    page: PropTypes.string,
  };

  static defaultProps = {
    errorKey: ERROR_GENERAL,
    errorMessage: '',
    fontColor: '',
    page: '',
  };

  render() {
    const { errorKey, errorMessage, fontColor, page } = this.props;
    const message = messages[errorKey];
    const attr = { style: {} };
    if (fontColor !== '') {
      attr.style.color = fontColor;
    }
    if (page === 'payment' || page === 'guestProfile') {
      attr.style.backgroundColor = 'yellow';
      attr.style.padding = '10px 10px 10px 10px';
      attr.style.marginBottom = '10px';
    }
    return (
      <div className={s.root} {...attr}>
        {errorMessage === '' && <FormattedMessage {...message} />}
        {errorMessage !== '' && (
          <span
            dangerouslySetInnerHTML={{
              __html: errorMessage,
            }}
          />
        )}
      </div>
    );
  }
}

export default withStyles(s)(ErrorMessages);
