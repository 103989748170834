/* eslint-disable react/button-has-type */
/* eslint-disable react/static-property-placement */
import React from 'react';
import PropTypes from 'prop-types';

class Option extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    name: PropTypes.number.isRequired,
    onClick: PropTypes.func.isRequired,
  };

  onClick = event => {
    event.stopPropagation();
    event.preventDefault();
    this.props.onClick(this.props.value);
  };

  render() {
    return (
      <li value={this.props.value}>
        <button onClick={this.onClick}>{this.props.name}</button>
      </li>
    );
  }
}

export default Option;
