/* eslint-disable css-modules/no-unused-class */
/**
 * Created by methee on 8/2/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, intlShape } from 'react-intl';
import cx from 'classnames';

import withStyles from 'isomorphic-style-loader/lib/withStyles';
import Button from '../Button';
import s from './BookButtonMobile.css';

const message = defineMessages({
  search: {
    id: 'booking_engine.book_now',
    defaultMessage: '##Search##',
    description:
      'Label on search button in room-select enigine on mobile version',
  },
});

class BookButtonMobile extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    buttonText: PropTypes.string,
  };

  static defaultProps = {
    className: '',
    buttonText: '',
  };

  static contextTypes = {
    intl: intlShape,
  };

  onClick = () => {
    this.props.onClick();
  };

  render() {
    const { buttonText } = this.props;
    return (
      <div
        className={cx(
          s.root,
          this.props.className,
          s[this.context.intl.locale],
        )}
      >
        <Button
          onClick={this.onClick}
          label={buttonText || this.context.intl.formatMessage(message.search)}
        />
      </div>
    );
  }
}

export default withStyles(s)(BookButtonMobile);
