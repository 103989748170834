/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import cx from 'classnames';

import s from './CheckInProgressBadge.css';
import checkMark from './icon-checkmark.webp';
import checkMarkAdd2x from './icon-checkmark@2x.webp';

class CheckInProgressStatus extends React.Component {
  static propTypes = {
    badgeNumber: PropTypes.number,
    label: PropTypes.string,
    selected: PropTypes.bool,
    passed: PropTypes.bool,
  };

  static defaultProps = {
    badgeNumber: 1,
    label: 'Booking Details',
    selected: false,
    passed: false,
  };

  render() {
    const { badgeNumber, label, selected, passed } = this.props;
    return (
      <div
        className={cx(
          s.root,
          selected ? s.selected : '',
          passed ? s.passed : '',
        )}
      >
        <div className={s.badge} />
        <div className={s.number}>
          {!passed ? (
            badgeNumber
          ) : (
            <img
              src={checkMark}
              srcSet={`${checkMarkAdd2x} 2x`}
              alt="checkmark"
            />
          )}
        </div>
        <div className={s.label}>{label}</div>
      </div>
    );
  }
}

export default withStyles(s)(CheckInProgressStatus);
