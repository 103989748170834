/**
 * Created by methee on 8/21/17.
 */
/* eslint-disable react/sort-comp */
/* eslint-disable react/static-property-placement */
/* eslint-disable react/no-unused-state */
/* eslint-disable css-modules/no-unused-class */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import cx from 'classnames';

import RoomType from '../../data/types/RoomType';
import {
  changeRoom,
  BookingShape,
  MaxNumberOfRooms,
  PAYMENT_CREDIT_CARD,
  PAYMENT_WALLET,
  PAYMENT_PAY_AT_HOTEL,
} from '../../actions/booking';

import ImageGallery from '../../components/ImageGallery';
import NumberPicker from '../../components/NumberPicker';
import CurrencyDisplay from '../../components/CurrencyDisplay';

import s from './RoomCard.css';

const messages = defineMessages({
  rooms: {
    id: 'booking.roomCard.rooms',
    defaultMessage: '##Rooms##',
    description: 'Label for number of room picker on room card',
  },
  exclusiveRate: {
    id: 'booking.roomCard.exclusiveRate',
    defaultMessage: '##Remembe{red} exclusive rate##',
    description:
      'Remembered rate indicator on Room CheckInCard. Do not remove or translate {red} path. It is a placeholder for highlight red path',
  },
  exclusiveRateRed: {
    id: 'booking.roomCard.exclusiveRateRed',
    defaultMessage: '##Red##',
    description:
      "'red' path of Remembe{red} exclusive rate and Non Remembe{red} rate",
  },
  joinForFree: {
    id: 'booking.roomCard.joinForFree',
    defaultMessage: '##Join for free!##',
    description: "Text 'Join for free' in Room CheckInCard",
  },
  night: {
    id: 'booking.roomCard.night',
    defaultMessage: '##Night##',
    description: 'Night for price per night in room card',
  },
  select: {
    id: 'booking.roomCard.select',
    defaultMessage: '##Select##',
    description: 'Select room button',
  },
  roomFeatures: {
    id: 'booking.roomCard.roomFeatures',
    defaultMessage: '##Room Features & amenities##',
    description: 'Label indicate feature of rooms',
  },
  nonRememberedRate: {
    id: 'booking.roomCard.nonRememberedRate',
    defaultMessage: '##Non Remembe{red} rate##',
    description:
      'Non-Remembered rate indicator on Room CheckInCard. Do not remove or translate {red} path. It is a placeholder for highlight red path',
  },
  noRoomAvailable: {
    id: 'booking.noRoomAvailable',
    defaultMessage: '##Not available##',
    description: 'Label no room available in room type selection page',
  },
});

class RoomCard extends React.Component {
  static propTypes = {
    room: RoomType.shape().isRequired,
    booking: BookingShape,
    onClickShowFeature: PropTypes.func.isRequired,
    showFeature: PropTypes.bool.isRequired,
    changeRoom: PropTypes.func,
  };

  static defaultProps = {
    booking: null,
    changeRoom: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = { mouseOver: false, amount: 0 };
  }

  componentDidUpdate() {
    if (this.props.showFeature) {
      window.addEventListener('click', this.onCloseFeature);
    } else {
      window.removeEventListener('click', this.onCloseFeature);
    }
  }

  onSelectedNumberOfRoom = amount => {
    this.props.changeRoom(this.props.room.id, amount);
    this.setState({ amount });
  };

  onClickShowFeature = () => {
    const { room } = this.props;
    this.props.onClickShowFeature(room.id);
  };

  onCloseFeature = () => {
    if (!this.state.mouseOver) {
      this.props.onClickShowFeature(0);
    }
  };

  render() {
    const {
      room,
      booking: {
        hotel: { currency },
        roomTypes,
        checkIn,
        checkOut,
        joinRemembered,
        payment,
      },
      showFeature,
    } = this.props;
    let foundRoomType = null;
    let alreadySelectedARoom = false;
    let numberOfRooms = 0;
    roomTypes.forEach(roomType => {
      if (roomType.room.id === room.id) {
        foundRoomType = roomType;
      }
      if (roomType.numberOfRooms > 0) {
        numberOfRooms += roomType.numberOfRooms;
        alreadySelectedARoom = true;
      }
    });
    if (!foundRoomType) {
      return null;
    }
    const possibleRooms = numberOfRooms - foundRoomType.numberOfRooms;
    let maxNumberOfRooms = Math.min(
      MaxNumberOfRooms - possibleRooms,
      foundRoomType.room.available,
    );
    if (maxNumberOfRooms === 0) {
      maxNumberOfRooms = foundRoomType.numberOfRooms - 1;
    }
    const minNumberOfRooms = alreadySelectedARoom ? 0 : numberOfRooms;
    const nights = checkOut.diff(checkIn, 'd');

    let memberPrice = 0;
    let normalPrice = 0;
    if (payment === PAYMENT_PAY_AT_HOTEL) {
      // memberPrice = foundRoomType.price.memberHotel / nights;
      // normalPrice = foundRoomType.price.normalHotel / nights;
      // CALC NO TAX
      memberPrice = foundRoomType.price.memberHotelNoTax / nights;
      normalPrice = foundRoomType.price.normalHotelNoTax / nights;
    } else if (payment === PAYMENT_CREDIT_CARD || payment === PAYMENT_WALLET) {
      // memberPrice = foundRoomType.price.member / nights;
      // normalPrice = foundRoomType.price.normal / nights;
      // CALC NO TAX
      memberPrice = foundRoomType.price.memberNoTax / nights;
      normalPrice = foundRoomType.price.normalNoTax / nights;
    }
    const specialRateDescription = room.rate.special_rate_description || '';
    const disabled =
      maxNumberOfRooms === 0 && foundRoomType.numberOfRooms === 0;
    const selectRoom =
      foundRoomType.numberOfRooms > 0 ? (
        <div className={s.roomPicker}>
          <span className={s.roomLabel}>
            <FormattedMessage {...messages.rooms} />
          </span>
          <NumberPicker
            maxNumber={maxNumberOfRooms}
            minNumber={minNumberOfRooms}
            number={foundRoomType.numberOfRooms}
            onSelectedNumber={this.onSelectedNumberOfRoom}
          />
        </div>
      ) : (
        <button
          type="button"
          className={s.selectButton}
          disabled={disabled}
          onClick={() => {
            this.onSelectedNumberOfRoom(1);
          }}
        >
          <FormattedMessage {...messages.select} />
        </button>
      );
    return (
      <div className={cx(s.root, s[this.context.intl.locale])}>
        <div className={s.gallery}>
          <ImageGallery
            images={room.images}
            classNames={s.imageGallery}
            autoRotation={false}
            leftButtonClassName={s.galleryButton}
            rightButtonClassName={s.galleryButton}
          />
          {specialRateDescription && (
            <div className={s.specialRate}>{specialRateDescription}</div>
          )}
        </div>
        <div className={s.content}>
          {foundRoomType.numberOfRooms > 0 && <div className={s.border} />}
          <div>
            <div className={s.clearFix}>
              <div className={s.roomNameDescription}>
                <h2>{room.name}</h2>
                <br />
                <p>{room.description}</p>
              </div>
              {maxNumberOfRooms > 0 || foundRoomType.room.available > 0 ? (
                selectRoom
              ) : (
                <span className={s.notAvailable}>
                  <FormattedMessage {...messages.noRoomAvailable} />
                </span>
              )}
            </div>
          </div>
          {room.features && (
            <div
              className={s.features}
              onMouseOver={() => {
                this.setState({ mouseOver: true });
              }}
              onMouseLeave={() => {
                this.setState({ mouseOver: false });
              }}
              onFocus=""
            >
              <button type="button" onClick={this.onClickShowFeature}>
                <h4>
                  <FormattedMessage {...messages.roomFeatures} />
                </h4>
              </button>
              <div className={cx(s.roomFeatureOverlay, showFeature && s.show)}>
                <h4>
                  <FormattedMessage {...messages.roomFeatures} />
                </h4>
                <button
                  type="button"
                  onClick={() => {
                    this.props.onClickShowFeature(0);
                  }}
                >
                  X
                </button>
                <ul>
                  {room.features.map(({ name }) => (
                    <li key={name}>{name}</li>
                  ))}
                </ul>
              </div>
            </div>
          )}
          <div
            className={cx(
              s.priceBox,
              s.remembered,
              !joinRemembered && s.strikethrough,
            )}
          >
            <div className={s.label}>
              <FormattedMessage
                {...messages.exclusiveRate}
                values={{
                  red: (
                    <span className={s.highlight}>
                      <FormattedMessage {...messages.exclusiveRateRed} />
                    </span>
                  ),
                }}
              />
            </div>
            <div className={s.price}>
              <CurrencyDisplay value={memberPrice} currency={currency} />
              <span className={s.night}>
                &nbsp;/&nbsp;
                <FormattedMessage {...messages.night} />
              </span>
            </div>
          </div>
          <div
            className={cx(
              s.priceBox,
              s.nonRemembered,
              joinRemembered && s.strikethrough,
            )}
          >
            <div className={s.label}>
              <FormattedMessage
                {...messages.nonRememberedRate}
                values={{
                  red: (
                    <span className={s.highlight}>
                      <FormattedMessage {...messages.exclusiveRateRed} />
                    </span>
                  ),
                }}
              />
            </div>
            <div className={s.price}>
              <CurrencyDisplay value={normalPrice} currency={currency} />
              <span className={s.night}>
                &nbsp;/&nbsp;
                <FormattedMessage {...messages.night} />
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapState = state => ({
  booking: state.booking,
});

const mapDispatch = {
  changeRoom,
};

export default connect(mapState, mapDispatch)(withStyles(s)(RoomCard));
