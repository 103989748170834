/**
 * Created by methee on 9/12/17.
 */
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage } from 'react-intl';
import cx from 'classnames';

import s from './TruncatableText.css';

const messages = defineMessages({
  readMore: {
    id: 'common.read_more',
    defaultMessage: '##Read More##',
    description: 'Read More link for long texts',
  },
  readLess: {
    id: 'common.read_less',
    defaultMessage: '##Read Less##',
    description: 'Read Less link after clicked read more',
  },
});

class TruncatableText extends React.Component {
  static propTypes = {
    isHtml: PropTypes.bool,
    text: PropTypes.string.isRequired,
    lines: PropTypes.number.isRequired,
    className: PropTypes.string,
    lineHeight: PropTypes.number,
  };

  static defaultProps = {
    isHtml: false,
    className: '',
    lineHeight: 24,
  };

  constructor(props) {
    super(props);
    this.initialHeight = props.lineHeight * props.lines;
    this.state = {
      readMore: false,
      height: `${this.initialHeight}px`,
      noNeedForReadMore: false,
    };
  }

  componentDidMount() {
    if (process.env.BROWSER) {
      if (
        this.testerElement.offsetHeight - this.initialHeight <=
        this.props.lineHeight
      ) {
        // eslint-disable-next-line react/no-did-mount-set-state
        this.setState({
          height: 'auto',
          noNeedForReadMore: true,
        });
      }
    }
  }

  onClickReadMore = () => {
    this.setState({
      height: 'auto',
      readMore: true,
    });
  };

  onClickReadLess = () => {
    this.setState({
      height: `${this.initialHeight}px`,
      readMore: false,
    });
  };

  initialHeight = 0;

  render() {
    const { text, className, isHtml } = this.props;
    const { readMore, height, noNeedForReadMore } = this.state;
    let htmlText = text;
    if (!isHtml) {
      htmlText = text.replace(/\n/g, '<br/>');
    }
    const readMoreElem = !readMore ? (
      <div>
        ...
        <br />
        <button onClick={this.onClickReadMore}>
          <FormattedMessage {...messages.readMore} />
        </button>
      </div>
    ) : (
      <div>
        <button onClick={this.onClickReadLess}>
          <FormattedMessage {...messages.readLess} />
        </button>
      </div>
    );
    return (
      <div className={cx(s.root, className)}>
        <div
          className={s.tester}
          ref={testerElement => {
            this.testerElement = testerElement;
          }}
          dangerouslySetInnerHTML={{ __html: htmlText }}
        />
        <div
          ref={content => {
            this.content = content;
          }}
          className={s.text}
          style={{ height }}
          dangerouslySetInnerHTML={{ __html: htmlText }}
        />
        {!noNeedForReadMore && readMoreElem}
      </div>
    );
  }
}

export default withStyles(s)(TruncatableText);
