// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ":root {\n\n  /*\n   * Typography\n   * ======================================================================== */\n\n  /*\n   * Layout\n   * ======================================================================== */\n\n  /*\n   * Media queries breakpoints\n   * ======================================================================== */ /* Extra small screen / phone */ /* Small screen / tablet */ /* Medium screen / desktop */ /* Large screen / wide desktop */\n\n  font-size: 16px;\n  color: #000;\n}\n\n.Options-root {\n  position: absolute;\n  padding: 5px 15px;\n  left: 0;\n  width: 150px;\n  background-color: #fff;\n  z-index: 999;\n}\n\n.Options-root button {\n  margin: 0;\n  padding: 0;\n  border: none;\n  background: none;\n  font-size: 1em;\n  color: #000;\n}\n\n.Options-root button:hover {\n  color: #da291c;\n  text-decoration: underline;\n}\n", ""]);
// Exports
exports.locals = {
	"root": "Options-root"
};
module.exports = exports;
