import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import { connect } from 'react-redux';

import s from './ResendBookingModal.css';

import { resendBookingConfirmation } from '../../actions/booking';
import { closeResendBookingModal } from '../../actions/user';
import TextBox from '../TextBox';
import Button from '../Button';

const messages = defineMessages({
  email: {
    id: 'signin.email',
    defaultMessage: '##Email##',
    description: 'Label for email',
  },
  resendingBookingConfirmation: {
    id: 'resendBooking.resendingBookingConfirmation',
    defaultMessage: '##Resending booking confirmation##',
    description: 'Label for Resend booking confirmation button',
  },
  confirmationNumber: {
    id: 'resendBooking.confirmationNumber',
    defaultMessage: '##Confirmation Number##',
    description: 'Label for confirmation number',
  },
  toEmail: {
    id: 'resendBooking.toEmail',
    defaultMessage: '##To Email##',
    description: 'Label of the email we are resending booking confirmation to',
  },
  resend: {
    id: 'resendBooking.resend',
    defaultMessage: '##Resend##',
    description: 'label on Resend booking confirmation button',
  },
  resendBookingSuccess: {
    id: 'resendBooking.resendBookingSuccess',
    defaultMessage: '##Your confirmation have been resent##',
    description:
      'Message display when booking confirmation was successfully resent',
  },
  resendBookingFail: {
    id: 'resendBooking.resendBookingFail',
    defaultMessage: "Can't resend your booking confirmation",
    description:
      'Message display when booking confirmation was failed to resent',
  },
});

class ResendBookingModal extends React.Component {
  static propTypes = {
    bookingNumber: PropTypes.string,
    toEmail: PropTypes.string,
    resendBookingConfirmation: PropTypes.func,
    closeResendBookingModal: PropTypes.func,
  };

  static defaultProps = {
    bookingNumber: '',
    toEmail: '',
    resendBookingConfirmation: () => {},
    closeResendBookingModal: () => {},
  };

  static contextTypes = {
    intl: intlShape,
  };

  constructor(props) {
    super(props);
    this.state = {
      currentEmail: props.toEmail,
      toEmail: props.toEmail,
    };
  }

  onEmailChange = value => {
    this.setState({
      toEmail: value,
    });
  };

  onSubmit = event => {
    const { bookingNumber } = this.props;
    const { toEmail, currentEmail } = this.state;
    this.props.resendBookingConfirmation(
      bookingNumber,
      currentEmail,
      toEmail,
      this.context.intl.formatMessage(messages.resendBookingSuccess),
      this.context.intl.formatMessage(messages.resendBookingFail),
    );
    event.preventDefault();
  };

  onClose = () => {
    this.props.closeResendBookingModal();
  };

  render() {
    const { bookingNumber } = this.props;
    const { toEmail } = this.state;
    return (
      <div className={s.root}>
        <div className={s.body}>
          <button className={s.btnClose} onClick={this.onClose}>
            ×
          </button>
          <h1>
            <FormattedMessage {...messages.resendingBookingConfirmation} />
          </h1>
          <form onSubmit={this.onSubmit}>
            <div className={s.row}>
              <b>
                <FormattedMessage {...messages.confirmationNumber} />
              </b>
              <span>{bookingNumber}</span>
            </div>
            <div className={s.row}>
              <TextBox
                id={'toEmail'}
                value={toEmail}
                inputType={'email'}
                onChange={this.onEmailChange}
              />
            </div>
            <div className={s.row}>
              <Button
                label={this.context.intl.formatMessage(messages.resend)}
              />
            </div>
          </form>
        </div>
      </div>
    );
  }
}

const mapDispatch = {
  resendBookingConfirmation,
  closeResendBookingModal,
};

export default connect(
  null,
  mapDispatch,
)(withStyles(s)(ResendBookingModal));
