/* eslint-disable camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

class ImportTripTease extends React.Component {
  // eslint-disable-next-line react/static-property-placement
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    hotel: PropTypes.object,
  };

  // eslint-disable-next-line react/static-property-placement
  static defaultProps = {
    hotel: null,
  };

  render() {
    const res = { '@context': 'http://schema.org', '@type': 'Organization' };
    const { hotel } = this.props;
    if (typeof hotel !== 'undefined' && hotel) {
      if (typeof hotel.name_en !== 'undefined' && hotel.name_en) {
        res['@type'] = 'Hotel';
        res.name = `Red Planet ${hotel.name_en}`;
      }
    }
    res.brand = 'Red Planet Hotels';
    const tripTeaseData = (
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{ __html: JSON.stringify(res) }}
      />
    );
    const tripTeaseScript = (
      <script
        dangerouslySetInnerHTML={{
          __html: `(function(a,t,b,c,d){c=a.createElement(t);c.src=b,c.defer=true,c.async=true,c.crossOrigin='anonymous';c.type="text/javascript";d=a.getElementsByTagName(t)[0];d.parentNode.insertBefore(c,d)})(document,"script","https://onboard.triptease.io/bootstrap.js?integrationId=01EA1KCH8D2QHV5S5CHPKQTCAT");`,
        }}
      />
    );
    return (
      <div className="import-tripTease">
        {tripTeaseData}
        {tripTeaseScript}
      </div>
    );
  }
}

const mapState = state => ({ hotel: state.hotel });

export default connect(mapState)(ImportTripTease);
