/* eslint-disable css-modules/no-unused-class */
/* eslint-disable no-undef */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, FormattedMessage, intlShape } from 'react-intl';
import withStyles from 'isomorphic-style-loader/lib/withStyles';
import { connect } from 'react-redux';
import Cookies from 'universal-cookie';
import cx from 'classnames';

import s from './FacebookButton.css';

import { fbLogin } from '../../actions/user';

const messages = defineMessages({
  loginWithFacebook: {
    id: 'signin.log_in_with_facebook',
    defaultMessage: '##Sign In with Facebook##',
    description: 'Sign in with Facebook button',
  },
  signupWithFacebook: {
    id: 'signup.sign_up_with_facebook',
    defaultMessage: '##Sign Up with Facebook##',
    description: 'Sign Up with Facebook button',
  },
});

class FacebookButton extends React.Component {
  signInWindow = null;

  componentDidMount() {
    (function(d, w, id) {
      const fjs = d.getElementsByTagName(w)[0];
      if (d.getElementById(id)) {
        return;
      }
      const js = d.createElement(w);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      (fjs ? fjs.parentNode : document.lastElementChild).insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

    window.fbAsyncInit = () => {
      FB.init({
        appId: window.App.fbUrl, // Change with your Facebook app id
        autoLogAppEvents: true,
        xfbml: true,
        version: 'v3.0',
      });
    };
  }

  onClick = () => {
    this.props.onClick();
    if (process.env.BROWSER) {
      const facebookLoginHandler = response => {
        if (response.status === 'connected') {
          const cookies = new Cookies();
          cookies.set('fbToken', response.authResponse.accessToken);
          this.props.fbLogin();
        }
      };
      FB.login(facebookLoginHandler, { scope: 'public_profile,email' });
    }
  };

  render() {
    const { forSignIn, className } = this.props;
    const message = forSignIn
      ? messages.loginWithFacebook
      : messages.signupWithFacebook;
    return (
      <div className={cx(s.root, className, s[this.context.intl.locale])}>
        <button onClick={this.onClick} type="button">
          <FormattedMessage {...message} />
        </button>
      </div>
    );
  }
}

FacebookButton.propTypes = {
  onClick: PropTypes.func,
  forSignIn: PropTypes.bool,
  fbLogin: PropTypes.func,
  className: PropTypes.string,
};

FacebookButton.defaultProps = {
  onClick: () => {},
  fbLogin: () => {},
  forSignIn: false,
  className: '',
};

FacebookButton.contextTypes = {
  intl: intlShape,
};

const mapDispatch = {
  fbLogin,
};

export default connect(null, mapDispatch)(withStyles(s)(FacebookButton));
